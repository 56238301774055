import React from 'react';
import { List, ListItem, Tag, Typography } from '@octanner/prism-core';

export default function FunctionDocumentation({ args = [], ret }) {
  return (
    <>
      <Typography variant="h3">ArgumentList:</Typography>
      {args.length === 0 && <Typography>No arguments</Typography>}
      <List>
        {args.map((arg, index) => (
          <ListItem key={arg.name}>
            <Typography>
              {index + 1}.<b>{arg.name}</b>:&nbsp;
            </Typography>
            <Tag variant="archived">
              {arg.type}
              {arg.optional && '[Optional]'}
            </Tag>
            &nbsp;
            {arg.description}
          </ListItem>
        ))}
      </List>
      <Typography variant="h3">Returns:</Typography>
      {!ret && <Typography>No return</Typography>}
      {ret && (
        <Typography>
          <Tag variant="archived">
            {ret.type}
            {ret.optional && '[Optional]'}
          </Tag>
          &nbsp;
          {ret.description}
        </Typography>
      )}
    </>
  );
}
