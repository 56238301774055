import { ReactNode } from 'react';
export type HeightOption = 'short' | 'tall';

export function getHeight(
  height?: HeightOption,
  label?: ReactNode,
): HeightOption {
  if (!height && !label) {
    return 'short';
  }
  switch (height) {
    case 'short':
      return 'short';
    case 'tall':
    default:
      return 'tall';
  }
}
