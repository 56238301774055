import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import {
  tannerBlack,
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '../ThemeProvider/colors';

const checkboxSize = 18;
export const BpIcon = styled('span')(() => ({
  borderRadius: '3px',
  width: checkboxSize,
  height: checkboxSize,
  border: `1px solid ${tannerGray['600']}`,
  'input: active ~ &': {
    borderColor: tannerSemantic.info.color,
  },
  'input:disabled ~ &': {
    backgroundColor: tannerGray['100'],
    borderColor: tannerGray['300'],
  },
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: tannerSemantic.info.color,
  borderColor: tannerSemantic.info.color,
  '&:before': {
    'input:enabled ~ &': {
      boxShadow: `0px 2px 2px 0px ${tannerBlack}33`, // 20% opacity in hex
    },
    position: 'absolute',
    width: 3,
    height: 12,
    left: '50%',
    top: 'calc(50% - 6px)',
    background: tannerWhite,
    borderRadius: '3px',
    transform: 'rotate(45deg)',
    content: '""',
  },
  '&:after': {
    'input:enabled ~ &': {
      boxShadow: `0px 2px 2px 0px ${tannerBlack}33`, // 20% opacity in hex
    },
    position: 'absolute',
    width: 3,
    height: 6,
    left: 'calc(50% - 5px)',
    top: 'calc(50% - 1px)',
    background: tannerWhite,
    borderRadius: '3px',
    transform: 'rotate(135deg)',
    content: '""',
  },
  'input: disabled ~ &': {
    backgroundColor: tannerSemantic.info.color,
    borderColor: tannerSemantic.info.color,
    opacity: '30%',
  },
});

const indeterminateWidth = 10;
const indeterminateHeight = 3;
export const BpIndeterminateIcon = styled(BpIcon)({
  backgroundColor: tannerSemantic.info.color,
  borderColor: tannerSemantic.info.color,
  '&:before': {
    'input:enabled ~ &': {
      boxShadow: `0px 2px 2px 0px ${tannerBlack}33`, // 20% opacity in hex
    },
    position: 'absolute',
    width: indeterminateWidth,
    height: indeterminateHeight,
    left: `calc(50% - ${indeterminateWidth}px / 2)`,
    top: `calc(50% - ${indeterminateHeight}px / 2)`,

    background: tannerWhite,
    borderRadius: '3px',
    content: '""',
  },
  'input:disabled ~ &': {
    backgroundColor: tannerSemantic.info.color,
    borderColor: tannerSemantic.info.color,
    opacity: '30%',
  },
});

export const BpCheckbox = styled(Checkbox)<{ disableRipple?: boolean }>(
  ({ disableRipple }) => ({
    width: 36,
    height: 36,
    '&& .MuiTouchRipple-child': {
      backgroundColor: disableRipple
        ? 'transparent'
        : `${tannerSemantic.info.color}4d`, // 30% opacity in hex
    },
    ':hover': {
      backgroundColor: disableRipple
        ? 'transparent'
        : `${tannerSemantic.info.background}4d`, // 30% opacity in hex
      transition: disableRipple ? 'none' : 'background-color 300ms ease-out',
    },
    ':not(:hover)': {
      transition: disableRipple ? 'none' : 'background-color 300ms ease-out',
    },
    '&.Mui-focusVisible': {
      outlineOffset: '3px',
      outline: `3px solid  ${tannerSemantic.info.color}`,
      borderRadius: '3px',
    },
  }),
);
