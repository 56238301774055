import React, { useState } from 'react';
import Typography from '../Typography';
import ColorBox from './ColorBox';
import generateClientColors from './generateClientColors';
import { tannerPurple } from './colors';
import {
  RootBox,
  TitleTypography,
  ClientColorTitleTypography,
  StyledTextField,
  GridBox,
} from './styles';
import { ColorBoxLabelProps, ColorBoxProps } from './types';

const ClientColorsComponent: React.FC<ColorBoxLabelProps> = () => {
  const [hexCode, setHexCode] = useState(tannerPurple['500']);
  const [clientColors, setClientColors] = useState<ColorBoxProps[]>(
    generateClientColors(hexCode),
  );
  const [error, setError] = useState('');

  const handleAddColor = (event: React.KeyboardEvent) => {
    if (event?.key === 'Enter') {
      if (!/^#[0-9A-F]{6}$/i?.test(hexCode)) {
        setError('# is required with the hex code (ex. #7942DD)');
        setClientColors([]);
      } else {
        const newClientColors = hexCode ? generateClientColors(hexCode) : [];
        setClientColors(newClientColors);
        setError('');
      }
    }
  };

  return (
    <RootBox>
      <TitleTypography variant="h3">Client Color</TitleTypography>
      <Typography>
        This color represents our client color. In Figma designs, this color is
        represented as
        <ClientColorTitleTypography style={{ paddingLeft: '4px' }}>
          Purple
        </ClientColorTitleTypography>
        . In the live product, this color comes from the clients hex code that
        is entered in the Themes space of Admin.
      </Typography>
      <StyledTextField
        id="filled-basic"
        label="Client Color Example"
        size="medium"
        value={hexCode}
        onChange={(e) => {
          setHexCode(e?.target?.value);
          setError('');
        }}
        onKeyPress={handleAddColor}
        error={!!error}
        helperText={error}
      />
      {!error && (
        <GridBox>
          {clientColors?.map(({ color, label }) => (
            <ColorBox key={color} color={color} label={label} />
          ))}
        </GridBox>
      )}
    </RootBox>
  );
};

export default ClientColorsComponent;
