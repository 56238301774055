import { createMyPlugins } from '../typescript/plateTypes';
import {
  createBoldPlugin,
  createCodePlugin,
  createItalicPlugin,
  createListPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createUnderlinePlugin,
} from '@udecode/plate';
import { plateUI } from './plateUI';

export const basicMarksPlugins = createMyPlugins(
  [
    createBoldPlugin(),
    createCodePlugin(),
    createItalicPlugin(),
    createStrikethroughPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createUnderlinePlugin(),
    createTablePlugin(),
    createListPlugin(),
  ],
  {
    components: plateUI,
  },
);
