import styled from '@emotion/styled';
import Box from '../Box';
import Button from '../Button';
import { tannerGray } from '../ThemeProvider/colors';

export const Container = styled(Box)<{ containerHeight: number | null }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: ${({ containerHeight }: { containerHeight: number | null }) =>
    `${containerHeight}px` || 'none'};
  transition: 0.5s;
`;

export const TextContainer = styled(Box)<{
  expanded: boolean;
  maxLines: number;
  containerHeight: number | null;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ expanded, maxLines }) =>
    expanded ? 'unset' : maxLines};
  -webkit-box-orient: vertical;
  max-height: ${({ containerHeight }) => `${containerHeight}px` || 'none'};
  text-align: justify;
`;

export const ClampTextWrapper = styled(Box)`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledButton = styled(Button)<{
  severity: string | undefined;
}>`
  padding: 0px;
  &.MuiButton-text {
    color: inherit;
    ${({ severity }) =>
      severity === 'secondary' &&
      `
      &:hover {
        background: ${tannerGray[200]};
      }
    `}
  }
`;
