import { createContext } from 'react';
import { QueryResult } from '@apollo/client';
import {
  Identity,
  Layout,
  CustomerCoreLocaleMapsByCustomerPayLoad,
} from '../../Header/interfaces';

const LayoutContext = createContext<
  [
    QueryResult<{ currentIdentity: Identity }> | null,
    QueryResult<Layout> | null,
    QueryResult<CustomerCoreLocaleMapsByCustomerPayLoad> | null,
  ]
>([null, null, null]);

export default LayoutContext;
