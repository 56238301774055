import i18n, { InitOptions } from 'i18next';
import React, { PropsWithChildren, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import useUser from '../hooks/useUser';

const fallbackLanguage = 'en';

export type BuildConfigOptions = {
  namespace: string;
  defaultStrings?: { [key: string]: string };
};

const buildConfig = (options: BuildConfigOptions): InitOptions => ({
  lng: 'en',
  // we do not use keys in form messages.welcome
  keySeparator: false,
  interpolation: {
    prefix: '{',
    suffix: '}',
    // react already handles xss
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  fallbackLng: 'en',
  defaultNS: options.namespace,
  ...(options?.defaultStrings && {
    resources: {
      en: { [options.namespace]: options.defaultStrings },
    },
  }),
});

const useUpdateLanguage = (translationUri: string, namespace: string) => {
  const user = useUser(),
    language = user?.language;

  useEffect(() => {
    const defaultLanguage = language ?? fallbackLanguage;
    if (!i18n.isInitialized) return;

    const url = new URL(`t/${namespace}`, translationUri);
    fetch(url.href, {
      headers: { 'accept-language': defaultLanguage },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw res;
      })
      .then((data) => {
        i18n.addResourceBundle(defaultLanguage, namespace, data);
      })
      .catch((error) => {
        console.error('Unable to retrieve translation data:', error.message);
      });
  }, [language, translationUri, namespace]);
};

export type TranslationLoaderProps = PropsWithChildren<{
  translationUri: string;
  namespace: string;
  defaultStrings: { [key: string]: string };
}>;

export const initializeI18n = (options: BuildConfigOptions) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(buildConfig(options));
};

const TranslationLoader: React.FC<TranslationLoaderProps> = ({
  children,
  namespace,
  defaultStrings,
  translationUri,
}) => {
  const user = useUser();
  const language = user?.language;

  useEffect(() => {
    i18n.addResourceBundle('en', namespace, defaultStrings);
  }, [namespace, defaultStrings]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useUpdateLanguage(translationUri, namespace);

  return <>{children}</>;
};

export default TranslationLoader;
