import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  CardItem,
  IconLabelDiv,
  IconDiv,
  DraggableIcon,
  TextStyle,
  ToggleDiv,
} from './styles';
import { SortableItemProps } from './types';
import { Switch } from '..';

export const SortableItem: React.FC<SortableItemProps> = ({
  variant,
  id,
  option,
  secondaryText,
  primaryText,
  index,
  focusedIndex,
  isDragging,
  handleSwitchClick,
  handleToggleOn,
  children,
}) => {
  const { setNodeRef, transform, listeners, setActivatorNodeRef } = useSortable(
    { id },
  );

  const handleMouseDown = (event: React.MouseEvent) => {
    if (listeners?.onMouseDown) listeners.onMouseDown(event);
    setActivatorNodeRef(event.currentTarget as HTMLElement);
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    if (listeners?.onTouchStart) listeners.onTouchStart(event);
    setActivatorNodeRef(event.currentTarget as HTMLElement);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (listeners?.onKeyDown) listeners.onKeyDown(event);
    setActivatorNodeRef(event.currentTarget as HTMLElement);
  };

  const handleToggleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleToggleOn(event, index);
    }
    if (listeners?.onKeyDown) listeners.onKeyDown(event);
    setActivatorNodeRef(event.currentTarget as HTMLElement);
  };

  return (
    <CardItem
      id={id}
      ref={setNodeRef}
      isDragging={isDragging}
      isDisabled={option.isDisabled}
      isFocused={focusedIndex === index}
      transform={CSS.Translate.toString(transform)}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onKeyDown={handleKeyDown}
      aria-describedby={`sortable-item-${id}-description`}
      aria-label={`${primaryText}, ${isDragging ? 'dragging' : 'not dragging'}`}
      aria-disabled={option.isDisabled}
      data-testid={`sortable-item-${id}`}
    >
      <IconLabelDiv>
        <IconDiv>
          <DraggableIcon
            isFocused={focusedIndex === index}
            isDisabled={option.isDisabled}
            tabIndex={option.isDisabled ? -1 : 0}
            role="button"
            aria-label="Drag item"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onKeyDown={handleKeyDown}
          />
        </IconDiv>
        {variant === 'settings' && (
          <TextStyle
            id={`sortable-item-${id}-description`}
            variant="body1"
            isDisabled={option.isDisabled}
          >
            {primaryText}
          </TextStyle>
        )}
      </IconLabelDiv>

      {variant === 'settings' ? (
        <>
          <TextStyle variant="body1" isDisabled={option.isDisabled}>
            {secondaryText}
          </TextStyle>

          <ToggleDiv
            onMouseDown={handleMouseDown}
            onKeyDown={handleToggleKeyDown}
          >
            <Switch
              aria-pressed={option.isToggleSelected}
              aria-label="Toggle setting"
              checked={option.isToggleSelected}
              onChange={(e) => handleSwitchClick(e, index)}
              disabled={false}
            />
          </ToggleDiv>
        </>
      ) : (
        children
      )}
    </CardItem>
  );
};
