import { MyPlatePlugin, createMyPlugins } from '../typescript/plateTypes';
import { AnyObject, AutoformatPlugin, PlatePlugin, createAutoformatPlugin, createListPlugin } from '@udecode/plate';
import { plateUI } from './plateUI';
import { autoformatRules } from './autoFormatRules';

export const listPlugins = createMyPlugins([createListPlugin()], {
  components: plateUI,
});

export const autoformatPlugin: Partial<PlatePlugin<AutoformatPlugin>> = {
  options: {
    rules: autoformatRules,
    enableUndoOnDelete: true,
  },
};

export const myAutoformatPlugin = createMyPlugins([createAutoformatPlugin(autoformatPlugin) as MyPlatePlugin<AnyObject>], {
  components: plateUI,
});
