import styled from '@emotion/styled';
import Button from '../Button';
import Typography from '../Typography';

export const StyledButton = styled(Button)`
  margin-right: 16px;
`;

export const MarginBottom = styled.div`
  margin-bottom: 32px;
`;

export const MiddleButton = styled(Button)`
  margin-right: 8px;
`;
export const SorryText = styled(Typography)`
  margin: 16px 0;
`;

export const ErrorCodeContainer = styled(Typography)`
  margin-top: 16px;
`;
