import React from 'react';
import { TabIndicatorStyles } from './styles';
import { TabsProps } from './types';
import { default as MuiTabs } from '@mui/material/Tabs';

const Tabs: React.FC<TabsProps> = ({
  onChange,
  value,
  children,
  action,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  centered = false,
  classes,
  className,
  orientation = 'horizontal',
  scrollButtons = 'auto',
  ScrollButtonComponent,
  selectionFollowsFocus,
  slots,
  sx,
  style,
  TabIndicatorProps,
  textColor = 'primary',
  visibleScrollbar = false,
  variant = 'standard',
  key,
  'data-testid': dataTestId,
}) => {
  return (
    <MuiTabs
      onChange={onChange}
      TabIndicatorProps={TabIndicatorProps ?? { sx: TabIndicatorStyles }}
      value={value}
      action={action}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      centered={centered}
      classes={classes}
      className={className}
      orientation={orientation}
      scrollButtons={scrollButtons}
      ScrollButtonComponent={ScrollButtonComponent}
      selectionFollowsFocus={selectionFollowsFocus}
      slots={slots}
      visibleScrollbar={visibleScrollbar}
      sx={sx}
      style={style}
      textColor={textColor}
      key={key}
      data-testid={dataTestId}
      variant={variant}
    >
      {children}
    </MuiTabs>
  );
};

export default Tabs;
