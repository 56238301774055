import { styled } from '@mui/material/styles';
import { Button, Grid } from '..';
import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';

const PREFIX = 'PasswordForm';

export const classes = {
  root: `${PREFIX}-root`,
  colorPrimary: `${PREFIX}-colorPrimary`,
  colorSecondary: `${PREFIX}-colorSecondary`,
  colorWarning: `${PREFIX}-colorWarning`,
  bar: `${PREFIX}-bar`,
  barColorPrimary: `${PREFIX}-barColorPrimary`,
  barColorWarning: `${PREFIX}-barColorWarning`,
  barColorSecondary: `${PREFIX}-barColorSecondary`,
};

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 10,
    borderRadius: 10,
  },

  [`& .${classes.colorPrimary}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.colorSecondary}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.colorWarning}`]: {
    backgroundColor: tannerGray['200'],
  },

  [`& .${classes.bar}`]: {
    borderRadius: 0,
  },

  [`& .${classes.barColorPrimary}`]: {
    backgroundColor: tannerSemantic['success'].color,
  },

  [`& .${classes.barColorWarning}`]: {
    backgroundColor: '#E5B818',
  },

  [`& .${classes.barColorSecondary}`]: {
    backgroundColor: tannerSemantic['error'].color,
  },
}));

export const StyledUL = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const CancelButton = styled(Button)`
  margin-left: 16px;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  width: 100%;
`;
