import React, { useState, useRef } from 'react';
import {
  DndContext,
  closestCenter,
  DragStartEvent,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
  KeyboardSensor,
  DragOverlay,
} from '@dnd-kit/core';
import {
  verticalListSortingStrategy,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers';
import { DivContainer, ListElement } from './styles';
import { DraggableRowsProps, Options } from './types';
import { SortableItem } from './SortableItem';

export const DraggableRows: React.FC<DraggableRowsProps> = ({
  variant,
  onChangeValue,
  onInitialLoad,
  options,
  primaryTextKey,
  secondaryTextKey,
  rowId,
  selectedIdList,
  children,
}) => {
  const [OptionsList, setOptionsList] = useState<Options[]>(options);
  const [selectedLists, setSelectedLists] = useState<string[]>(selectedIdList);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  const selectedListsOnLoad = options
    .filter((item) => item.isToggleSelected)
    .map((item) => item.id);
  const filteredStrings: string[] = selectedListsOnLoad.filter(
    (item): item is string => typeof item === 'string',
  );

  onInitialLoad(filteredStrings, options);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      scrollBehavior: 'auto',
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    if (active.id !== over?.id) {
      const oldIndex = OptionsList.findIndex((item) => item.id === active.id);
      const newIndex = OptionsList.findIndex((item) => item.id === over?.id);
      const newOrder = arrayMove(OptionsList, oldIndex, newIndex);

      setOptionsList(newOrder);
      updateChangeValue(newOrder);
    }
    setDraggingIndex(null);
  };

  const handleSwitchClick = (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    index: number,
  ) => {
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    handleToggleOn(event, index);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(String(active.id));
    const index = OptionsList.findIndex((item) => item.id === active.id);
    if (index !== -1) {
      setDraggingIndex(index);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          const newOrder = arrayMove(OptionsList, index, index - 1);
          setOptionsList(newOrder);
          setFocusedIndex(index + 2);
          updateChangeValue(newOrder);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (index < OptionsList.length - 2) {
          const newOrder = arrayMove(OptionsList, index, index + 1);
          setOptionsList(newOrder);
          setFocusedIndex(index + 1);
          updateChangeValue(newOrder);
        }
        break;
      case 'Space':
        event.preventDefault();
        setDraggingIndex(draggingIndex === null ? index : null);
        break;
      default:
        break;
    }
  };

  const handleToggleOn = (
    event:
      | React.MouseEvent
      | React.KeyboardEvent
      | React.ChangeEvent<HTMLInputElement>
      | undefined,
    index: number,
  ) => {
    if (event) {
      event.preventDefault?.();
      event.stopPropagation?.();
    }
    const updatedList = OptionsList.map((item, i) => {
      if (i === index) {
        return { ...item, isToggleSelected: !item.isToggleSelected };
      }
      return item;
    });
    setOptionsList(updatedList);
    updateChangeValue(updatedList);
  };

  const setSelectedListIds = (list: Options[]) => {
    return list.filter((item) => item.isToggleSelected).map((item) => item.id);
  };

  const updateChangeValue = (updatedList: Options[]) => {
    const selectedLists = setSelectedListIds(updatedList);
    const filteredStrings: string[] = selectedLists.filter(
      (item): item is string => typeof item === 'string',
    );
    setSelectedLists(filteredStrings);
    onChangeValue(filteredStrings, updatedList);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
    >
      <SortableContext
        items={OptionsList.map((option) => option[rowId] as string)}
        strategy={verticalListSortingStrategy}
      >
        <ListElement role="list">
          {OptionsList.map((option, index) => {
            const isDragging = draggingIndex === index;
            const itemLabel = `${option[primaryTextKey]} ${index + 1} of ${
              OptionsList.length
            }`;

            return (
              <DivContainer
                key={option[rowId] as string}
                data-testid={`sortable-item-${option[rowId]}`}
                aria-grabbed={isDragging}
                aria-labelledby={`sortable-item-label-${option[rowId]}`}
                role="listitem"
                aria-describedby={
                  isDragging
                    ? `You have grabbed ${itemLabel}`
                    : `${itemLabel} is draggable`
                }
              >
                <SortableItem
                  ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                  id={option[rowId] as string}
                  data-testid={`sortable-item-${option[rowId]}`}
                  option={option}
                  secondaryText={option[secondaryTextKey] as string}
                  primaryText={option[primaryTextKey] as string}
                  index={index}
                  variant={variant}
                  focusedIndex={focusedIndex}
                  handleToggleOn={(event) => handleToggleOn(event, index)}
                  selectedLists={selectedLists}
                  onKeyDown={handleKeyDown}
                  isDragging={isDragging}
                  handleSwitchClick={handleSwitchClick}
                >
                  {children}
                </SortableItem>
              </DivContainer>
            );
          })}
        </ListElement>
      </SortableContext>

      <DragOverlay>
        {activeId &&
          (() => {
            const option = OptionsList.find(
              (option) => String(option[rowId]) === activeId,
            );

            if (!option) {
              return null;
            }

            const index = OptionsList.findIndex(
              (item) => String(item[rowId]) === activeId,
            );

            return (
              <SortableItem
                id={activeId}
                ref={(el: HTMLDivElement) => (cardRefs.current[index] = el)}
                option={option}
                data-testid={`sortable-item-${option[rowId]}`}
                handleSwitchClick={handleSwitchClick}
                primaryText={option[primaryTextKey] as string}
                secondaryText={option[secondaryTextKey] as string}
                index={index}
                onKeyDown={handleKeyDown}
                variant={variant}
                handleToggleOn={(event) => handleToggleOn(event, index)}
                focusedIndex={focusedIndex}
                selectedLists={selectedLists}
                isDragging={true}
              >
                {children}
              </SortableItem>
            );
          })()}
      </DragOverlay>
    </DndContext>
  );
};

export default DraggableRows;
