import React from 'react';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import Menu from '../Menu';
import { ArrowTriangleDown } from '@octanner/prism-icons';

const DualActionButton: React.FC<
  React.ComponentProps<typeof ButtonGroup> & {
    label: string;
    onClick(e: React.MouseEvent): void;
    adminColor?: boolean;
    menuProps?: {
      maxHeight?: string;
    };
  }
> = ({ children, label, onClick, adminColor, menuProps, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const mappedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // TODO figure out a way to remove this ts-ignore
        // @ts-ignore this is an HTMLElement and does have onClick prop available
        onClick: (e: React.MouseEvent) => {
          setAnchorEl(null);
          if (child.props.onClick) {
            child.props.onClick(e);
          }
        },
      });
    }
  });
  const maxHeight = menuProps?.maxHeight || 'None';

  return (
    <>
      <ButtonGroup {...props}>
        <Button onClick={onClick} adminColor={adminColor}
        sx={{
          borderRadius: '5px',
        }}>
          {label}
        </Button>
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget as Element)}
          adminColor={adminColor}
        >
          <ArrowTriangleDown />
        </Button>
      </ButtonGroup>
      <Menu
        PaperProps={{
          sx: {
            maxHeight: maxHeight,
          },
        }}
        MenuListProps={{
          sx: {
            maxHeight: maxHeight,
            overflowY: 'auto',
          },
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={() => setAnchorEl(null)}
      >
        {mappedChildren}
      </Menu>
    </>
  );
};

export default DualActionButton;
