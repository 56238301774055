import styled from '@emotion/styled';
import Button from '../Button';
import Popover from '@mui/material/Popover';

export const StyledButton = styled(Button)`
  display: inline-flex;
  width: 228px;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
`;
export const PopoverContainer = styled(Popover)({
  pointerEvents: 'none',
  '.MuiPopover-paper': {
    pointerEvents: 'auto',
    padding: '16px 24px',
  },
});
export const PopoverWrapper = styled('div')({
  width: 'fit-content',
});
