import {
  ELEMENT_DEFAULT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  getParentNode,
  getPluginType,
  LinkToolbarButton,
  ListToolbarButton,
  MarkToolbarButton,
  toggleNodeType,
  useEventPlateId,
} from '@udecode/plate';
import React from 'react';
import {
  ButtonGroup,
  Button,
  Box,
  Select,
  MenuItem,
  Colors,
} from '@octanner/prism-core';
import { ToolbarButton } from './constants';
import styled, { css } from 'styled-components';
import { Link } from '@octanner/prism-icons';
import { SelectChangeEvent, styled as Styled } from '@mui/material';
import { useMyPlateEditorRef } from './typescript/plateTypes';
import { BasePoint } from 'slate';
import { autoformatLists } from '../TextEditor/plugins/autoFormatLists';
import { autoformatRules } from '../TextEditor/plugins/autoFormatRules';

const autoformatOptions = {
  rules: [...autoformatRules],
  enableUndoOnDelete: true,
};
const CustomSelect = styled(Select)({
  cursor: 'pointer',
  textAlign: 'start',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '18px 16px',
  gap: '8px',
  width: '160px',
  background: `${Colors.tannerWhite}`,
  border: `1px solid ${Colors.tannerGray[300]}`,
  borderRadius: '3px',
  flex: 'none',
  order: 0,
  alignSelf: 'stretch',
  flexGrow: 0,
  '& .MuiSelect-select': {
    padding: '0px 0px 0px 16px',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    height: '38px',
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: Colors.tannerWhite,
  },
});

const CustomButton = Styled(Button)`
&& {
  padding: 0px;
  border: none;
  overflow: hidden;
  color: ${Colors.tannerGray[300]};
  &.Mui-focusVisible {
    z-index: 1;
  }
}`;

export const BasicElementToolbarButtons = ({
  id,
  readOnly,
}: {
  id: string | undefined;
  readOnly: boolean | undefined;
}) => {
  const editor = useMyPlateEditorRef(useEventPlateId());
  const toolbarButton = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    background: Colors.tannerWhite,
    borderWidth: '0px 0px 0px 0px',
    borderStyle: 'solid',
    borderColor: Colors.tannerGray[300],
    flex: 'none',
    order: 0,
    flexGrow: 0,
    color: Colors.tannerGray[900],
  };
  const toolbarButtonStyles = {
    root: css`
      border: 1px solid ${Colors.tannerGray[300]};
      width: 40px;
      height: 40px;
      &.slate-ToolbarButton-active {
        background-color: ${readOnly
          ? Colors.tannerWhite
          : Colors.tannerBlue[500]};
        border: 1px solid
          ${readOnly ? Colors.tannerGray[300] : Colors.tannerBlue[500]};
      }
      &.slate-ToolbarButton:hover {
        background-color: ${Colors.tannerGray[100]};
        color: ${Colors.tannerBlack};
      }
      &.slate-ToolbarButton-active:hover {
        background-color: ${readOnly
          ? Colors.tannerGray[300]
          : Colors.tannerBlue[500]};
      }
      &.slate-ToolbarButton-active svg {
        fill: ${readOnly ? Colors.tannerBlack : Colors.tannerWhite};
      }
    `,
  };

  const iconStyle = {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingTop: '7px',
    paddingLeft: '6px',
  };

  const elementOptions = [
    { type: ELEMENT_DEFAULT, label: 'Paragraph' },
    { type: ELEMENT_H1, label: 'Heading 1' },
    { type: ELEMENT_H2, label: 'Heading 2' },
    { type: ELEMENT_H3, label: 'Heading 3' },
    { type: ELEMENT_H4, label: 'Heading 4' },
  ];

  const validTypes = [ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4];
  const parent = getParentNode(editor, editor?.selection?.anchor as BasePoint);
  const node = parent && Array.isArray(parent) ? parent[0] : null;

  return (
    <>
      <CustomSelect
        id={id}
        autoWidth={true}
        defaultValue={ELEMENT_DEFAULT}
        onChange={(event: SelectChangeEvent<unknown>) =>
          editor &&
          toggleNodeType(editor, {
            activeType: (event.target.value as string) || undefined,
            inactiveType: ELEMENT_DEFAULT,
          })
        }
        style={{ height: '40px', minHeight: '40px', padding: '0px' }}
        MenuProps={{
          MenuListProps: {
            style: {
              minWidth: '160px',
            },
          },
        }}
        value={
          node?.type && validTypes.includes(node?.type as string)
            ? node?.type
            : ELEMENT_DEFAULT
        }
      >
        {elementOptions.map((option) => (
          <MenuItem value={option.type} key={option.type}>
            {option.label}
          </MenuItem>
        ))}
      </CustomSelect>

      <ButtonGroup variant="outlined" color="inherit">
        {ToolbarButton.map(({ element, icon, type }, index) => {
          if (type === 'MARK') {
            autoformatOptions.rules.push(...autoformatLists);
            const tabIndex = index + 1;
            return (
              <CustomButton tabIndex={tabIndex}>
                <Box sx={toolbarButton}>
                  <MarkToolbarButton
                    id={id}
                    styles={toolbarButtonStyles}
                    style={{ color: 'black' }}
                    key={element}
                    type={getPluginType(editor, element)}
                    icon={<span style={iconStyle}>{icon}</span>}
                  />
                </Box>
              </CustomButton>
            );
          }
          return null;
        })}
      </ButtonGroup>

      <ButtonGroup variant="outlined" color="inherit">
        {ToolbarButton.map(({ element, icon, type }, index) => {
          if (type === 'LIST') {
            autoformatOptions.rules.push(...autoformatLists);
            const tabIndex = index + 1;
            return (
              <CustomButton tabIndex={tabIndex}>
                <Box sx={toolbarButton}>
                  <ListToolbarButton
                    id={id}
                    styles={toolbarButtonStyles}
                    style={{ color: 'black' }}
                    key={element}
                    type={getPluginType(editor, element)}
                    icon={
                      <span
                        style={{
                          ...iconStyle,
                          paddingTop: '9px',
                          paddingLeft: '5px',
                        }}
                      >
                        {icon}
                      </span>
                    }
                  />
                </Box>
              </CustomButton>
            );
          }
          return null;
        })}
      </ButtonGroup>

      <ButtonGroup variant="outlined" color="inherit">
        <CustomButton tabIndex={1}>
          <Box sx={toolbarButton}>
            <LinkToolbarButton
              styles={toolbarButtonStyles}
              style={{ color: 'black' }}
              id={id}
              icon={
                <span
                  style={{
                    ...iconStyle,
                    paddingTop: '11px',
                    paddingLeft: '0px',
                  }}
                >
                  {<Link />}
                </span>
              }
              tooltip={{ content: '' }}
            />
          </Box>
        </CustomButton>
      </ButtonGroup>
    </>
  );
};
