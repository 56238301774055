import React from 'react';
import Helmet from 'react-helmet';

function Layout({ children, ...props }) {
  return (
    <>
      <Helmet>
        <title>Prism</title>
        <link rel="icon" type="image/ico" href="/prism.ico" />
        <link rel="stylesheet" href="https://core.modulz.app/fonts/fonts.css" />

        <meta property="og:url" content="https://radix.modulz.app/" />
        <meta
          property="og:image"
          content="https://radix.modulz.app/social/hero.png"
        />
        <meta property="og:title" content="Prism" />
        <meta
          property="og:description"
          content="Design System and Component Library for OC Tanner"
        />
      </Helmet>
      {children}
    </>
  );
}

export default Layout;
