import ListItemIcon from '../ListItemIcon';
import { styled } from '@mui/material/styles';
import MenuItemBase from './MenuItemBase';
import {
  tannerSemantic,
  tannerDanger,
  tannerGray,
} from '../ThemeProvider/colors';

export const StyledMenuItemBase = styled(MenuItemBase)<{
  variant?: 'normal' | 'check' | 'danger';
}>(({ variant }) => ({
  ...(variant === 'check' && {
    minWidth: 284,
    border: '2px solid transparent',
    paddingRight: '12px',
    '&.Mui-selected': {
      color: tannerSemantic.info.color,
      backgroundColor: tannerSemantic['info']['background'],
      '&:hover': { backgroundColor: '#CBDEF8' },
    },
    '+.MuiDivider-root': {
      marginTop: '6px',
    },
  }),
  ...(variant === 'danger' && {
    '&:not(.Mui-disabled)': {
      color: tannerDanger['light'],
      '&:hover': {
        backgroundColor: tannerDanger['main'],
      },
      '&:focus-visible': {
        backgroundColor: tannerDanger['main'],
      },
    },
  }),

  '.MuiCheckbox-root': {
    padding: '0px',
    height: '18px',
    width: '18px',
  },

  '.Mui-disabled': {
    color: tannerGray[600],
    fontSize: '14px',
    fontWeight: '400',
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)({
  color: 'inherit',
  fontSize: '17px',
  marginRight: '-12px',
  marginLeft: '4px',
});
