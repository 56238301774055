import React, { useEffect, useRef, useState } from 'react';
import { StyledBox, LoaderContainer } from './styles';
import RenderSkeletonLoader from './RenderSkeletonLoader';
import { ScrollLoaderProps } from './types';

export default function ScrollLoader({
  load,
  pageSize,
  size = 'auto',
}: ScrollLoaderProps) {
  const loadingRef = useRef<HTMLDivElement>(null);
  const [trigger, setTrigger] = useState(false);

  const observerOptions = {
    root: document.getElementById('scrollArea'),
    rootMargin: '-100px',
    threshold: 0.1,
  };

  useEffect(() => {
    if (!trigger) return;
    load();
    setTrigger(false);
  }, [trigger]);

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].intersectionRatio <= 0) return;
    setTrigger(true);
  };

  const [observer] = useState(
    () => new IntersectionObserver(handleObserver, observerOptions),
  );

  useEffect(() => {
    if (observer && loadingRef.current) {
      observer.observe(loadingRef.current);
      return () => observer.disconnect();
    }
  }, [observer]);

  return (
    <LoaderContainer ref={loadingRef} data-testid="scroll-loader">
      <StyledBox size={size} sx={{ paddingTop: '12px' }}>
        <RenderSkeletonLoader pageSize={pageSize} />
      </StyledBox>
    </LoaderContainer>
  );
}
