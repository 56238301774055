import { Play } from '@octanner/prism-icons';
import { styled } from '@mui/material';
import {
  tannerSemantic,
  tannerBlack,
  tannerWhite,
} from '../ThemeProvider/colors';

export const VideoContainer = styled('div', { name: 'VideoContainer' })({
  display: 'flex',
  borderRadius: '3px',
  position: 'relative',
  '&:img, &:video': {
    maxWidth: '100%',
  },
  justifyContent: 'center',
  alignItems: 'center',
});

export const Button = styled('button', { name: 'Button' })({
  border: 'none',
  width: 75,
  height: 75,
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  fontSize: 30,
  borderRadius: '50%',
  backgroundColor: `${tannerBlack}B3`, // 70% opacity
  top: '0%',
  left: '0%',
  right: '0%',
  bottom: '5%',
  margin: 'auto',
  '&:hover': {
    backgroundColor: `${tannerBlack}CC`, // 80% opacity
  },
  '&:active': {
    backgroundColor: `${tannerBlack}E6`, // 90% opacity
  },
  '@media screen and (max-width: 400px)': {
    width: 60,
    height: 60,
    fontSize: 22,
    bottom: '10%',
  },
});

export const StyledPlayIcon = styled(Play, { name: 'StyledPlayIcon' })({
  color: `${tannerWhite}CC`, // 80% opacity
});

export const Video = styled('video', { name: 'Video' })({
  width: 'calc(100% - 12px)',
  height: 'calc(100% - 12px)',
  margin: '8px',
  borderRadius: '3px',
  '&:focus': {
    outline: `3px solid ${tannerSemantic.info.color}`,
    outlineOffset: '3px',
  },
});
