import { styled } from '@mui/material';
import DotsLoader from './dotsLoader';
import Typography from '../Typography';
import React from 'react';

interface Props {
  height?: string | number;
  size?: string | number;
  type?: 'dots';
  color?: 'primary' | 'secondary' | 'blue';
  percent?: number;
  label?: string;
  loading?: boolean;
  onClick?(): void;
  adminColor?: boolean;
}

const MIN = 0;
const MAX = 100;

// Function to normalise the values (MIN / MAX could be integrated)
const normalise = (percent: number) => {
  if (percent > MAX) return MAX;
  if (percent < MIN) return MIN;
  return Math.round(((percent - MIN) * 100) / (MAX - MIN));
};

const loadingWrapperProps = ['loading', 'label'];

const LoadingWrapper = styled('div', {
  shouldForwardProp: (prop) => !loadingWrapperProps.includes(prop.toString()),
})<Props>(({ height = '15vh', label, loading }) => ({
  display: loading ? 'flex' : 'none',
  flexDirection: label ? 'column' : 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  height,
}));

const Percent = styled('div')<Props>(() => ({
  position: 'relative',
  minWidth: 45,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Label = styled('div')({
  marginTop: 5,
});

const Loader: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  size,
  percent,
  label,
  color,
  onClick,
  loading = false,
  type = 'dots',
  adminColor = false,
  ...props
}) => {
  return (
    <LoadingWrapper
      {...props}
      label={label}
      loading={loading}
      type={type}
      percent={percent}
      data-testid="loading-element"
    >
      {type === 'dots' && <DotsLoader color={adminColor ? 'blue' : color} />}
      {percent && (
        <Percent type={type}>
          <Typography variant="caption" color="textSecondary">
            {`${normalise(percent)}%`}
          </Typography>
        </Percent>
      )}
      {label && (
        <Label>
          <Typography variant="caption" color="textSecondary">
            {label}
          </Typography>
        </Label>
      )}
    </LoadingWrapper>
  );
};

export default Loader;
