import { styled } from '@mui/material';
import React from 'react';
import { mobileMediaQuery } from '.';
import DialogActions from '../DialogActions';
import DialogContent from '../DialogContent';
import useMediaQuery from '../hooks/useMediaQuery';
import ModalNav from './ModalNav';
import { CustomDialogActions } from './styles';
import { ModalProps } from './types';

const FullScreenContainer = styled('div')({
  maxWidth: '768px',
  margin: '10px auto',
  width: '100%',
});

const ModalContent: React.FC<
  ModalProps & React.ComponentProps<typeof DialogContent>
> = ({
  actions,
  backText,
  backDataTestId,
  backButtonProps,
  children,
  id,
  onBack,
  onClose,
  title,
  hideTitle,
  titleTypographyProps,
  fullScreen,
  disableCloseButton,
  disableBackText,
  backTextAutoFocus,
}) => {
  const [content, setContent] = React.useState<HTMLDivElement | null>(null);
  const [upperShadow, setUpperShadow] = React.useState(false);
  const [lowerShadow, setLowerShadow] = React.useState(false);
  const isMobile = useMediaQuery(mobileMediaQuery);

  React.useEffect(() => {
    if (content) {
      const setScrollVars = () => {
        if (content.scrollTop < 5) {
          setUpperShadow(false);
        } else {
          setUpperShadow(true);
        }

        if (
          content.scrollTop + content.offsetHeight >
          content.scrollHeight - 5
        ) {
          setLowerShadow(false);
        } else {
          setLowerShadow(true);
        }
      };

      const resizeObserver =
        'ResizeObserver' in window ? new ResizeObserver(setScrollVars) : null;
      setScrollVars();
      content.addEventListener('scroll', () => setScrollVars());
      if (resizeObserver) resizeObserver.observe(content);

      return () => {
        content.removeEventListener('scroll', setScrollVars);
        if (resizeObserver) resizeObserver.unobserve(content);
      };
    }
  }, [content, setUpperShadow, setLowerShadow]);
  return (
    <>
      <ModalNav
        backText={backText}
        backButtonProps={backButtonProps}
        backDataTestId={backDataTestId}
        id={id}
        onBack={onBack}
        onClose={onClose}
        title={title}
        hideTitle={hideTitle}
        titleTypographyProps={titleTypographyProps}
        fullScreen={fullScreen}
        disableCloseButton={disableCloseButton}
        disableBackText={disableBackText}
        upperShadow={upperShadow}
        backTextAutoFocus={backTextAutoFocus}
        isMobile={isMobile}
      />
      {fullScreen ? (
        <DialogContent ref={setContent} data-testid="DialogContent:FullScreen">
          <FullScreenContainer>
            {children}
            <DialogActions sx={{ marginTop: '48px' }}>{actions}</DialogActions>
          </FullScreenContainer>
        </DialogContent>
      ) : (
        <>
          <DialogContent ref={setContent} data-testid="DialogContent:Standard">
            {children}
          </DialogContent>
          <CustomDialogActions
            className={lowerShadow ? 'shadow' : undefined}
            isMobile={isMobile}
          >
            {actions}
          </CustomDialogActions>
        </>
      )}
    </>
  );
};

export default ModalContent;
