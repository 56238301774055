import { ApolloError, gql, useQuery } from '@apollo/client';

interface BellIndicatorProps {
  isActive?: boolean;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
}

const useIsBellActive = (): BellIndicatorProps => {
  const { data, loading, error, refetch } = useQuery<{
    ccIsBellIndicatorActive: { isBellIndicatorActive: boolean };
  }>(BELL_INDICATOR_ACTIVE);
  return {
    isActive: data?.ccIsBellIndicatorActive?.isBellIndicatorActive,
    loading,
    error,
    refetch,
  };
};

export const BELL_INDICATOR_ACTIVE = gql`
  query CcIsBellIndicatorActive {
    ccIsBellIndicatorActive {
      isBellIndicatorActive
    }
  }
`;

export default useIsBellActive;
