import React, { forwardRef } from 'react';
import MuiFormHelperText, {
  FormHelperTextProps,
} from '@mui/material/FormHelperText';

const FormHelperText = forwardRef<HTMLDivElement, FormHelperTextProps>(
  (props, ref) => {
    return (
      <MuiFormHelperText
        ref={ref}
        {...props}
        sx={{ fontSize: 12, ml: 5, mt: 0, ...props.sx }}
      />
    );
  },
);

export default FormHelperText;
export * from '@mui/material/FormHelperText';
