import styled from '@emotion/styled';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { tannerGray } from '../ThemeProvider/colors';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)<{
  variant?: 'primary' | 'secondary';
  disabled: boolean;
  iconOnly: boolean;
}>`
  background-color: ${({ variant }) =>
    variant === 'secondary' ? '#fff' : tannerGray[200]};
  border-radius: ${({ variant }) => (variant === 'secondary' ? '' : '30px')};
  border: ${({ variant }) =>
    variant === 'secondary'
      ? `1px solid ${tannerGray[300]}`
      : `4px solid ${tannerGray[200]}`};
  height: 40px;
  column-gap: ${({ variant }) => (variant === 'secondary' ? '0' : '4px')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  .MuiToggleButton-root {
    border-radius: ${({ iconOnly, variant }) => {
      if (variant === 'secondary') {
        return '';
      }
      return iconOnly ? '50%' : '30px';
    }};
    width: ${({ iconOnly, variant }) => {
      if (iconOnly) {
        return variant === 'secondary' ? '40px' : '32px';
      }
      return 'auto';
    }};
    height: ${({ iconOnly, variant }) => {
      if (iconOnly) {
        return variant === 'secondary' ? 'auto' : '32px';
      }
      return 'auto';
    }};
    padding: ${({ iconOnly, variant }) => {
      if (variant === 'secondary') {
        return '';
      }
      return iconOnly ? '8px' : '';
    }};
    border: ${({ variant }) =>
      variant === 'secondary' ? 'none' : 'transparent'};
    column-gap: 8px;
    transition: all 0.3s ease-in;
    font-size: 14px;

    svg {
      vertical-align: middle;
    }

    &.Mui-disabled {
      border-radius: ${({ variant }) =>
        variant === 'secondary' ? '' : 'inherit'};
      border-left: 0;
    }

    &.MuiButtonBase-root {
      padding: ${({ variant }) => (variant === 'secondary' ? '8px' : '')};
      margin-left: 0;
    }

    &.Mui-selected {
      background-color: ${({ variant }) =>
        variant === 'secondary' ? tannerGray[200] : '#fff'};
      transition: '';
      box-shadow: ${({ variant }) =>
        variant === 'secondary' ? '' : '0px 1px 1px 0px rgba(0, 0, 0, 0.25)'};
      &:hover {
        background-color: ${({ variant }) =>
          variant === 'secondary' ? tannerGray[200] : tannerGray[300]};
      }
      &:active {
        background-color: ${({ variant }) =>
          variant === 'secondary' ? tannerGray[300] : tannerGray[400]};
      }
    }

    &:hover {
      background-color: ${({ variant }) =>
        variant === 'secondary' ? tannerGray[200] : tannerGray[300]};
    }

    &:active {
      background-color: ${({ variant }) =>
        variant === 'secondary' ? tannerGray[300] : tannerGray[400]};
    }

    &:focus-visible {
      background-color: ${tannerGray[300]};
      outline: 3px solid #0060e0;
      outline-offset: 3px;
      z-index: 1;
    }

    &.MuiButtonBase-root:first-child {
      border-top-left-radius: ${({ variant }) =>
        variant === 'secondary' ? '3px' : ''};
      border-bottom-left-radius: ${({ variant }) =>
        variant === 'secondary' ? '3px' : ''};
    }

    &.MuiButtonBase-root:last-child {
      border-top-right-radius: ${({ variant }) =>
        variant === 'secondary' ? '3px' : ''};
      border-bottom-right-radius: ${({ variant }) =>
        variant === 'secondary' ? '3px' : ''};
    }

    &.MuiButtonBase-root:not(:first-child) {
      border-left: ${({ variant }) =>
        variant === 'secondary'
          ? `1px solid ${tannerGray[300]} !important`
          : ''};
    }

    &.MuiButtonBase-root {
      border: ${({ variant }) => (variant === 'secondary' ? '' : 'none')};
    }
  }
`;

export const LabelWrapper = styled.span<{
  isSelected: boolean;
  variant?: 'primary' | 'secondary';
}>`
  color: ${({ isSelected, variant }) => {
    if (variant === 'secondary') {
      return tannerGray[800];
    }
    return isSelected ? '#0060E0' : '#525252';
  }};
  text-transform: none;
  font-weight: ${({ variant }) => (variant === 'secondary' ? '700' : '500')};
`;

export const IconWrapper = styled.span<{
  isSelected: boolean;
  variant?: 'primary' | 'secondary';
}>`
  color: ${({ isSelected, variant }) => {
    if (variant === 'secondary') {
      return isSelected ? tannerGray[700] : tannerGray[800];
    }
    return isSelected ? '#0060E0' : '#525252';
  }};
  margin: 0px;
`;
