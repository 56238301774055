import React from 'react';
import { VideoContainer, Video, Button, StyledPlayIcon } from './styles';

const VideoPlayer: React.FC<
  React.HTMLAttributes<HTMLDivElement> & VideoPlayerProps
> = ({ videoUrl, posterUrl, ...props }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [paused, setPaused] = React.useState(true);
  const [mouseOver, setMouseOver] = React.useState(false);
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setMouseOver(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <VideoContainer
      {...props}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {videoUrl && (
        <>
          <Video
            onMouseDown={(e) => e.preventDefault()}
            onLoadedData={() => {
              setVideoLoaded(true);
            }}
            ref={videoRef}
            controls={videoLoaded && mouseOver ? true : false}
            onPlay={() => setPaused(false)}
            onPause={() => setPaused(true)}
            playsInline
            poster={posterUrl}
            preload=""
            controlsList="nodownload"
            disablePictureInPicture
            data-testid="video"
            aria-label={
              paused
                ? `play ${props['aria-label']} video`
                : `pause ${props['aria-label']} video`
            }
          >
            <source
              src={videoUrl}
              type="video/mp4"
              data-testid="video-source"
            />
          </Video>
          {paused && videoLoaded && (
            <Button
              onClick={() => videoRef.current?.play()}
              tabIndex={-1}
              aria-hidden={true}
            >
              <StyledPlayIcon />
            </Button>
          )}
        </>
      )}
    </VideoContainer>
  );
};

export default VideoPlayer;
