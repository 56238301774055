import React from 'react';
import DialogBase from '../DialogBase';
import ModalBase from './ModalBase';
import ModalContent from './ModalContent';
import { ModalProps } from './types';

export const mobileMediaQuery = '(max-width: 480px)';

const Modal: React.FC<ModalProps & React.ComponentProps<typeof DialogBase>> = ({
  actions,
  backText,
  backDataTestId,
  backButtonProps,
  children,
  id,
  onBack,
  onClose,
  open,
  title,
  hideTitle,
  titleTypographyProps,
  fullScreen,
  disableCloseButton,
  disableBackText,
  backTextAutoFocus = false,
  ...props
}) => {
  return (
    <ModalBase
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      id={id}
      {...props}
    >
      <ModalContent
        actions={actions}
        backText={backText}
        backDataTestId={backDataTestId}
        backButtonProps={backButtonProps}
        id={id}
        onBack={onBack}
        onClose={onClose}
        title={title}
        hideTitle={hideTitle}
        titleTypographyProps={titleTypographyProps}
        fullScreen={fullScreen}
        disableCloseButton={disableCloseButton}
        disableBackText={disableBackText}
        backTextAutoFocus={backTextAutoFocus}
      >
        {children}
      </ModalContent>
    </ModalBase>
  );
};

export default Modal;
