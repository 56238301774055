import styled from '@emotion/styled';
import MenuItemLink from '../MenuItem/MenuItemLink';
import { tannerBlue, tannerGray } from '../ThemeProvider/colors';
import Avatar from '../Avatar';
import Button from '../Button';
import Skeleton from '@mui/material/Skeleton';
import { ListItemText } from '@mui/material';
import { ContainerCellProps } from './types';
import { Theme } from '@mui/material/styles';
import { AvatarProps } from '../Avatar/types';

export const HeaderContainer = styled('header')({
  minHeight: 72,
});

export const ProfileMenuItem = styled(MenuItemLink)`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const HeaderRow = styled('div', {
  shouldForwardProp: (prop) => !/secondary|slim/.test(String(prop)),
})<{
  secondary?: boolean;
  slim?: boolean;
}>(
  ({ secondary = false, slim = false }) => `
  background-color: ${secondary ? tannerGray[900] : '#fff'};
  box-shadow: ${secondary || slim ? 'none' : '0px 1px 0px #C4C4C4'};
  color: ${secondary ? '#fff' : 'inherit'};
  height: ${secondary ? 56 : 72}px;
  position: relative;
`,
);

export const HeaderInnerRow = styled.div<{
  centered?: boolean;
}>(
  ({ centered = false }) => `
  display: flex;
  height: 100%;
  align-items: ${centered ? 'center' : 'inherit'};
  justify-content: ${centered ? 'center' : 'space-between'};
  margin: 0 auto;
  max-width: 960px;
  padding: 0 8px; 
`,
);

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
`;

export const AutoCompleteHeaderCell = styled(HeaderCell, {
  shouldForwardProp: (prop) => !/searchOpen|snipBorder/.test(String(prop)),
})<{
  searchOpen?: boolean;
  snipBorder?: boolean;
}>(
  ({ searchOpen = false, snipBorder = false }) => `
  align-self: center;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 1;
  margin-left: auto;
  max-width: 500px;
  transition: all 200ms;
  ${
    searchOpen
      ? `
    background-color: ${tannerGray['800']};
    flex-grow: 1;
    margin-left: unset;
  `
      : ''
  }
  ${
    snipBorder
      ? `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `
      : ''
  }
`,
);

export const HeaderAvatar = styled(Avatar)<AvatarProps>`
  margin-left: 8px;
`;

export const Logo = styled.div`
  display: flex;
  padding: 0 96px 0 16px;
`;

export const LogoImage = styled.img`
  width: 100%;
`;

export const CancelButton = styled(Button)`
  color: ${tannerGray['400']};
  font-weight: 500;
`;

const outlineColor = tannerBlue['500'];

export const buttonFocusOverride = {
  '&:focus-visible': {
    backgroundColor: tannerGray['100'],
    outline: `3px solid ${outlineColor}`,
    outlineOffset: '3px',
    borderRadius: '4px',
  },
};
export const tabFocusOverride = {
  '&:focus-visible': {
    outline: `3px solid ${outlineColor}`,
    outlineOffset: '-8px',
    borderRadius: '14px',
  },
};

export const StyledSkeleton = styled(Skeleton)<{ theme: Theme }>(
  ({ theme }) => `
    height: 24,
    width: 80,
    marginRight: ${theme.spacing(2)},
  `,
);

export const StyledLogoLink = styled.a`
  height: 40px;
`;

export const StyledLogoImage = styled(LogoImage)`
  height: 40px;
  max-width: 220px;
  object-fit: contain;
`;

export const StyledCultureCloudLogoLink = styled.a`
padding-top: 8px;
  '&:focus-visible': {
    outline: 3px solid ${outlineColor},
    outlineOffset: '4px',
    borderRadius: '2px',
  },
`;

export const SecondaryListItemText = styled(ListItemText)`
  text-align: right;
`;

export const ContainerCell = styled(HeaderCell)<ContainerCellProps>(
  ({ searchOpen }) => `
    max-width: 800px;
    width: ${searchOpen ? 0 : '100%'};
  `,
);

export const LogoCell = styled(HeaderCell)`
  padding-right: 40px;
`;

export const MenuAvatarText = styled.div`
  font-size: 14px;
  margin-left: 8px;
  font-weight: 700;
`;
