import React, { useRef, useEffect, useState, ReactNode } from 'react';
import {
  ClampTextWrapper,
  Container,
  TextContainer,
  StyledButton,
} from './styles';
import { useTranslation } from 'react-i18next';

interface ClampedTextProps {
  text: ReactNode | string;
  maxLines: number;
  severity?: 'error' | 'warning' | 'info' | 'success' | 'secondary';
}

const ClampText: React.FC<ClampedTextProps> = ({
  text,
  maxLines,
  severity,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isClamped, setIsClamped] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    // added 1px buffer to line height to account for line height differences when used html and css
    const lineHeight =
      parseInt(window.getComputedStyle(container).lineHeight) + 1;
    const maxHeight = lineHeight * maxLines;
    setIsClamped(container.scrollHeight > maxHeight);

    if (expanded) {
      setContainerHeight(container.scrollHeight);
    } else {
      setContainerHeight(container.clientHeight);
    }
  }, [text, maxLines, expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <ClampTextWrapper>
      <Container containerHeight={containerHeight}>
        <TextContainer
          expanded={expanded}
          maxLines={maxLines}
          ref={containerRef}
          containerHeight={containerHeight}
        >
          {text}
        </TextContainer>
      </Container>
      {isClamped && (
        <StyledButton
          size="small"
          color="secondary"
          buttonType="secondary"
          variant="text"
          severity={severity}
          onClick={toggleExpanded}
        >
          {expanded
            ? t('prism-header:show-less', 'Show Less')
            : t('prism-header:show-more', 'Show More')}
        </StyledButton>
      )}
    </ClampTextWrapper>
  );
};

export default ClampText;
