import styled from '@emotion/styled';
import { tannerGray } from '../ThemeProvider/colors';
import Typography from '../Typography';
import Link from '../Link';
import { StyledDetailsDivProps, UserNameProps } from './types';

const outlineColor = ' #0060E0';

export const AvatarLockupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const AvatarInfo = styled('div')<StyledDetailsDivProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: props.align === 'left' ? 'flex-start' : 'flex-end',
  padding: '8px',
  '> div': {
    display: 'inline-flex',
    columnGap: '4px',
  },
}));

export const UserNameLink = styled(Link)<UserNameProps>((props) => ({
  fontWeight: props.emphasized ? '800' : '500',
  ...(props.lineOfText && { fontSize: 'inherit' }),
  ':focus-visible': {
    outline: `2px solid ${outlineColor}`,
    outlineOffset: '2px',
    borderRadius: '2px',
  },
}));

export const LineOfTextContainer = styled('div')({
  display: 'inline-flex',
  columnGap: '4px',
  alignItems: 'baseline',
});

const PronounStyles = {
  color: tannerGray['600'],
  fontWeight: '500',
};

export const UserPronounsTypography = styled(Typography)({
  ...PronounStyles,
});

export const UserPronounsSpan = styled('span')({
  ...PronounStyles,
});

export const InformationTypography = styled(Typography)({
  color: tannerGray['600'],
});
