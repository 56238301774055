import { styled } from '@mui/material';
import MuiSwitch from '@mui/material/Switch';
import { Check, Close } from '@octanner/prism-icons';
import React from 'react';
import { tannerGray } from '../ThemeProvider/colors';

type Size = 'small' | 'medium';

const usePixelSize = (size: Size) => {
  let dimensions;
  switch (size) {
    case 'small':
      dimensions = {
        width: 16,
        height: 16,
        fontSize: 10,
        margin: '0px',
      };
      break;
    case 'medium':
      dimensions = {
        width: 18,
        height: 18,
        fontSize: 12,
        margin: '1px 2px',
      };
      break;
  }
  let IconWrapper = styled('div', { name: 'IconWrapper' })({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    color: tannerGray['300'],
    ...dimensions,
    borderRadius: 50,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
  });
  return IconWrapper;
};

const Switch: React.FC<React.ComponentProps<typeof MuiSwitch>> = ({
  size = 'medium',
  ...props
}) => {
  const IconWrapper = usePixelSize(size);
  return (
    <MuiSwitch
      size={size}
      checkedIcon={
        <IconWrapper>
          <Check />
        </IconWrapper>
      }
      icon={
        <IconWrapper>
          <Close />
        </IconWrapper>
      }
      {...props}
    />
  );
};
export default Switch;
