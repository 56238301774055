import React, { ReactElement, ReactNode, useState } from 'react';
import {
  default as MuiChip,
  ChipProps as MuiChipProps,
} from '@mui/material/Chip';
import { Close } from '@octanner/prism-icons';
export * from '@mui/material/Chip';

type ChipProps = MuiChipProps & {
  avatar?: ReactElement;
  clickable?: boolean;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  'data-testid'?: string;
  deleteIcon?: ReactElement;
  disabled?: boolean;
  icon?: ReactElement;
  label?: ReactNode;
  onClick?: React.MouseEventHandler;
  onDelete?: React.MouseEventHandler;
  size?: 'small' | 'medium';
  skipFocusWhenDisabled?: boolean;
  variant?: 'filled' | 'outlined';
  'aria-label'?: string;
};

const Chip: React.FC<ChipProps> = ({
  avatar,
  classes,
  className,
  clickable,
  color,
  'data-testid': testid,
  deleteIcon,
  disabled,
  icon,
  label,
  onClick,
  onDelete,
  size,
  skipFocusWhenDisabled,
  style,
  sx,
  variant,
  'aria-label': ariaLabel,
}) => {
  const [clicked, setClicked] = useState<boolean>(false);

  const getVariant = (clicked: boolean) => {
    if (variant === 'filled') {
      return clicked ? 'outlined' : 'filled';
    } else return clicked ? 'filled' : 'outlined';
  };

  const getClickable = () => {
    if (clickable !== undefined) {
      return clickable;
    }
    if (onClick) {
      return true;
    } else {
      return false;
    }
  };

  const createDataTestId = (id?: string) => {
    if (id !== undefined) {
      return `chip:${id}`;
    }
    return 'chip';
  };

  return (
    <MuiChip
      avatar={avatar}
      classes={classes}
      className={className}
      clickable={getClickable()}
      color={color}
      data-testid={createDataTestId(testid)}
      deleteIcon={
        deleteIcon || (
          <Close titleAccess='Remove' data-testid={`${createDataTestId(testid)}:deleteicon`} />
        )
      }
      disabled={disabled}
      icon={icon}
      label={label}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          setClicked(!clicked);
          onClick(e);
        }
      }}
      onDelete={onDelete}
      size={size}
      skipFocusWhenDisabled={skipFocusWhenDisabled}
      style={style}
      sx={sx}
      variant={getVariant(clicked)}
      aria-label={ariaLabel}
    />
  );
};

export default Chip;
