import {
  FormatBold,
  FormatItalic,
  BulletedList,
  NumberedList,
  FormatUnderline,
  Link,
} from '@octanner/prism-icons';

import {
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_TABLE,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
} from '@udecode/plate';
import React from 'react';
interface ToolbarContent {
  element: string;
  icon: JSX.Element | null;
  type: 'MARK' | 'BLOCK' | 'LIST' | 'LINK' | 'TABLE';
}

export const ToolbarButton: ToolbarContent[] = [
  {
    element: MARK_BOLD,
    icon: <FormatBold />,
    type: 'MARK',
  },
  {
    element: MARK_ITALIC,
    icon: <FormatItalic />,
    type: 'MARK',
  },
  {
    element: MARK_UNDERLINE,
    icon: <FormatUnderline />,
    type: 'MARK',
  },
  {
    element: ELEMENT_UL,
    icon: <BulletedList />,
    type: 'LIST',
  },
  {
    element: ELEMENT_OL,
    icon: <NumberedList />,
    type: 'LIST',
  },
  {
    element: ELEMENT_LINK,
    icon: <Link />,
    type: 'LINK',
  },
  {
    element: ELEMENT_TABLE,
    icon: null,
    type: 'TABLE',
  },
];
