import React from 'react';
import { Box, Table, Thead, Tr, Th, Tbody, Td, Heading } from '@modulz/radix';

export function TypesTable({ data = {} }) {
  return (
    <Box
      mt={8}
      mb={7}
      sx={{
        overflow: ['scroll', 'visible'],
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Heading as="h3" size={2} mt={8} mb={4}>
        Types
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(data).map(([key, value]) => {
            return (
              <Tr key={key}>
                <Td>{value.name}</Td>
                <Td sx={{ lineHeight: 1 }}>{value.description}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
}
