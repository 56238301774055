import { CardContent, styled, Typography } from '@mui/material';
import Card from '../Card';
import CardActionArea from '../CardActionArea';
import {
  tannerGray,
  tannerWhite,
  tannerSemantic,
  tannerBlack,
} from '../ThemeProvider/colors';
import Button from '../Button';
import Box from '../Box';
import VideoPlayer from '../VideoPlayer';

export const StyledCard = styled(Card, {
  name: 'StyledCard',
  shouldForwardProp: (prop) => !/skeleton/.test(String(prop)),
})<{
  skeleton?: boolean;
  isError?: boolean;
}>(({ skeleton, isError }) => ({
  position: 'relative',
  borderRadius: 3,
  minWidth: 100,
  maxWidth: 286,
  aspectRatio: '16/9',
  backgroundColor: tannerGray['100'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    boxShadow:
      skeleton || isError
        ? ''
        : `0 8px 10px ${tannerBlack}24, 0 3px 14px ${tannerBlack}1F, 0 4px 14px ${tannerBlack}33`, // 14% opacity, 12% opacity, 20% opacity
  },
  '&:focus': {
    outline: `3px solid ${tannerSemantic.info.color}`,
    outlineOffset: '3px',
  },
}));

export const StyledName = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  color: white;
  padding-left: 22px;
  padding-bottom: 20px;
  font-weight: 800;
`;

export const StyledCardActionArea = styled(CardActionArea, {
  name: 'StyledCardActionArea',
})({
  height: '100%',
  width: '100%',
  '& img': {
    height: '100%',
  },
});

export const MediaIconButton = styled(Button, { name: 'MediaIconButton' })({
  border: 'none',
  minWidth: 'unset',
  width: 40,
  height: 40,
  position: 'absolute',
  color: tannerWhite,
  fontSize: 30,
  background: `${tannerBlack}B3`, // 70% opacity
  borderRadius: '50%',
  '&:hover, &:focus': {
    background: `${tannerBlack}CC`, // 80% opacity
    color: tannerWhite,
  },
  '&:active': {
    background: `${tannerBlack}E6`, // 90% opacity
    color: tannerWhite,
  },
  '&.Mui-focusVisible': {
    boxShadow: 'none',
    outline: `3px solid ${tannerSemantic.info.color}`,
    outlineOffset: '3px',
  },
});

export const MediaButton = styled(MediaIconButton, { name: 'MediaButton' })({
  borderRadius: '50%',
  '& svg': {
    color: `${tannerWhite}CC`, // 80% opacity
    width: 11.88,
    height: 16,
  },
});

export const MediaCardContent = styled(CardContent, {
  name: 'MediaCardContent',
})({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const MediaCardWrapper = styled('div', {
  name: 'MediaCardWrapper',
})({
  '&:focus': {
    boxShadow: 'none',
    outline: `3px solid ${tannerSemantic.info.color}`,
    outlineOffset: '3px',
  },
});

export const StyledBrokenImage = styled('div', { name: 'StyledBrokenImage' })({
  width: '20px',
  height: '20px',
});

export const StyledBackground = styled(Box)<{ mediaType: string }>`
  display: flex;
  height: 95px;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0.15) 29%,
    rgba(0, 0, 0, 0.9) 89.5%
  );
  position: absolute;
  bottom: ${(props) => (props.mediaType === 'img' ? '0' : '8px')};
  left: ${(props) => (props.mediaType === 'img' ? '0' : '7.5px')};
  width: ${(props) =>
    props.mediaType === 'img' ? '100%' : 'calc(100% - 15px)'};
  overflow: hidden;
`;

export const StyledVideoPlayer = styled(VideoPlayer, {
  name: 'StyledVideoPlayer',
})({
  width: '100%',
  height: 'auto',
  overflow: 'hidden',
});

export const StyledBox = styled(Box, {
  name: 'StyledBox',
  shouldForwardProp: (prop) => !/size/.test(String(prop)),
})<{
  size?: 'auto' | 'sm' | 'md';
}>(({ size, theme }) => ({
  padding: '6px',
  display: 'grid',
  justifyContent: 'start',
  gridGap: '12px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: `repeat(${
      size === 'sm' || size === 'auto' ? 2 : 4
    }, 1fr)`, //the width of the card
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `repeat(${
      size === 'md' || size === 'auto' ? 4 : 2
    }, 1fr)`, //the width of the card
  },
}));

export const LoadButton = styled(Button, {
  name: 'LoadButton',
})({
  width: '100%',
  height: 40,
  marginTop: 12,
});

export const TrashButton = styled(MediaIconButton, { name: 'TrashButton' })<{
  isVideo?: boolean;
}>(({ isVideo = false }) => ({
  borderRadius: 3,
  zIndex: 1,
  top: '0%',
  right: '0%',
  margin: isVideo ? '18px' : '10px',
  '& svg': {
    color: `${tannerWhite}E6`, // 90% opacity
    width: 12,
    height: 15,
  },
}));

export const ScrollArea = styled(Box)<{
  dataLength?: number | undefined;
  loading?: boolean;
}>(({ dataLength, loading }) => ({
  paddingBottom: 2,
  aspectRatio: loading || (dataLength && dataLength >= 16) ? 15 / 8 : 'auto',
  overflow: 'auto',
  '&:focus-visible': {
    outline: `3px solid ${tannerSemantic.info.color}`,
    outlineOffset: '3px',
  },
}));

export const LoaderContainer = styled('div', { name: 'LoaderContainer' })({
  width: '100%',
  gridColumn: 'span 4',
  textAlign: 'center',
});
