import React from 'react';
import { styled } from '@mui/material';

type Truncate = { truncate: false } | { truncate: true; maxWidth: number };

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const FirstName = styled('span', { name: 'FirstName' })<{
  truncate: boolean;
}>(({ truncate }) => ({
  flex: `0 ${truncate ? '1' : '0'} auto`,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const Name: React.FC<
  Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> & {
    children: string;
  }
> = ({ children, ...props }) => {
  if (!children && typeof children !== 'string') {
    throw new Error(
      'You must pass in the name as a string <Name>first last</Name>',
    );
  }

  const [firstName, lastName] = children.split(' ');
  const ref = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<Truncate>({ truncate: false });

  React.useEffect(() => {
    const onResize = () => {
      const el = ref?.current;
      if (el) {
        if (el.offsetWidth < el.scrollWidth) {
          setState({ truncate: true, maxWidth: el.scrollWidth });
        } else if (state.truncate && el.offsetWidth > state.maxWidth) {
          setState({ truncate: false });
        }
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [state, ref]);

  return (
    <Container ref={ref} {...props}>
      <FirstName truncate={state.truncate}>{firstName}</FirstName>
      {lastName && (
        <>
          &nbsp;
          <span>{state.truncate ? `${lastName[0]}.` : lastName}</span>
        </>
      )}
    </Container>
  );
};

export default Name;
