import { Box, Button } from '@octanner/prism-core';
import React from 'react';
import { GridToolbarExport } from '@mui/x-data-grid-pro';

type SearchSpec =
  | {
      search: true;
      onSearchClick: () => void;
    }
  | { search: false };

type DownloadSpec =
  | {
      download: true;
      downloadFileName: string;
    }
  | { download: false };

type Props = {
  actions?: React.ReactNode;
} & SearchSpec &
  DownloadSpec;

export default function GridToolbar(props: Props) {
  // TODO add translations
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '6px 12px',
      }}
    >
      <div>
        {props.search && (
          <Button id="search" onClick={props.onSearchClick} variant="text">
            Search
          </Button>
        )}
      </div>
      <div>
        {props.actions}

        {props.download && (
          <GridToolbarExport
            csvOptions={{
              fileName: props.downloadFileName,
            }}
            printOptions={{ disableToolbarButton: true }}
            getRowsToExport={() => {}}
          />
        )}
      </div>
    </Box>
  );
}

GridToolbar.defaultProps = {
  search: false,
  download: false,
};
