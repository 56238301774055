import React from 'react';
import {
  DarkBackgroundLink,
  PrimaryLink,
  SecondaryLink,
  TypographyNameLink,
} from './styles';
import { LinkProps } from './types';

const Link = ({
  linkVariant,
  context,
  children,
  color,
  href,
  onClick,
  css,
  target,
  rel,
  sx = {},
  style,
  to,
  className,
  id,
  component,
  key,
  underline,
  classes,
  variant,
  type,
  passHref,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
  onMouseOver,
  onMouseLeave,
  role,
  tabIndex,
  download,
}: LinkProps) => {
  let LinkComponent;
  switch (linkVariant) {
    case 'primary':
      LinkComponent = PrimaryLink;
      break;
    case 'secondary':
      LinkComponent = SecondaryLink;
      break;
    case 'name':
      LinkComponent = TypographyNameLink;
      break;
    case 'dark':
      LinkComponent = DarkBackgroundLink;
      break;
    default:
      LinkComponent = PrimaryLink;
  }

  return (
    <LinkComponent
      context={context}
      data-testid={dataTestId}
      href={href}
      color={color}
      linkVariant={linkVariant}
      onClick={onClick}
      css={css}
      target={target}
      rel={rel}
      sx={sx}
      style={style}
      to={to}
      className={className}
      id={id}
      component={component}
      key={key}
      underline={underline}
      classes={classes}
      variant={variant}
      type={type}
      passHref={passHref}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      role={role}
      tabIndex={tabIndex}
      download={download}
    >
      {children}
    </LinkComponent>
  );
};

export default Link;
