import styled from '@emotion/styled';
import { Divider, Typography } from '@mui/material';
import { tannerGray } from '../ThemeProvider/colors';
import MenuItemLink from '../MenuItem/MenuItemLink';
import MenuItem from '../MenuItem';

export const GroupHeader = styled('div')({
  boxSizing: 'border-box',
  lineHeight: '32px',
  listStyle: 'none',
  marginLeft: '-8px',
  paddingLeft: '16px',
  position: 'sticky',
  fontSize: '12px',
  backgroundColor: 'white',
  top: 0,
  zIndex: 1,
});

export const GroupItems = styled('ul')({
  padding: 0,
  fontSize: '14px',
});

export const ButtonText = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const menuItemStyle = {
  display: 'flex',
  paddingRight: '8px',
  justifyContent: 'space-between',
  marginTop: '4px',
};

export const GroupHeaderText = styled(Typography)({
  fontSize: '12px',
  color: tannerGray['600'],
});

export const FullWidthDivider = styled(Divider)({
  margin: '8px -8px',
});

export const MenuItemPrimary = styled(Typography)({
  fontWeight: 500,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const MenuItemSecondary = styled(Typography)({
  color: tannerGray['600'],
  marginLeft: '8px',
});

export const StyledMenuItemLink = styled(MenuItemLink)({
  ...menuItemStyle,
});

export const StyledMenuItem = styled(MenuItem)({
  cursor: 'auto',
  ...menuItemStyle,
});
