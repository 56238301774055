import React from 'react';
import TextField from '../TextField';
import Button from '../Button';
type SingleSubmitFieldProps = {
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  buttonDisabled?: boolean;
  buttonText: string;
  onSubmit?: () => void;
  error?: boolean;
  helperText?: string;
  id?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  multiline?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  autoComplete?: string;
  placeHolder?: string;
  tabIndex?: number;
  ariaLabel?: string;
  defaultValue?: string;
};

const SingleSubmitField: React.FC<SingleSubmitFieldProps> = ({
  disabled,
  buttonDisabled,
  buttonText,
  onSubmit,
  value,
  onChange,
  error,
  helperText,
  id,
  name,
  onBlur,
  multiline,
  autoFocus,
  fullWidth,
  autoComplete,
  placeHolder,
  tabIndex,
  ariaLabel,
  label,
  defaultValue,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) {
          return onSubmit();
        }
      }}
    >
      <TextField
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        id={id}
        name={name}
        onBlur={onBlur}
        multiline={multiline}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        autoComplete={autoComplete}
        placeholder={placeHolder}
        tabIndex={tabIndex}
        inputRef={(input) => input && autoFocus && input.focus()}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )
        }
        disabled={disabled}
        label={label}
        defaultValue={defaultValue}
        sx={{
          '.MuiInputBase-root': {
            paddingRight: '8px',
            paddingTop: multiline ? '8px' : '',
          },
          '.MuiInputBase-input': {
            paddingTop:
              multiline && label ? '16px' : multiline || !label ? '8px' : '',
          },
        }}
        InputProps={{
          endAdornment: (
            <Button
              variant="text"
              disabled={disabled || buttonDisabled}
              type="submit"
              data-testid="submit-field-button"
              style={{ height: '40px', alignSelf: 'center' }}
            >
              {buttonText}
            </Button>
          ),
        }}
        inputProps={{
          'aria-label': ariaLabel,
        }}
      />
    </form>
  );
};

export default SingleSubmitField;
