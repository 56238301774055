import React from 'react';
import { Grid, Skeleton } from '../index';
import { StyledCard } from './styles';
import { RenderSkeletonLoaderProps } from './types';

export default function RenderSkeletonLoader({
  pageSize,
}: RenderSkeletonLoaderProps) {
  return (
    <>
      {Array(pageSize)
        .fill('skeleton')
        .map((item, index) => (
          <Grid key={index} item xs data-testid={`media-library-${item}`}>
            <StyledCard skeleton key={index}>
              <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
            </StyledCard>
          </Grid>
        ))}
    </>
  );
}
