import { Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import {
  tannerBlack,
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '../ThemeProvider/colors';
import { LinkProps } from './types';

export const StyledLink = styled(MuiLink)<LinkProps>(() => ({
  '&:focus-visible': {
    outline: `3px solid ${tannerSemantic['info'].color}`,
    outlineOffset: '3px',
    borderRadius: '3px',
    transition: 'outline-offset 150ms',
  },
}));

export const PrimaryLink = styled(StyledLink)(({ context }) => ({
  color: tannerSemantic['info'].color,
  textDecoration: context === 'explicit' ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: context === 'explicit' ? '#0049AB' : tannerSemantic['info'].color,
  },
}));

export const SecondaryLink = styled(StyledLink)(({ context }) => ({
  color: tannerBlack,
  textDecoration: context === 'explicit' ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: context === 'explicit' ? tannerGray['600'] : tannerBlack,
  },
}));

export const DarkBackgroundLink = styled(StyledLink)(({ context }) => ({
  color: tannerWhite,
  backgroundColor: tannerBlack,
  textDecoration: context === 'explicit' ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: context === 'explicit' ? tannerGray['300'] : tannerWhite,
  },
}));

export const TypographyNameLink = styled(StyledLink)(({ context }) => ({
  color: tannerBlack,
  fontWeight: context === 'explicit' ? 800 : 500,
  '&:hover': {
    textDecoration: 'underline',
    color: tannerBlack,
  },
}));
