import React from 'react';
import { Close } from '@octanner/prism-icons';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '../Chip';
import TextField from '../TextField';
import { Identity } from '../Header/interfaces';
import SearchOptionCard from './SearchOptionCard';
import { tannerGray } from '../ThemeProvider/colors';
import { getFullName } from '@octanner/prism-utilities';
import classNames from 'classnames';
import { CustomPaper, getSearchInputStyles } from './styles';
import { Props } from './types';

const DEFAULT_OPTIONS: Identity[] = [];

// avoid passing down onchange prop for pagefilter
const Search = <
  OptionType extends object = Identity,
  Multiple extends boolean = true,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = true,
>({
  autoFocus = false,
  required = false,
  error,
  freeSolo,
  helperText,
  label,
  loading,
  multiple,
  options,
  placeholder,
  inputDataTestId,
  disabled,
  value,
  clearIcon,
  onChange,
  ...props
}: Props<OptionType, Multiple, DisableClearable, FreeSolo>) => (
  <Autocomplete<OptionType, Multiple, DisableClearable, FreeSolo>
    onChange={onChange}
    clearIcon={
      clearIcon ?? (
        <Close
          tabIndex={0}
          sx={(props.size && { color: tannerGray['600'] }) || {}}
        />
      )
    }
    filterOptions={(options) => options}
    freeSolo={freeSolo ?? (true as FreeSolo)}
    getOptionLabel={
      multiple ? undefined : getIdentityProperty('getOptionLabel')
    }
    sx={
      (props.size === 'small' && {
        '.MuiAutocomplete-clearIndicator': {
          '&:hover': {
            backgroundColor: tannerGray['200'],
          },
        },
      }) ||
      {}
    }
    multiple={multiple ?? (true as Multiple)}
    options={options ?? (DEFAULT_OPTIONS as OptionType[])}
    disabled={disabled}
    renderTags={(options, getTagProps) =>
      options.map((option, index) => (
        <Chip
          label={getIdentityProperty('renderTags')(option)}
          {...getTagProps({ index })}
          tabIndex={0}
        />
      ))
    }
    renderOption={(props, option) => (
      <SearchOptionCard
        {...props}
        avatarUrl={getIdentityProperty('renderOption')(option, 'avatarUrl')}
        showAvatar
        subTitle={getIdentityProperty('renderOption')(option, 'buName')}
        title={getIdentityProperty('renderOption')(option)}
      />
    )}
    PaperComponent={CustomPaper}
    renderInput={(props) => (
      <TextField
        {...props}
        // eslint-disable-next-line
        autoFocus={autoFocus}
        error={error}
        helperText={helperText}
        label={label}
        placeholder={
          Array.isArray(value) && Boolean(value?.length) ? '' : placeholder
        }
        variant="outlined"
        required={required}
        inputProps={{
          ...props.inputProps,
          'data-testid': inputDataTestId,
          className: classNames('prism-search', props.inputProps.className),
          onKeyDown: (e) => {
            if (
              e.key === 'Enter' &&
              (options?.length === 0 || loading || !onChange)
            ) {
              e.stopPropagation();
            }
          },
        }}
        InputProps={getSearchInputStyles(disabled, props, loading)}
      />
    )}
    value={value}
    {...props}
  />
);
export default Search;

export const getIdentityProperty =
  (type: string) => (option: any, value?: 'avatarUrl' | 'buName') => {
    if (isIdentity(option)) {
      switch (value) {
        case 'avatarUrl':
          return option.profile?.avatar || '';
        case 'buName':
          return option.businessUnit?.buName || '';
        default:
          return getFullName(option);
      }
    }
    return `Use default Identity type or implement "${type}"`;
  };

const isIdentity = (option: any): option is Identity =>
  (option as Identity).firstName !== undefined;
