import React from 'react';
import { styled } from '@mui/material';
import Button from '../Button';
import { Identity } from '../Header/interfaces';

const Container = styled('div', { name: 'PrismImpBanner-container' })({
  alignItems: 'center',
  background: '#000000',
  boxShadow:
    '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '20px 32px',
  position: 'sticky',
  top: 0,
  zIndex: 2,
});

const Message = styled('div', { name: 'PrismImpBanner-message' })({
  color: '#ffffff',
  fontSize: 14,
  fontWeight: 500,
});

const Name = styled('span', { name: 'PrismImpBanner-name' })({
  fontWeight: 600,
});

const ImpersonationBanner: React.FC<{
  identity: Identity;
  onStopImpersonation(): void;
}> = ({ identity, onStopImpersonation }) => (
  <Container>
    <Message>
      Impersonating:{' '}
      <Name>
        {identity.preferredName || identity.firstName} {identity.lastName}
      </Name>{' '}
      ({identity.email},{' '}
      {identity.customer?.name ? `${identity.customer.name}, ` : ''}{' '}
      {identity.identityUuid})
    </Message>
    <Button onClick={onStopImpersonation} color="secondary" variant="contained">
      Stop Impersonating
    </Button>
  </Container>
);

export default ImpersonationBanner;
