import React from 'react';
import { CheckboxProps } from '@mui/material/Checkbox';
import {
  BpCheckbox,
  BpCheckedIcon,
  BpIndeterminateIcon,
  BpIcon,
} from './styles';

function Checkbox(props: CheckboxProps) {
  return (
    <BpCheckbox
      color="default"
      checkedIcon={<BpCheckedIcon />}
      indeterminateIcon={<BpIndeterminateIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default Checkbox;
