import React from 'react';
import { styled } from '@mui/material';
import { keyframes } from '@mui/system';
import { Check, Checkbox } from '@octanner/prism-icons';

import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';

const pulseInOut = keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    },
    100% {
      opacity: 0;
    }
  `;

const Group = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

const CheckWrap = styled('span')({
  alignItems: 'center',
  display: 'flex',
  marginLeft: -8,
  marginRight: 8,
});

const BaseButton = styled('button')({
  alignItems: 'center',
  backgroundColor: '#fff',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: tannerGray['500'],
  borderRadius: 3,
  display: 'flex',
  justifyContent: 'center',
  fontSize: 14,
  outline: 'none',
  overflow: 'visible',
  padding: '8px 16px',
  position: 'relative',
  transition: 'all 200ms',
  '&:hover, &:focus': {
    borderColor: tannerGray['900'],
    boxShadow: `0px 0px 0px 2px ${tannerGray['900']}33`,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 3,
    opacity: 0,
    boxShadow: `0px 0px 0px 4px ${tannerGray['900']}33`,
  },
  '&:not(:first-of-type)': {
    marginLeft: 8,
  },
  '&[aria-checked="true"]': {
    borderColor: tannerSemantic['info'].color,
    color: tannerSemantic['info'].color,
    '&::after': {
      boxShadow: `0px 0px 0px 4px ${tannerSemantic['info'].color}33`,
    },
    '&:hover, &:focus': {
      boxShadow: `0px 0px 0px 2px ${tannerSemantic['info'].color}33`,
    },
  },
  '&[disabled]': {
    color: tannerGray['400'],
    backgroundColor: tannerGray['50'],
    borderColor: tannerGray['400'],
    boxShadow: 'none',
  },
});

const SelectionButton: React.FC<
  React.ComponentProps<typeof BaseButton> & {
    isCheckButton?: boolean;
    ref?:
      | ((instance: HTMLButtonElement | null) => void)
      | React.RefObject<HTMLButtonElement>
      | null;
    value: string | number;
  }
> = React.forwardRef(({ children, isCheckButton, ...props }, ref) => {
  return (
    <BaseButton
      {...props}
      sx={{
        '&:focus::after': {
          animation: `${pulseInOut} 1000ms ease-in infinite`,
        },
        ...props.sx,
      }}
      ref={ref}
    >
      {isCheckButton && (
        <CheckWrap>
          {props['aria-checked'] ? <Check /> : <Checkbox />}
        </CheckWrap>
      )}
      {children}
    </BaseButton>
  );
});

const SingleSelectionButtons: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    onChange?(value: string | number): void;
    value?: string | number;
  }
> = ({ children, onChange, value, ...props }) => {
  const childArray = React.Children.toArray(children);
  const [focus, setFocus] = React.useState(false);
  const [selected, setSelected] = React.useState<string | number>(value || '');

  const toggleItem = (item: string | number) => {
    const isAlreadySelected = value === item;
    const updatedValue = isAlreadySelected ? value : item;
    setSelected(item);
    onChange?.(updatedValue);
  };

  const switchRadioKey = (e: React.KeyboardEvent, index: number) => {
    let currIndex = index;
    let newItem: React.ReactNode | null = null;

    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        while (!React.isValidElement(newItem) || newItem.props.disabled) {
          if (currIndex === 0) {
            currIndex = childArray.length - 1;
          } else {
            currIndex = currIndex - 1;
          }
          newItem = childArray[currIndex];
        }
        break;
      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        while (!React.isValidElement(newItem) || newItem.props.disabled) {
          if (currIndex === childArray.length - 1) {
            currIndex = 0;
          } else {
            currIndex = currIndex + 1;
          }
          newItem = childArray[currIndex];
        }
        break;
      default:
        return;
    }

    e.preventDefault();
    setFocus(true);
    setSelected(newItem.props.value);
  };

  return (
    <Group role="radiogroup" {...props}>
      {childArray.map((child, index) => {
        if (React.isValidElement(child)) {
          const isSelected = selected === child.props.value || false;
          return React.cloneElement(child, {
            // TODO figure out a way to remove this ts-ignore
            // @ts-ignore this is a button and does have 'aria-checked' available
            'aria-checked': isSelected,
            onClick: () => toggleItem(child.props.value),
            onKeyDown: (e: React.KeyboardEvent) => switchRadioKey(e, index),
            ref: (button?: HTMLButtonElement) => {
              if (isSelected && focus) {
                setFocus(false);
                button?.focus();
              }
            },
            role: 'radio',
            tabIndex: isSelected ? 0 : -1,
          });
        }
        return child;
      })}
    </Group>
  );
};

type MultiSelectionValue = (string | number)[];

const MultiSelectionButtons: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    onChange?(value: MultiSelectionValue): void;
    value?: MultiSelectionValue;
  }
> = ({ children, onChange, value, ...props }) => {
  const childArray = React.Children.toArray(children);

  const toggleItem = (item: string | number) => {
    const isAlreadySelected = value?.some((selected) => selected === item);
    const updatedValue = isAlreadySelected
      ? (value || []).filter((selected) => selected !== item)
      : [...(value || []), item];
    onChange?.(updatedValue);
  };

  const checkKey = (e: React.KeyboardEvent, value: string | number) => {
    if (e.key === 'Space') {
      e.preventDefault();
      toggleItem(value);
    }
  };

  return (
    <Group {...props}>
      {childArray.map((child) => {
        if (React.isValidElement(child)) {
          const isSelected =
            value?.some((item) => item === child.props.value) || false;
          return React.cloneElement(child, {
            // TODO figure out a way to remove this ts-ignore
            // @ts-ignore this is a button and does have 'aria-checked' available
            'aria-checked': isSelected,
            isCheckButton: true,
            onClick: () => toggleItem(child.props.value),
            onKeyDown: (e: React.KeyboardEvent) =>
              checkKey(e, child.props.value),
            role: 'checkbox',
          });
        }
        return child;
      })}
    </Group>
  );
};

export { SelectionButton, SingleSelectionButtons, MultiSelectionButtons };
