import { styled } from '@mui/system';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import { tannerSemantic } from '../ThemeProvider/colors';

export const FormControlLabel = styled(MuiFormControlLabel)(({ disabled }) => ({
  maxWidth: 'fit-content',
  columnGap: '2px',
  '&:hover .MuiButtonBase-root': {
    backgroundColor: disabled
      ? 'transparent'
      : `${tannerSemantic.info.background}4d`,
    transition: disabled ? 'none' : 'background-color 300ms ease-out',
  },
  '&:not(:hover)': {
    ' .MuiButtonBase-root': {
      transition: disabled ? 'none' : 'background-color 300ms ease-out',
    },
  },
  '&:focus-visible': {
    outlineOffset: '3px',
    outline: `3px solid  ${tannerSemantic.info.color}`,
    borderRadius: '3px',
    ' .MuiButtonBase-root': {
      backgroundColor: disabled
        ? 'transparent'
        : `${tannerSemantic.info.background}4d`,
      transition: disabled ? 'none' : 'background-color 300ms ease-out',
    },
  },
  '.MuiTypography-root': {
    fontWeight: '400',
  },
}));
