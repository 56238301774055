import React from 'react';
import MuiMenuItem, {
  MenuItemProps,
  MenuItemTypeMap,
} from '@mui/material/MenuItem';
import {
  tannerBlue,
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '../ThemeProvider/colors';
export * from '@mui/material/MenuItem';

// NOTE: this component is not designed to be exported out of this library.
// prism-core use only
export default function MenuItemBase<
  D extends React.ElementType = MenuItemTypeMap['defaultComponent'],
  P = {},
>(props: MenuItemProps<D, P>) {
  return (
    <MuiMenuItem
      {...props}
      sx={{
        ...props.sx,
        borderRadius: '5px',
        border: '3px solid transparent',
        paddingLeft: '6px',
        minWidth: '184px',
        '&.Mui-selected': {
          ...props.sx?.['&.Mui-selected'],
          backgroundColor: tannerGray['50'],
          '&:hover': {
            backgroundColor: tannerGray['100'],
          },
          '&:focus-visible': {
            backgroundColor: tannerSemantic['info']['background'],
            border: `2px solid ${tannerWhite}`,
            outline: `2px solid ${tannerBlue['500']}`,
          },
        },
        '&:hover': { backgroundColor: tannerGray['100'] },
        '&:focus-visible': {
          backgroundColor: tannerGray['50'],
          border: `3px solid ${tannerWhite}`,
          outline: `2px solid ${tannerBlue['500']}`,
        },
        '&.MuiMenuItem-root': {
          padding: '5.5px 8px',
        },
      }}
    >
      {props.children}
    </MuiMenuItem>
  );
}
