import React from 'react';
import Avatar from '../Avatar';
import {
  AvatarLockupContainer,
  AvatarInfo,
  UserPronounsTypography,
  UserNameLink,
  InformationTypography,
  UserPronounsSpan,
  LineOfTextContainer,
} from './styles';

import { AvatarLockupProps } from './types';

// AvatarLockup functional component
const AvatarLockup: React.FC<AvatarLockupProps> = ({
  name,
  pronouns,
  information,
  align = 'left', // Default alignment is 'left'
  emphasized = true, // Default emphasis is true
  src,
  alt,
  size,
  lineOfText = false, // Default lineofText is false
  style,
  key,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
}) => {
  return (
    <>
      {lineOfText ? (
        <LineOfTextContainer
          style={style}
          key={key}
          data-testid={dataTestId}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
        >
          {name && (
            <UserNameLink
              lineOfText={lineOfText}
              emphasized={emphasized}
              href={src}
              linkVariant="name"
              context="quiet"
            >
              {' '}
              {name}{' '}
            </UserNameLink>
          )}
          {pronouns && (
            <UserPronounsSpan> ({pronouns.toLowerCase()})</UserPronounsSpan>
          )}
        </LineOfTextContainer>
      ) : (
        <AvatarLockupContainer
          style={style}
          key={key}
          data-testid={dataTestId}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          aria-describedby={ariaDescribedBy}
        >
          {align === 'left' && (src || alt) && (
            <Avatar tabIndex={0} src={src} alt={alt} size={size} />
          )}
          {(name || pronouns || information) && (
            <AvatarInfo align={align}>
              <div>
                {name && (
                  <UserNameLink
                    lineOfText={lineOfText}
                    linkVariant="name"
                    context="quiet"
                    emphasized={emphasized}
                    href={src}
                  >
                    {name}
                  </UserNameLink>
                )}
                {pronouns && (
                  <UserPronounsTypography>
                    {' '}
                    ({pronouns.toLowerCase()})
                  </UserPronounsTypography>
                )}
              </div>
              {information && (
                <InformationTypography variant="body2">
                  {information}
                </InformationTypography>
              )}
            </AvatarInfo>
          )}
          {align === 'right' && (src || alt) && (
            <Avatar tabIndex={0} src={src} alt={alt} size={size} />
          )}
        </AvatarLockupContainer>
      )}
    </>
  );
};

export default AvatarLockup;
