import React from 'react';
import ColorBox from './ColorBox';
import { RootBox, TitleTypography, GridBox } from './styles';
import { tannerGray, tannerBlack, tannerWhite } from './colors';
import { ColorBoxLabelProps } from '@octanner/prism-core/ThemeProvider/types';

const TannerGraysColorsComponent: React.FC<ColorBoxLabelProps> = () => {
  const mapColorEntries = (
    colorName: string,
    colorObject: Record<string, string>,
  ) => {
    return Object?.keys(colorObject)?.map((key) => ({
      label: `${colorName}/${key}`,
      color: colorObject[key],
    }));
  };

  const tannerGrayColors = [
    ...mapColorEntries('TannerGray', tannerGray),
    { label: 'TannerBlack', color: tannerBlack },
    { label: 'TannerWhite', color: tannerWhite },
  ];

  return (
    <RootBox>
      <TitleTypography variant="h3">Grays</TitleTypography>
      <GridBox>
        {tannerGrayColors?.map(({ color, label }) => (
          <ColorBox key={color} color={color} label={label} />
        ))}
      </GridBox>
    </RootBox>
  );
};

export default TannerGraysColorsComponent;
