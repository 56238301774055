import { labels, baseColors } from './styles';
/**
 * Converts a decimal number to a hexadecimal string.
 * @param {number} decimal - The decimal number.
 * @returns {string} The hexadecimal string.
 */
const decimalToHexadecimal = (decimal: number): string =>
  decimal?.toString(16)?.padStart(2, '0');

/**
 * Converts a hexadecimal string to a decimal number.
 * @param {string} hexadecimal - The hexadecimal string.
 * @returns {number} The decimal number.
 */
const hexadecimalToDecimal = (hexadecimal: string): number =>
  parseInt(hexadecimal, 16);

/**
 * Mixes two colors.
 * @param {string} color1 - The first color in hexadecimal format.
 * @param {string} color2 - The second color in hexadecimal format.
 * @param {number} weight - The weight of the first color in the mix.
 * @returns {string} The resulting color in hexadecimal format.
 */
const mixColors = (color1: string, color2: string, weight: number): string => {
  let mixedColor = '#';
  for (let i = 1; i <= 6; i += 2) {
    const color1Part = hexadecimalToDecimal(color1?.substr(i, 2));
    const color2Part = hexadecimalToDecimal(color2?.substr(i, 2));
    const mixedPart = decimalToHexadecimal(
      Math.round(color2Part + (color1Part - color2Part) * (weight / 100.0)),
    );
    mixedColor += mixedPart;
  }
  return mixedColor;
};

/**
 * Generates client colors based on a base color.
 * @param {string} baseColor - The base color in hexadecimal format.
 * @returns {Array} The generated client colors.
 */
const generateClientColors = (baseColor: string) => {
  const opacities = [8, 15, 30, 45, 60, 85, 100, 85, 70, 55, 40];

  return opacities.map((opacity, index) => ({
    label: labels[index],
    color: mixColors(baseColor, baseColors[index], opacity),
  }));
};

export default generateClientColors;
