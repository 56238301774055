import React, { useRef, useState } from 'react';
import { PopoverProps } from './types';
import { PopoverContainer, PopoverWrapper } from './styles';

const Popover: React.FC<PopoverProps> = ({
  id,
  onKeyDown,
  role,
  disableRestoreFocus,
  popoverComponent,
  anchorOrigin,
  transformOrigin,
  popoverWrapper,
  style,
  classes,
  key,
  sx,
  className,
  'data-testid': dataTestId,
  transitionDuration,
  elevation,
  action,
  anchorReference,
  anchorPosition,
  container,
  disableScrollLock,
  marginThreshold,
  onClose,
  slotProps = {},
  slots,
  anchorEl,
  TransitionComponent,
  TransitionProps,
  open,
  children,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedBy,
}) => {
  const [customAnchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [paperOpen, setPaperOpen] = useState(false);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const popoverAnchor = useRef(null);
  const mergedSlotProps = {
    ...slotProps,
    paper: {
      onPointerEnter: () => setPaperOpen(true),
      onPointerLeave: () => setPaperOpen(false),
      ...slotProps.paper, // Merge any existing props with the mandatory pointer events
    },
  };
  return (
    <>
      <PopoverWrapper
        ref={popoverAnchor}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {popoverWrapper}
      </PopoverWrapper>
      <PopoverContainer
        id={id}
        onKeyDown={onKeyDown}
        role={role}
        disableRestoreFocus={disableRestoreFocus}
        open={open ? open : Boolean(customAnchorEl) || paperOpen}
        anchorEl={anchorEl ? anchorEl : popoverAnchor.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={mergedSlotProps}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        container={container}
        disableScrollLock={disableScrollLock}
        elevation={elevation}
        marginThreshold={marginThreshold}
        onClose={onClose}
        slots={slots}
        TransitionComponent={TransitionComponent}
        TransitionProps={TransitionProps}
        transitionDuration={transitionDuration}
        classes={classes}
        sx={sx}
        key={key}
        style={style}
        className={className}
        action={action}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-describedby={ariaDescribedBy}
      >
        {popoverComponent ? popoverComponent : children}
      </PopoverContainer>
    </>
  );
};

export default Popover;
