import {
  darken,
  alpha,
  getContrastRatio,
  PaletteColor,
} from '@mui/material/styles';
import { Theme } from '../Header/interfaces';

const WCAG_AA_CONTRAST_RATIO = 4.5;

const makePalette = (theme?: Theme) => {
  let primaryColor = theme?.primaryColor?.value;

  if (primaryColor) {
    while (getContrastRatio(primaryColor, '#fff') < WCAG_AA_CONTRAST_RATIO) {
      primaryColor = darken(primaryColor, 0.1);
    }
  }

  const palette = {
    primary: {
      ...(primaryColor && { main: primaryColor }),
    },
  };

  return { palette };
};

const colorDisabled = (color: PaletteColor) => {
  try {
    return {
      color: alpha(color.contrastText, 0.75),
      backgroundColor: alpha(color.main, 0.6),
    };
  } catch (e) {
    console.error((e as Error).stack);
  }
};

export { makePalette, colorDisabled };
