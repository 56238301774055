import {
  createPlateEditor,
  HeadingToolbar,
  Plate,
  PlateProvider,
  ToolbarProps,
  deserializeHtml,
  serializeHtml,
} from '@udecode/plate';
import { BasicElementToolbarButtons } from './BasicElementToolbarButtons';
import { Box, Colors, Tooltip } from '@octanner/prism-core';
import plugins from './Plugins';
import { Types } from './Types';
import { useState } from 'react';
import React, { KeyboardEvent } from 'react';
import { MyValue } from './typescript/plateTypes';
import { styled } from '@mui/material';
import { Lock } from '@octanner/prism-icons';

export const Toolbar = (props: ToolbarProps) => <HeadingToolbar {...props} />;

const CustomToolbar = styled(Toolbar)`
  && {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: ${Colors.tannerGray[50]};
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: ${Colors.tannerGray[400]};
    border-radius: 3px 3px 0px 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px;
  }
`;

const editor = createPlateEditor({ plugins });

export function generatePlateHtml(value: MyValue) {
  editor.children = value as
    | MyValue
    | { type: 'p'; children: [{ text: '' }] } as MyValue;
  const html = serializeHtml(editor, {
    nodes: editor?.children,
  });

  let newHtml = html;
  const reg = /<div\b[^>]*>(.*?)<\/div>/g;
  const matches = html.match(reg);
  const elements = ['slate-h1', 'slate-h2', 'slate-h3', 'slate-h4', 'slate-p', 'slate-lic'];
  matches &&
    matches.forEach((val, idx, arr) => {
      elements.forEach((ele) => {
        if (val.includes(ele)) {
          ele = ele.slice(ele.indexOf('-')+1)
          ele = ele === 'lic' ? 'p' : ele;
          let newval = val.replace('<div', '<' + ele);
          newval = newval.replace('</div>', '</' + ele + '>');
          newHtml = newHtml.replace(val, newval);
        }
      });
    });
  return newHtml;
}

const TextEditor = (props: Types) => {
  const { iconVisible = true } = props;
  const editor = createPlateEditor({ plugins });
  const plateValue =
    props.valueFormat === 'HTML' && typeof props.value === 'string'
      ? deserializeHtml(editor, { element: props.value })
      : props.value;
  const [value, setValue] = useState<MyValue>(plateValue as MyValue);
  const toolbar =
    typeof props.enableToolbar === 'boolean' ? props.enableToolbar : true;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (props.readOnly) {
      event.preventDefault();
    }
  };

  const textEditorStyles = {
    boxSizing: 'border-box',
    padding: '10px 16px',
    background: props.disableEditor
      ? Colors.tannerGray[50]
      : Colors.tannerWhite,
    border: `1px solid ${Colors.tannerGray[300]}`,
    borderRadius: '0px 0px 3px 3px',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 1,
    gap: '8px',
    zIndex: 0,
    overflow: 'auto',
    '*::marker': {
      fontSize: '14px',
      color: `${Colors.tannerGray[900]}`,
    },
    pointerEvents: 'auto',
    '&.MuiBox-root': {
      height: props.readOnly ? '300px' : 'auto',
      padding: '16px',
    },
  };

  const handleChange = (value: MyValue) => {
    setValue(value);
    if (typeof props.onUpdate === 'function') {
      props.valueFormat === 'HTML'
        ? props.onUpdate(generatePlateHtml(value))
        : props.onUpdate(JSON.stringify(value));
    }
  };

  return (
    <Box
      sx={{
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {
        <PlateProvider
          id={props.id}
          plugins={plugins}
          value={value as MyValue}
          onChange={handleChange}
        >
          {!props.readOnly && toolbar && (
            <CustomToolbar
              sx={{
                pointerEvents: 'auto',
              }}
            >
              <BasicElementToolbarButtons
                id={props.id}
                readOnly={props.readOnly}
              />
            </CustomToolbar>
          )}
          <Box sx={textEditorStyles}>
            {iconVisible && props.readOnly && (
              <div style={{ position: 'relative' }}>
                <Tooltip title="This field cannot be edited">
                  <Lock
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      color: `${Colors.tannerGray[400]}`,
                      pointerEvents: 'auto',
                    }}
                    viewBox="0 0 20 20"
                  />
                </Tooltip>
              </div>
            )}
            {/* @ts-ignore plate types issue, props isnt allowed although is possible.*/}
            <Plate<MyValue>
              id={props.id}
              editableProps={{
                autoFocus: props.autoFocus,
                contentEditable: !props.readOnly,
                spellCheck: false,
                readOnly: props.readOnly,
                onKeyDown: handleKeyDown,
                style: {
                  pointerEvents: props.readOnly ? 'none' : 'auto',
                  opacity: props.disableEditor ? 0.4 : 1,
                  height: '223px',
                  paddingRight: props.readOnly ? '20px' : 'none',
                  marginRight: props.readOnly ? '8px' : 'none',
                },
              }}
            />
          </Box>
        </PlateProvider>
      }
    </Box>
  );
};

export default TextEditor;

TextEditor.defaultProps = {
  disableEditor: false,
  autoFocus: false,
};
