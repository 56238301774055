import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  JSXElementConstructor,
  HTMLAttributes,
} from 'react';
import { ListSubheader } from '@mui/material';
import {
  Button,
  Checkbox,
  Chip,
  Autocomplete,
  Typography,
  ListItemText,
  MenuItem,
} from '..';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteWrapper,
  ListBoxWrapper,
  ActionContainer,
  DividerContainer,
  RecordsCountContainer,
  StyledPopper,
  RenderInputWrapper,
  RecordsCountListSubheader,
  StyledTextField,
} from './styles';
import { Props } from '../Autocomplete/AutocompleteTypes';
import {
  ListboxComponentProps,
  ListboxContextType,
  OptionType,
  OptionElement,
} from './types';

export const ListboxContext = createContext<ListboxContextType>({
  options: [],
  value: [],
  setValue: () => {},
  renderedOptions: [],
  setRenderedOptions: () => {},
});

const ListboxComponent = React.forwardRef<
  HTMLUListElement,
  ListboxComponentProps
>(function ListboxComponent(
  {
    children,
    className,
    role,
    id,
    'aria-labelledby': ariaLabelledBy,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
  },
  ref,
) {
  const context = useContext(ListboxContext);
  const { setValue, value, setRenderedOptions, options } = context;

  const renderedOptions = React.Children.toArray(children)
    .filter(
      (child): child is OptionElement =>
        React.isValidElement(child) && child.props.optionData.id !== undefined,
    )
    .map((child) => child.props.optionData);

  const previousRenderedOptionsRef = React.useRef<OptionType[]>([]);

  if (
    JSON.stringify(renderedOptions) !==
    JSON.stringify(previousRenderedOptionsRef.current)
  ) {
    setRenderedOptions(renderedOptions);
    previousRenderedOptionsRef.current = renderedOptions;
  }

  const handleSelectAll = () => {
    setValue((prevValue) => {
      const prevIdsSet = new Set(prevValue.map((option) => option.id));
      const newOptions = renderedOptions.filter(
        (option) => !prevIdsSet.has(option.id),
      );
      return [...prevValue, ...newOptions];
    });
  };

  const handleClearAll = () => {
    const renderedOptionIds = new Set(
      renderedOptions.map((option) => option.id),
    );
    setValue((prevValue) =>
      prevValue.filter((option) => !renderedOptionIds.has(option.id)),
    );
  };

  const { t } = useTranslation();
  return (
    <ListBoxWrapper>
      <ul
        className={className}
        role={role}
        id={id}
        aria-labelledby={ariaLabelledBy}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <ListSubheader>
          <ActionContainer>
            <Button
              onClick={handleSelectAll}
              variant="text"
              data-testid="select-all-button"
            >
              {t('prism-header:select-all', 'Select all')}
            </Button>
            {value?.length > 0 && (
              <Button onClick={handleClearAll} variant="text">
                {t('prism-header:clear-selections', 'Clear selections')}
              </Button>
            )}
          </ActionContainer>
        </ListSubheader>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return child;
          }
          return null;
        })}
      </ul>
      <DividerContainer />
      <RecordsCountListSubheader>
        <RecordsCountContainer>
          <Typography variant="body2">{`${value.length} of ${options.length}`}</Typography>
        </RecordsCountContainer>
      </RecordsCountListSubheader>
    </ListBoxWrapper>
  );
});

const MultiSelectAutocomplete: React.FC<Props> = ({
  value,
  setValue,
  options,
  label,
  disabled,
  inputValue,
  onInputChange,
  error,
  helperText,
  labelId,
}) => {
  const [renderedOptions, setRenderedOptions] = useState<OptionType[]>([]);
  const renderInputWrapperRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (renderInputWrapperRef.current) {
      renderInputWrapperRef.current.scrollTop =
        renderInputWrapperRef.current.scrollHeight;
    }
  }, [inputValue]);

  return (
    <ListboxContext.Provider
      value={{
        options,
        value,
        setValue: setValue || (() => {}),
        renderedOptions,
        setRenderedOptions,
      }}
    >
      <AutocompleteWrapper>
        <Autocomplete
          data-testid="multi-select-autocomplete"
          multiple
          id="multi-select-autocomplete"
          options={options}
          ListboxComponent={
            ListboxComponent as JSXElementConstructor<
              HTMLAttributes<HTMLElement>
            >
          }
          value={value}
          onChange={(event, newValue) => {
            if (setValue) setValue(newValue as OptionType[]);
          }}
          onInputChange={onInputChange}
          inputValue={inputValue}
          PopperComponent={StyledPopper}
          disableCloseOnSelect
          getOptionLabel={(option) => option[labelId || 'label']}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            // @ts-ignore - optionData is not a valid prop for MenuItem but no idea what it should do
            <MenuItem {...props} value={option.id} optionData={option}>
              <Checkbox checked={selected} />
              <ListItemText primary={option[labelId || 'label']} />
            </MenuItem>
          )}
          renderTags={(value, getTagProps) => (
            <RenderInputWrapper ref={renderInputWrapperRef}>
              {value.map((option, index) => (
                <Chip
                  size="small"
                  label={option[labelId || 'label']}
                  {...getTagProps({ index })}
                />
              ))}
            </RenderInputWrapper>
          )}
          renderInput={(params) => (
            <StyledTextField
              label={label}
              error={error}
              helperText={helperText}
              {...params}
            />
          )}
          disabled={disabled}
        />
      </AutocompleteWrapper>
    </ListboxContext.Provider>
  );
};

export default MultiSelectAutocomplete;
