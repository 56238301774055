import React from 'react';
import { Box, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';

const jumpingDots = keyframes`
    25% {
      transform: translateY(2px);
    }
    75% {
      transform: translateY(-2px);
    }
  `;

interface Props {
  type?: 'button' | 'dots';
  color?: 'primary' | 'secondary' | 'blue';
  themeColor?: string;
}

const DotsLoader: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  color = 'primary',
  type = 'dots',
}) => {
  const theme = useTheme();
  const { primary, secondary } = theme.palette;
  const themeColor =
    color === 'blue'
      ? '#0060E0'
      : color === 'primary'
      ? primary.main
      : secondary.main;
  return (
    <Box
      sx={{
        '> span': {
          width: 6,
          height: 6,
          margin: '0 2px',
          borderRadius: '50%',
          display: 'inline-block',
          backgroundColor: type === 'dots' ? themeColor : 'currentColor',
          animation: `${jumpingDots} 600ms infinite linear `,
          '&:nth-of-type(3n)': {
            animationDelay: '-300ms',
          },
          '&:nth-of-type(2n)': {
            animationDelay: '-150ms',
          },
        },
      }}
    >
      <span />
      <span />
      <span />
    </Box>
  );
};

export default DotsLoader;
