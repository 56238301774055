import React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled, SxProps } from '@mui/system';

type StyledAccordionProps = AccordionProps & {
  defaultExpanded?: boolean;
  disableGutters?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  sx?: SxProps;
  style?: React.CSSProperties;
  key?: string | number | null;
  'data-testid'?: string;
};

const StyledAccordion = styled(MuiAccordion)<StyledAccordionProps>({
  borderTop: `1px solid #E1E1E1`,
  minHeight: `56px`,
  padding: `0px`,
  boxShadow: `none`,
  borderBottom: `1px solid #E1E1E1`,
  '&:not(:last-child)': {
    borderBottom: '8px',
  },
  '&:first-of-type': {
    borderTop: `1px solid #E1E1E1`,
    borderTopLeftRadius: `0px`,
    borderTopRightRadius: `0px`,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: `0px`,
    borderBottomRightRadius: `0px`,
  },
  '&::before': {
    display: `none`,
  },
});

const Accordion: React.FC<StyledAccordionProps> = ({
  defaultExpanded = false,
  disableGutters = true,
  expanded,
  square = false,
  disabled,
  onChange,
  children,
  classes,
  sx = {},
  style,
  key,
  'data-testid': testid,
}) => {
  return (
    <StyledAccordion
      elevation={0}
      square={square}
      defaultExpanded={defaultExpanded}
      disableGutters={disableGutters}
      expanded={expanded}
      disabled={disabled}
      onChange={onChange}
      children={children}
      classes={classes}
      sx={sx}
      style={style}
      key={key}
      data-testid={testid}
    />
  );
};

export default Accordion;
export * from '@mui/material/Accordion';
