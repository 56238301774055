import styled from '@emotion/styled';

export const Container = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  overflow: 'hidden',
});

export const IdentityContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
});

export const NameWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 8,
  overflow: 'hidden',
  width: '100%',
});

export const Text = styled.div<{
  color?: string;
  secondary?: boolean;
}>(({ color, secondary }) => ({
  color: color,
  fontSize: secondary ? 12 : 14,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
