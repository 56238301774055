import React from 'react';
import { Button } from '..';
import {
  EmptyStateWrapper,
  ContentWrapper,
  Header,
  Caption,
  ButtonWrapper,
  SecondaryAction,
} from './styles';
import { EmptyStateProps } from './types';

const EmptyState: React.FC<EmptyStateProps> = ({
  header,
  caption,
  submitAction,
  secondaryAction,
}) => {
  return (
    <EmptyStateWrapper>
      <ContentWrapper>
        {header && <Header variant="h3">{header}</Header>}
        {caption && <Caption variant="body1">{caption}</Caption>}
        {(submitAction?.onClick || secondaryAction?.onClick) && (
          <ButtonWrapper>
            {submitAction?.onClick && (
              <Button color="primary" onClick={submitAction?.onClick}>
                {submitAction?.name || 'Submit'}
              </Button>
            )}
            {secondaryAction?.onClick && (
              <SecondaryAction hasSubmitAction={!!submitAction?.onClick}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={secondaryAction?.onClick}
                  size="small"
                >
                  {secondaryAction?.name || 'Cancel'}
                </Button>
              </SecondaryAction>
            )}
          </ButtonWrapper>
        )}
      </ContentWrapper>
    </EmptyStateWrapper>
  );
};

export default EmptyState;
