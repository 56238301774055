import React from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { ArrowDown, Close } from '@octanner/prism-icons';
import TextField from '../TextField';
import Chip from '../Chip';
import { Props } from './AutocompleteTypes';
import MultiSelectAutocomplete from '../AutoCompleteMultiSelectVariant';

const StyledAutocomplete = styled(MuiAutocomplete)<Props>(
  {
    '& .MuiFilledInput-root': {
      '& .MuiFilledInput-input': {
        paddingLeft: '8px',
      },
      '& .MuiAutocomplete-clearIndicator': {
        visibility: 'visible',
      },
    },
  },
  {
    '& + .MuiAutocomplete-popper': {
      paddingTop: `4px`,
      '& .MuiFilledInput-input': {
        paddingLeft: '8px',
      },
      '& .MuiAutocomplete-paper': {
        fontSize: '14px',
      },
      '.MuiAutocomplete-option': {
        fontSize: '14px',
      },
      '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
          borderBottom: '0px',
        },
      },
      '.MuiAutocomplete-groupUl': {
        borderRadius: '0px',
        padding: '0px 0px 8px 0px',
      },
      '.MuiListSubheader-root': {
        fontSize: '12px',
        maxHeight: '32px',
        lineHeight: '32px',
      },
      '.MuiAutocomplete-groupLabel': {
        paddingLeft: '16px',
      },
      "& .MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: '#DEEBFC',
        color: '#0060E0',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .Mui-focused': {
          backgroundColor: '#DEEBFC',
        },
      },
      '.MuiAutocomplete-listbox > li': {
        borderBottom: '1px solid #C4C4C4',
        '&:last-child': {
          paddingTop: '8px',
          borderBottom: '0px',
        },
      },
      '.MuiAutocomplete-listbox > li:last-child > ul': {
        '&:last-child': {
          padding: `0px`,
        },
      },
      '.MuiAutocomplete-listbox': {
        padding: '8px',
      },
      ".MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
        backgroundColor: '#DEEBFC',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
);

const Autocomplete: React.FC<Props> = (props) => {
  const {
    multiple = false,
    options,
    label,
    groupBy,
    getOptionLabel,
    renderOption,
    sx,
    onInputChange,
    inputValue,
    onChange,
    classes,
    className = '',
    style,
    defaultValue,
    loading,
    getOptionSelected,
    required,
    error,
    helperText,
    id,
    noOptionsText,
    open,
    onOpen,
    onClose,
    fullWidth,
    renderInput,
    renderTags = (value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          size={'small'}
          label={getChipOptionLabel(option)}
          {...getTagProps({ index })}
        />
      )),
    value,
    disabled,
    limitTags,
    isOptionEqualToValue,
    autoHighlight,
    dataTestId,
    ListboxProps,
    disableCloseOnSelect,
    disableClearable,
    filterOptions,
    filterSelectedOptions,
    getChipOptionLabel = (option) => option.label || '',
    disablePortal = true,
    freeSolo = false,
    clearOnBlur = false,
    ListboxComponent,
    PopperComponent,
    isMultiVariant = false,
    setValue,
    labelId = 'label',
    PaperComponent,
  } = props;

  if (isMultiVariant) {
    return (
      <MultiSelectAutocomplete
        value={value}
        setValue={setValue}
        options={options}
        label={label}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={onInputChange}
        error={error}
        helperText={helperText}
        labelId={labelId}
      />
    );
  }

  return (
    <StyledAutocomplete
      componentsProps={{
        clearIndicator: {
          tabIndex: 0,
        },
        popupIndicator: { tabIndex: 0 },
      }}
      limitTags={limitTags}
      className={className}
      id={id}
      clearIcon={<Close />}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      renderInput={
        renderInput
          ? renderInput
          : (params) => (
              <TextField
                required={required}
                helperText={helperText}
                error={error}
                label={label}
                {...params}
              />
            )
      }
      options={options}
      groupBy={groupBy}
      disablePortal={disablePortal}
      popupIcon={<ArrowDown />}
      multiple={multiple}
      renderTags={renderTags}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      sx={sx}
      onInputChange={onInputChange}
      inputValue={inputValue}
      onChange={onChange}
      classes={classes}
      style={style}
      defaultValue={defaultValue}
      loading={loading}
      getOptionSelected={getOptionSelected}
      required={required}
      noOptionsText={noOptionsText}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      value={value}
      PaperComponent={PaperComponent}
      disabled={disabled}
      isOptionEqualToValue={isOptionEqualToValue}
      autoHighlight={autoHighlight}
      ListboxProps={ListboxProps}
      dataTestId={dataTestId}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      ListboxComponent={ListboxComponent}
      PopperComponent={PopperComponent}
    />
  );
};

export default Autocomplete;
export * from '@mui/material/Autocomplete';
