import React from 'react';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import styled from '@emotion/styled';

const StyledAccordionDetails = styled(MuiAccordionDetails)({
  padding: `0px 0px 12px 0px`,
});

const AccordionDetails: React.FC<AccordionDetailsProps> = (props) => {
  return (
    <StyledAccordionDetails {...props} />
  );
};

export default AccordionDetails;
export * from '@mui/material/AccordionDetails';
