import React from 'react';
import FormControl, { FormControlProps } from '../FormControl';
import InputLabel from '../InputLabel';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import {
  tannerBlack,
  tannerGray,
  tannerSemantic,
} from '../ThemeProvider/colors';
import { ArrowDown, Lock } from '@octanner/prism-icons';
import { FormHelperText } from '..';
import { HeightOption, getHeight } from './utils';
export type { SelectChangeEvent } from '@mui/material';

type CustomProps = {
  height?: HeightOption;
  helperText?: string;
  error?: boolean;
  formControlProps?: FormControlProps;
};

type Props = CustomProps & React.ComponentProps<typeof MuiSelect>;
const StyledSelect = styled(MuiSelect, { name: 'PrismSelect' })<Props>(
  ({ theme, label, height, inputProps = {} }) => {
    const actualHeight = getHeight(height, label);
    const { readOnly = false } = inputProps;
    const iconSize = readOnly ? 12 : 18;
    return {
      height: actualHeight === 'short' ? 40 : 56,
      lineHeight: 'unset',
      backgroundColor: '#fff',
      border: `1px solid ${tannerGray['500']}`,
      borderRadius: 3,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
        borderColor: tannerBlack,
      },
      '&:active': {
        borderColor: tannerGray['500'],
        backgroundColor: '#fff',
      },
      '&.Mui-focused': {
        backgroundColor: '#fff',
      },
      '& .MuiSelect-select': {
        ...(label
          ? {
              padding: `27px 28px 10px 16px`,
            }
          : {
              paddingTop: 0,
              paddingBottom: 0,
              alignItems: 'center',
              display: 'flex',
            }),
        ...(readOnly && {
          borderRadius: 3,
          backgroundColor: '#fff',
        }),
      },
      '& .MuiSelect-root': {
        borderRadius: 3,
        marginRight: 0,
        '&:focus-visible': {
          backgroundColor: '#fff',
        },
      },
      '& .MuiSelect-icon': {
        color: readOnly ? tannerGray['400'] : 'currentColor',
        fontSize: 12,
        top: 'calc(50% - 9px)',
        transition: 'transform 200ms',
        right: 12,
        marginRight: readOnly ? 4 : 2,
        width: iconSize,
        height: iconSize,
        marginTop: readOnly ? 3 : 0,
      },
      '&.Mui-disabled': {
        backgroundColor: tannerGray['50'],
        color: tannerGray['400'],
        '&:hover': {
          backgroundColor: tannerGray['50'],
        },
      },
      '&.Mui-error': {
        borderColor: tannerSemantic.error.color,
      },
      '&.MuiInputBase-root': {
        paddingRight: 0,
        '&.hover': {
          border: `1px solid ${tannerGray[readOnly ? 200 : 300]}`,
        },
      },
      ...(readOnly && {
        pointerEvents: 'none',
      }),
    };
  },
);

const Select: React.FC<Props> = ({
  children,
  disabled,
  fullWidth,
  helperText,
  error,
  id,
  label,
  formControlProps,
  inputProps,
  ...props
}) => (
  <FormControl
    variant="filled"
    disabled={disabled}
    fullWidth={fullWidth}
    error={error}
    {...formControlProps}
    sx={{
      minWidth: 176,
      ...formControlProps?.sx,
    }}
  >
    {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
    <StyledSelect
      IconComponent={inputProps?.readOnly ? Lock : ArrowDown}
      label={label}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { horizontal: 'left', vertical: 'top' },
      }}
      {...props}
      inputProps={{
        ...inputProps,
        id: id,
      }}
    >
      {React.Children.toArray(children).map((child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              // TODO figure out a way to remove this ts-ignore
              // @ts-ignore this is an option and does have variant prop available
              variant: !props.multiple ? 'check' : 'normal',
              'aria-label':
                props.value === child.props.value
                  ? `${child.props.children} selected`
                  : child.props.children,
            })
          : child,
      )}
    </StyledSelect>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

export default Select;
