import Typography from '../Typography';
import styled from '@emotion/styled';
import { Draggable } from '@octanner/prism-icons';
import {
  tannerBlack,
  tannerBlue,
  tannerGray,
  tannerWhite,
} from '../ThemeProvider/colors';

export const ListElement = styled.ul`
  list-style-type: none;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 0;
`;

export const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardItem = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDragging', 'isDisabled', 'isFocused', 'transform'].includes(prop),
})<{
  id: string;
  isDragging: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  transform?: string;
}>`
  display: flex;
  align-items: center;
  jsutify-content: space-between;
  width: 100%;
  touch-action: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'grab' : 'grab')};
  padding: 16px;
  align-items: center;
  gap: 24px;
  min-height: ${({ isDragging }) => (isDragging ? 'auto' : '56px')};
  outline: none;
  border-top: 1px solid ${tannerGray[200]};
  background-color: ${({ isDragging, isDisabled }) => {
    if (isDragging) {
      return tannerGray[50];
    }
    return isDisabled ? tannerGray[50] : tannerWhite;
  }};
  border-radius: ${({ isDragging }: { isDragging: boolean }) =>
    isDragging ? '4px' : '0px'};
  box-shadow: ${({ isDragging }: { isDragging: boolean }) =>
    isDragging
      ? '0px 1px 10px 0px #00000033, 0px 4px 5px 0px #0000001F, 0px 2px 4px 0px #00000024'
      : 'none'};
  &:first-child {
  }
  &:last-child {
    border-bottom: 1px solid ${tannerGray[200]};
  }
  &:hover {
    background-color: ${tannerGray[50]};
  }
  transform: ${({ transform }) => transform || 'none'};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const IconLabelDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const DraggableIcon = styled(Draggable, {
  shouldForwardProp: (prop) => !['isDisabled', 'isFocused'].includes(prop),
})<{ isDisabled?: boolean; isFocused?: boolean }>`
cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'grab')};
height: 24px;
width: 24px;
padding: 5px 6px 7px 6px;
box-sizing:border-box;
outline: none;
  path {
    fill: ${(props) => {
      const fillColor = props.isDisabled
        ? tannerGray[400]
        : props.isFocused
        ? tannerGray[400]
        : tannerGray[700];
      return fillColor;
    }};
  }
    :focus-visible {
    outline: 3px solid ${tannerBlue[500]};
    border-radius: 5px;
    path{
    fill: ${tannerGray[400]};
}
`;

export const TextStyle = styled(Typography, {
  shouldForwardProp: (prop) => !['isDisabled', 'isFocused'].includes(prop),
})<{ isDisabled?: boolean; isFocused?: boolean }>`
  margin-top: 2px;
  color: ${({ isDisabled }) => (isDisabled ? tannerGray[700] : tannerBlack)};
  variant: 'h1';
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const DivContainer = styled.div`
  visibiliy: hidden;
`;
