import React from 'react';
import Radio, { RadioProps as MUIRadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import {
  tannerGray,
  tannerSemantic,
  tannerWhite,
} from '../ThemeProvider/colors';

type RadioProps = Omit<MUIRadioProps, 'color'>;

const radioSize = 20;
const dotSize = 8;
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: radioSize,
  height: radioSize,
  border: `1px solid ${tannerGray[600]}`,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    backgroundColor: tannerGray[100],
    borderColor: tannerGray[300],
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: tannerSemantic.info.color,
  border: 'none',
  '&:before': {
    position: 'absolute',
    width: dotSize,
    height: dotSize,
    left: 'calc(50% - 8px/2)',
    top: 'calc(50% - 8px/2)',

    background: tannerWhite,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    content: '""',
  },
  'input:disabled ~ &': {
    backgroundColor: tannerSemantic.info.color,
    opacity: '0.3',
  },
});

const BpRadio = styled(Radio)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: `${tannerSemantic.info.background}46`,
  },
}));

const RadioButton = React.forwardRef<HTMLButtonElement, RadioProps>(
  (props, ref) => (
    <BpRadio
      ref={ref}
      color="primary"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  ),
);

export default RadioButton;
export * from '@mui/material/Radio';
