import { tannerSemantic } from './colors';
import { ColorBoxProps } from './types';

const INFORMATION_COLORS_GRID: ColorBoxProps[] = [
  { label: 'Semantic/info 25', color: tannerSemantic['info']['25'] },
  { label: 'Semantic/error 25', color: tannerSemantic['error']['25'] },
  { label: 'Semantic/warning 25', color: tannerSemantic['warning']['25'] },
  { label: 'Semantic/success 25', color: tannerSemantic['success']['25'] },

  { label: 'Semantic/info 100', color: tannerSemantic['info']['100'] },
  { label: 'Semantic/error 100', color: tannerSemantic['error']['100'] },
  { label: 'Semantic/warning 100', color: tannerSemantic['warning']['100'] },
  { label: 'Semantic/success 100', color: tannerSemantic['success']['100'] },

  { label: 'Semantic/info 200', color: tannerSemantic['info']['200'] },
  { label: 'Semantic/error 200', color: tannerSemantic['error']['200'] },
  { label: 'Semantic/warning 200', color: tannerSemantic['warning']['200'] },
  { label: 'Semantic/success 200', color: tannerSemantic['success']['200'] },

  { label: 'Semantic/info 300', color: tannerSemantic['info']['300'] },
  { label: 'Semantic/error 300', color: tannerSemantic['error']['300'] },
  { label: 'Semantic/warning 300', color: tannerSemantic['warning']['300'] },
  { label: 'Semantic/success 300', color: tannerSemantic['success']['300'] },

  { label: 'Semantic/info 500', color: tannerSemantic['info'].color },
  { label: 'Semantic/error 500', color: tannerSemantic['error'].color },
  { label: 'Semantic/warning 500', color: tannerSemantic['warning'].color },
  { label: 'Semantic/success 500', color: tannerSemantic['success'].color },

  { label: 'Semantic/info 600', color: tannerSemantic['info']['600'] },
  { label: 'Semantic/error 600', color: tannerSemantic['error']['600'] },
  { label: 'Semantic/warning 600', color: tannerSemantic['warning']['600'] },
  { label: 'Semantic/success 600', color: tannerSemantic['success']['600'] },

  { label: 'Semantic/info 700', color: tannerSemantic['info']['700'] },
  { label: 'Semantic/error 700', color: tannerSemantic['error']['700'] },
  { label: 'Semantic/warning 700', color: tannerSemantic['warning']['700'] },
  { label: 'Semantic/success 700', color: tannerSemantic['success']['700'] },
];

export default INFORMATION_COLORS_GRID;
