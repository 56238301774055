import React from 'react';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import styled from '@emotion/styled';
import Typography from '../Typography';
import { ArrowDown } from '@octanner/prism-icons';

type StyledAccordionSummaryProps = AccordionSummaryProps & {
  expandIcon?: React.ReactNode;
  helperText?: string;
};

const StyledAccordionSummary = styled(MuiAccordionSummary)({
  padding: `0px`,
  height: `56px`,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: `#000000`,
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const HelperTextWrapper = styled.span({
  paddingRight: '12px',
});

const AccordionSummary: React.FC<StyledAccordionSummaryProps> = ({
  expandIcon = <ArrowDown />,
  children,
  classes,
  helperText,
  sx,
}) => {
  return (
    <StyledAccordionSummary classes={classes} expandIcon={expandIcon} sx={sx}>
      {children}
      {helperText && (
        <HelperTextWrapper>
          <Typography variant="body2" color="textSecondary">
            {helperText}
          </Typography>
        </HelperTextWrapper>
      )}
    </StyledAccordionSummary>
  );
};

export default AccordionSummary;
export * from '@mui/material/AccordionSummary';
