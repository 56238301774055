import * as PSMIcons from '@octanner/prism-icons';
import React from 'react';
import CodeBlock from './CodeBlock';
import * as PSM from '@octanner/prism-core/dist';
import styled from '@emotion/styled';

const icons = [
  { name: 'Add', component: <PSMIcons.Add /> },
  { name: 'AddCircleOutline', component: <PSMIcons.AddCircleOutline /> },
  { name: 'Agenda', component: <PSMIcons.Agenda /> },
  { name: 'ArrowUp', component: <PSMIcons.ArrowUp /> },
  { name: 'ArrowDown', component: <PSMIcons.ArrowDown /> },
  { name: 'ArrowLeft', component: <PSMIcons.ArrowLeft /> },
  { name: 'ArrowRight', component: <PSMIcons.ArrowRight /> },
  { name: 'ArrowTailUp', component: <PSMIcons.ArrowTailUp /> },
  { name: 'ArrowTailDown', component: <PSMIcons.ArrowTailDown /> },
  { name: 'ArrowTailLeft', component: <PSMIcons.ArrowTailLeft /> },
  { name: 'ArrowTailRight', component: <PSMIcons.ArrowTailRight /> },
  { name: 'ArrowTriangleUp', component: <PSMIcons.ArrowTriangleUp /> },
  { name: 'ArrowTriangleDown', component: <PSMIcons.ArrowTriangleDown /> },
  { name: 'ArrowTriangleLeft', component: <PSMIcons.ArrowTriangleLeft /> },
  { name: 'ArrowTriangleRight', component: <PSMIcons.ArrowTriangleRight /> },
  { name: 'Attachment', component: <PSMIcons.Attachment /> },
  { name: 'Attention', component: <PSMIcons.Attention /> },
  { name: 'AvatarAdd', component: <PSMIcons.AvatarAdd /> },
  { name: 'AvatarCheck', component: <PSMIcons.AvatarCheck /> },
  { name: 'AvatarGroup', component: <PSMIcons.AvatarGroup /> },
  { name: 'Bookmark', component: <PSMIcons.Bookmark /> },
  { name: 'BulletedList', component: <PSMIcons.BulletedList /> },
  { name: 'Calendar', component: <PSMIcons.Calendar /> },
  { name: 'CalendarAdd', component: <PSMIcons.CalendarAdd /> },
  { name: 'Chart', component: <PSMIcons.Chart /> },
  { name: 'Chat', component: <PSMIcons.Chat /> },
  { name: 'ChatGroup', component: <PSMIcons.ChatGroup /> },
  { name: 'Check', component: <PSMIcons.Check /> },
  { name: 'CheckResponsive', component: <PSMIcons.CheckResponsive /> },
  { name: 'Checkbox', component: <PSMIcons.Checkbox /> },
  { name: 'Close', component: <PSMIcons.Close /> },
  { name: 'Copy', component: <PSMIcons.Copy /> },
  { name: 'Crop', component: <PSMIcons.Crop /> },
  { name: 'Desktop', component: <PSMIcons.Desktop /> },
  { name: 'Document', component: <PSMIcons.Document /> },
  { name: 'DownloadCloud', component: <PSMIcons.DownloadCloud /> },
  { name: 'DownloadFile', component: <PSMIcons.DownloadFile /> },
  {
    name: 'Draggable',
    component: <PSMIcons.Draggable />,
  },
  { name: 'EditNote', component: <PSMIcons.EditNote /> },
  { name: 'Eye', component: <PSMIcons.Eye /> },
  { name: 'EyeSlash', component: <PSMIcons.EyeSlash /> },
  { name: 'Filter', component: <PSMIcons.Filter /> },
  { name: 'Flag', component: <PSMIcons.Flag /> },
  { name: 'FormatBold', component: <PSMIcons.FormatBold /> },
  { name: 'FormatItalic', component: <PSMIcons.FormatItalic /> },
  { name: 'FormatUnderline', component: <PSMIcons.FormatUnderline /> },
  { name: 'FullScreen', component: <PSMIcons.FullScreen /> },
  { name: 'Gift', component: <PSMIcons.Gift /> },
  { name: 'Globe', component: <PSMIcons.Globe /> },
  { name: 'Goal', component: <PSMIcons.Goal /> },
  { name: 'Group', component: <PSMIcons.Group /> },
  { name: 'Heart', component: <PSMIcons.Heart /> },
  { name: 'Image', component: <PSMIcons.Image /> },
  { name: 'InboxCheck', component: <PSMIcons.InboxCheck /> },
  { name: 'InformationCircle', component: <PSMIcons.InformationCircle /> },
  { name: 'Library', component: <PSMIcons.Library /> },
  { name: 'Link', component: <PSMIcons.Link /> },
  { name: 'Lock', component: <PSMIcons.Lock /> },
  { name: 'Mail', component: <PSMIcons.Mail /> },
  { name: 'MenuDots', component: <PSMIcons.MenuDots /> },
  { name: 'MenuHamburger', component: <PSMIcons.MenuHamburger /> },
  { name: 'Minus', component: <PSMIcons.Minus /> },
  { name: 'MinusInCircle', component: <PSMIcons.MinusInCircle /> },
  { name: 'NumberedList', component: <PSMIcons.NumberedList /> },
  { name: 'Notes', component: <PSMIcons.Notes /> },
  { name: 'Notification', component: <PSMIcons.Notification /> },
  { name: 'Pause', component: <PSMIcons.Pause /> },
  { name: 'Pencil', component: <PSMIcons.Pencil /> },
  { name: 'Person', component: <PSMIcons.Person /> },
  { name: 'Play', component: <PSMIcons.Play /> },
  { name: 'PlayScreen', component: <PSMIcons.PlayScreen /> },
  { name: 'PlayWithCircle', component: <PSMIcons.PlayWithCircle /> },
  { name: 'Power', component: <PSMIcons.Power /> },
  { name: 'Print', component: <PSMIcons.Print /> },
  { name: 'Refresh', component: <PSMIcons.Refresh /> },
  { name: 'Rotate', component: <PSMIcons.Rotate /> },
  { name: 'Search', component: <PSMIcons.Search /> },
  { name: 'Select', component: <PSMIcons.Select /> },
  { name: 'Send', component: <PSMIcons.Send /> },
  { name: 'SendUnfilled', component: <PSMIcons.SendUnfilled /> },
  { name: 'SettingsGear', component: <PSMIcons.SettingsGear /> },
  { name: 'Share', component: <PSMIcons.Share /> },
  { name: 'ShoppingCart', component: <PSMIcons.ShoppingCart /> },
  { name: 'VolumeLoud', component: <PSMIcons.VolumeLoud /> },
  { name: 'VolumeLow', component: <PSMIcons.VolumeLow /> },
  { name: 'VolumeMedium', component: <PSMIcons.VolumeMedium /> },
  { name: 'VolumeOff', component: <PSMIcons.VolumeOff /> },
  { name: 'Verified', component: <PSMIcons.Verified /> },
  { name: 'Star', component: <PSMIcons.Star /> },
  { name: 'Tag', component: <PSMIcons.Tag /> },
  { name: 'Trash', component: <PSMIcons.Trash /> },
  { name: 'Trophy', component: <PSMIcons.Trophy /> },
  { name: 'Unlock', component: <PSMIcons.Unlock /> },
  { name: 'Video', component: <PSMIcons.Video /> },
  { name: 'Wellness', component: <PSMIcons.Wellness /> },
  { name: 'Write', component: <PSMIcons.Write /> },
  { name: 'Yearbook', component: <PSMIcons.Yearbook /> },
  { name: 'ZoomIn', component: <PSMIcons.ZoomIn /> },
  { name: 'Warning', component: <PSMIcons.Warning /> },
  { name: 'Sort', component: <PSMIcons.Sort /> },
  { name: 'SortAscending', component: <PSMIcons.SortAscending /> },
  { name: 'SortDescending', component: <PSMIcons.SortDescending /> },
  { name: 'Upload', component: <PSMIcons.Upload /> },
  { name: 'BrokenImage', component: <PSMIcons.BrokenImage /> },
  { name: 'Alert', component: <PSMIcons.Alert /> },
  { name: 'Mobile', component: <PSMIcons.Mobile /> },
  { name: 'CheckCircle', component: <PSMIcons.CheckCircle /> },
  { name: 'Home', component: <PSMIcons.Home /> },
  { name: 'HomeUnfilled', component: <PSMIcons.HomeUnfilled /> },
  { name: 'CartUnfilled', component: <PSMIcons.CartUnfilled /> },
  { name: 'PersonUnfilled', component: <PSMIcons.PersonUnfilled /> },
  {
    name: 'NotificationUnfilled',
    component: <PSMIcons.NotificationUnfilled />,
  },
  { name: 'SearchUnfilled', component: <PSMIcons.SearchUnfilled /> },
  {
    name: 'Sparkle',
    component: <PSMIcons.Sparkle />,
  },
  {
    name: 'BillingDetail',
    component: <PSMIcons.BillingDetail />,
  },
  {
    name: 'AnniversaryEvents',
    component: <PSMIcons.AnniversaryEvents />,
  },
  {
    name: 'Activity',
    component: <PSMIcons.Activity />,
  },
  {
    name: 'AnniversaryParticipants',
    component: <PSMIcons.AnniversaryParticipants />,
  },
  {
    name: 'Budget',
    component: <PSMIcons.Budget />,
  },
  {
    name: 'BudgetSegment',
    component: <PSMIcons.BudgetSegment />,
  },
  {
    name: 'EmployeeVisits',
    component: <PSMIcons.EmployeeVisits />,
  },
  {
    name: 'EmployeeFile',
    component: <PSMIcons.EmployeeFile />,
  },
  {
    name: 'Orders',
    component: <PSMIcons.Orders />,
  },
  {
    name: 'PayrollSummary',
    component: <PSMIcons.PayrollSummary />,
  },
  {
    name: 'PayrollIssued',
    component: <PSMIcons.PayrollIssued />,
  },
  {
    name: 'PayrollRedeemed',
    component: <PSMIcons.PayrollRedeemed />,
  },

  {
    name: 'FileUpload',
    component: <PSMIcons.FileUpload />,
    deprecated: true,
    reason: 'use `<Upload />`',
  },
  {
    name: 'Download',
    component: <PSMIcons.Download />,
    deprecated: true,
    reason: 'use `<DownloadFile />`',
  }
];

const Icons = () => {
  const [searchValue, setSearch] = React.useState('');

  const filteredIcons = icons.filter((icon) =>
    icon.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const StyledCard = styled(PSM.Card)`
    margin-bottom: 24px;
    padding: 8px;
  `;

  const FlexCenterAlign = styled.div`
    display: flex;
    justify-content: center;

    align-items: center;
    margin: 8px 0px;
  `;

  const iconMapper = (icons) => {
    if (icons.length === 0) return <div>No icons found</div>;
    return icons.map((icon) => (
      <PSM.Grid item xs={6} key={icon.name}>
        <StyledCard>
          <FlexCenterAlign>{icon.component}</FlexCenterAlign>
          {icon.deprecated && (
            <PSM.Typography sx={{ textAlign: 'center' }}>
              Deprecated: {icon.reason}
            </PSM.Typography>
          )}
          <PSM.Typography>
            <CodeBlock>{`<${icon.name} />`}</CodeBlock>
          </PSM.Typography>
        </StyledCard>
      </PSM.Grid>
    ));
  };

  return (
    <div>
      <PSM.TextField
        label="search"
        value={searchValue}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ width: '100%', mb: 4 }}
      />
      <PSM.Grid container spacing={3}>
        {searchValue.length > 0 ? iconMapper(filteredIcons) : iconMapper(icons)}
      </PSM.Grid>
    </div>
  );
};

export default Icons;
