import { createMyPlugins } from './typescript/plateTypes';
import { basicElementsPlugins } from './plugins/basicElementPlugins';
import { basicMarksPlugins } from './plugins/basicMarkPlugins';
import { myLinkPlugin } from './plugins/linkPlugin';
import { listPlugins, myAutoformatPlugin } from './plugins/listPlugins';
import { plateUI } from './plugins/plateUI';

const Plugins = createMyPlugins(
  [
    ...basicElementsPlugins,
    ...basicMarksPlugins,
    ...listPlugins,
    ...myLinkPlugin,
    ...myAutoformatPlugin,
  ],
  {
    components: plateUI,
  },
);

export default Plugins;
