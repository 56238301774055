import Typography from '../Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import CultureCloudGradientLogo from '../Header/CultureCloudGradientLogo';
import {
  ErrorCodeContainer,
  MarginBottom,
  MiddleButton,
  SorryText,
  StyledButton,
} from './styles';
import { ErrorProps } from './types';

const Error: React.FC<ErrorProps> = ({
  errorCode,
  homepageUrl = '/',
  recognizeUrl,
  routerBack,
  recognitionPermission = false,
}) => {
  const { t } = useTranslation();
  const breakAfterComma = (text: string) => {
    const commaIndex = text.indexOf(',');
    if (commaIndex === -1) {
      return text;
    }
    const firstPart = text.slice(0, commaIndex + 1);
    const secondPart = text.slice(commaIndex + 1);
    return (
      <>
        {firstPart}
        <br aria-hidden={true} />
        {secondPart}
      </>
    );
  };

  const renderText = () => {
    const sorry = breakAfterComma(
      t(
        'prism-header:error-has-occured',
        'We’re sorry, an error has occurred.',
      ),
    );
    return (
      <>
        <SorryText variant="h1" fontSize="56px" lineHeight="64px">
          {sorry}
        </SorryText>
        <MarginBottom>
          <Typography>
            {t(
              'prism-header:service-unavailable',
              "Service is temporarily unavailable. We're incredibly sorry for the inconvenience. Please try again at a later time.",
            )}
          </Typography>
          {recognitionPermission && (
            <Typography data-testid="error:text:recognize someone">
              {t(
                'prism-header:send-some-recognition',
                'While you wait, take a second to send some recognition!',
              )}
            </Typography>
          )}
          {errorCode && (
            <ErrorCodeContainer data-testid="error:text:error code">
              {t('prism-header:code', 'Code:')} {errorCode}
            </ErrorCodeContainer>
          )}
        </MarginBottom>
      </>
    );
  };
  const renderButtons = () => {
    return (
      <MarginBottom>
        {recognitionPermission && recognizeUrl && (
          <StyledButton
            data-testid="error:button:recognize someone"
            href={recognizeUrl}
          >
            {t('prism-header:recognize-someone', 'Recognize someone')}
          </StyledButton>
        )}
        {routerBack !== undefined && (
          <MiddleButton
            color="secondary"
            onClick={routerBack}
            data-testid="error:button:go back"
          >
            {t('prism-header:go-back', 'Go back')}
          </MiddleButton>
        )}
        {homepageUrl && (
          <Button
            variant="text"
            data-testid="error:button:return to homepage"
            href={homepageUrl}
          >
            {t('prism-header:return-to-homepage', 'Return to homepage')}
          </Button>
        )}
      </MarginBottom>
    );
  };

  return (
    <>
      <CultureCloudGradientLogo
        data-testid="culturecloud-logo"
        aria-label="Culture Cloud Logo"
      />
      {renderText()}
      {renderButtons()}
    </>
  );
};

export default Error;
