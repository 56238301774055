import React from 'react';
import { ColorLabel, Color } from './styles';
import { ColorBoxProps } from './types';
import Box from '../Box';

const ColorBox: React.FC<ColorBoxProps> = ({ color, label, isSemantic }) => (
  <Box>
    <Color color={color} label={label} isSemantic={isSemantic} />
    <ColorLabel
      variant={label?.includes('Primary Brand Color') ? 'h5' : 'body2'}
      label={label}
      gutterBottom
    >
      {label}
    </ColorLabel>
  </Box>
);

export default ColorBox;
