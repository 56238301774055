import {
  createMyPlugins,
  MyPlatePlugin,
  MyValue,
} from '../typescript/plateTypes';
import {
  createLinkPlugin,
  LinkPlugin,
  PlateFloatingLink,
  RenderAfterEditable,
  ELEMENT_LINK,
  type PlateEditor,
  getPluginOptions,
  type Value,
} from '@udecode/plate';
import { plateUI } from './plateUI';
import type { TElement } from '@udecode/plate-common';

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

const isUrl = (text: string) => {
  return (
    localhostDomainRE.test(text) ||
    nonLocalhostDomainRE.test(text) ||
    protocolAndDomainRE.test(text)
  );
};

export interface TLinkElement extends TElement {
  url: string;
  target?: string;
}
export const getLinkAttributes = <V extends Value>(
  editor: PlateEditor<V>,
  link: TLinkElement,
) => {
  const { defaultLinkAttributes } = getPluginOptions<LinkPlugin, V>(
    editor,
    ELEMENT_LINK,
  );
  const attributes = { ...defaultLinkAttributes };
  if (!link.url.startsWith('http') && isUrl(link.url)) {
    link.url = `https://${link.url}`;
  }
  return attributes;
};
export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink as RenderAfterEditable<MyValue>,
  options: {
    isUrl,
  },
  then: (editor) => ({
    props: ({ element }) => ({
      nodeProps: getLinkAttributes(editor, element as TLinkElement),
    }),
  }),
};

export const myLinkPlugin = createMyPlugins([createLinkPlugin(linkPlugin)], {
  components: plateUI,
});
