import React from 'react';
import MuiAlert from '@mui/material/Alert';
import { Close } from '@octanner/prism-icons';
import IconButton from '../IconButton';
import ClampText from './ClampText';
import { tannerGray } from '../ThemeProvider/colors';
import { AlertProps as MuiAlertProps } from '@mui/material/Alert';

interface AlertProps extends Omit<MuiAlertProps, 'severity' | 'variant'> {
  variant?: 'standard' | 'filled' | 'outlined' | 'vertical';
  severity?: 'error' | 'warning' | 'info' | 'success' | 'secondary';
  maxLines?: number;
}

const Alert: React.FC<AlertProps> = ({
  action,
  onClose,
  variant = 'standard',
  children,
  severity = 'info',
  maxLines = 3,
  ...props
}) => {
  const secondaryStyles =
    severity === 'secondary'
      ? {
          backgroundColor: tannerGray[100],
          color: tannerGray[800],
        }
      : {};

  return (
    <MuiAlert
      {...props}
      sx={
        variant === 'vertical'
          ? {
              display: 'flex',
              padding: '8px 16px',
              '& .MuiAlert-message': {
                padding: '4px 0px',
              },
              alignItems: 'baseline',
              ...secondaryStyles,
            }
          : secondaryStyles
      }
      variant="standard"
      severity={severity === 'secondary' ? 'info' : severity}
      action={
        <>
          {action}
          {onClose && (
            <IconButton
              size="small"
              sx={{
                marginLeft: 3,
                ...(severity === 'secondary' && {
                  '&.MuiIconButton-sizeSmall': {
                    color: tannerGray[600],
                    '&:hover': {
                      backgroundColor: tannerGray[200],
                    },
                  },
                }),
              }}
              onClick={onClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          )}
        </>
      }
    >
      {variant === 'vertical' ? (
        <ClampText severity={severity} text={children} maxLines={maxLines} />
      ) : (
        children
      )}
    </MuiAlert>
  );
};

export default Alert;
export * from '@mui/material/Alert';
