import React, { forwardRef } from 'react';
import MuiStepper, {
  StepperProps as MuiStepperProps,
} from '@mui/material/Stepper';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import MuiStep from '@mui/material/Step';
import MuiStepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { Check, CheckResponsive, Pencil } from '@octanner/prism-icons';
import { styled } from '@mui/material/styles';
import { tannerWhite, tannerGray } from '../ThemeProvider/colors';
import { useMediaQuery } from '../hooks';

interface Step {
  label: string;
  id?: string;
}
export type StepperProps = MuiStepperProps & {
  steps: Step[];
  onStepClick?(step: Step, index: number): void;
};

const StepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
  isMobile: boolean;
}>(({ theme, ownerState, isMobile }) => ({
  color: tannerGray[600],
  display: 'flex',
  width: !isMobile ? 36 : 18,
  height: !isMobile ? 36 : 18,
  alignItems: 'center',
  border: `2px solid ${tannerGray[!isMobile ? 300 : 200]}`,
  borderRadius: '50%',
  justifyContent: 'center',
  fontWeight: 700,
  fontSize: !isMobile ? 14 : 10,
  backgroundColor: !isMobile ? 'transparent' : tannerGray[200],
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    backgroundColor: 'transparent',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    cursor: 'pointer',
    '& .pencilIcon': {
      display: 'none',
    },
    '&:hover': {
      '& .pencilIcon': {
        display: 'inline-block',
      },
      '& .checkIcon, .checkResponsiveIcon': {
        display: 'none',
      },
    },
  }),
  '& .StepIcon-completedIcon': {
    zIndex: 1,
    display: 'flex',
    color: tannerWhite,
    fontWeight: 700,
  },
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  const isMobile = useMediaQuery('(max-width: 480px)');

  return (
    <StepIconRoot
      ownerState={{ active, completed }}
      className={className}
      isMobile={isMobile}
    >
      {completed ? (
        <div className="StepIcon-completedIcon">
          {!isMobile ? (
            <Check className="checkIcon" />
          ) : (
            <CheckResponsive className="checkResponsiveIcon" />
          )}
          <Pencil className="pencilIcon" />
        </div>
      ) : (
        <div>{!isMobile && icon}</div>
      )}
    </StepIconRoot>
  );
}

const Connector = styled(StepConnector)(({ theme }) => {
  const isMobile = useMediaQuery('(max-width: 480px)');
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: !isMobile ? 20 : 12,
      left: !isMobile ? 'calc(-50% + 16px)' : 'calc(-50% - 3px)',
      right: !isMobile ? 'calc(50% + 16px)' : 'calc(50% - 3px)',
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: tannerGray[!isMobile ? 300 : 200],
      borderTopWidth: 2,
      borderRadius: 3,
      margin: '0px 12px',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
      },
    },
  };
});

const ConnectorLabel = styled(MuiStepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.alternativeLabel}`]: {
    [`& .${stepLabelClasses.active}`]: {
      fontWeight: 800,
    },
    [`& .${stepLabelClasses.alternativeLabel}`]: {
      fontSize: 12,
      marginTop: 4,
    },
  },
}));

const Stepper = forwardRef<HTMLButtonElement, StepperProps>(
  ({ steps, activeStep = 0, onStepClick = () => {}, ...props }, ref) => {
    const isMobile = useMediaQuery('(max-width: 480px)');
    return (
      <MuiStepper
        connector={<Connector />}
        {...props}
        alternativeLabel
        activeStep={activeStep}
        orientation="horizontal"
      >
        {steps.map((step, idx) => {
          return (
            <MuiStep
              key={step.label}
              aria-label={`${step.label} ${
                idx === activeStep ? ' current step' : ' step'
              }${idx + 1} of ${steps.length}`}
              tabIndex={idx <= activeStep ? 0 : -1}
              role={(idx=== activeStep) ? "none": "button"}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onStepClick(step, idx);
                }
              }}
            >
              <ConnectorLabel
                StepIconComponent={StepIcon}
                onClick={() => {
                  if (idx < activeStep) {
                    onStepClick(step, idx);
                  }
                }}
              >
                {!isMobile && step.label}
              </ConnectorLabel>
            </MuiStep>
          );
        })}
      </MuiStepper>
    );
  },
);

export default Stepper;
