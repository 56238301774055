import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { tannerGray } from '../ThemeProvider/colors';
import { ArrowDown, ArrowUp, Close, Lock } from '@octanner/prism-icons';
import { useTranslation } from 'react-i18next';
import Box from '../Box';
import { IconButton, Tooltip } from '@mui/material';

const HelperContainer = styled('span', { name: 'PrismTextFieldHelper' })({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: 12,
});

const HelperText = styled('span', { name: 'PrismTextFieldHelperText' })({
  width: 1,
  overflowWrap: 'break-word',
  flex: '1 1 auto',
  ariaLive: 'polite',
});

const HelperTextCount = styled('span', {
  name: 'PrismTextFieldHelperTextCount',
})({
  flex: '0 0 auto',
});

const Stepper = styled('div', {
  name: 'PrismStepper',
  shouldForwardProp: (prop) => prop !== 'disabled',
})<React.HTMLAttributes<HTMLDivElement> & { disabled?: boolean }>(
  ({ disabled = true }) => ({
    display: 'none',
    pointerEvents: disabled ? 'none' : 'auto',
  }),
  ({ disabled = false }) => ({
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: 12,
    pointerEvents: disabled ? 'none' : 'auto',
  }),
);

const StepperButton = styled('div', { name: 'PrismStepperButton' })({
  '&:first-of-type': {
    alignItems: 'flex-end',
  },
  '&:last-of-type': {
    alignItems: 'flex-start',
  },
  color: tannerGray['400'],
  cursor: 'pointer',
  '&:hover': {
    color: '#000',
  },
  display: 'flex',
  flexBasis: '50%',
  transition: 'color 200ms',
});

const CloseButton = styled(Close, {
  name: 'CloseButton',
  shouldForwardProp: (prop: string) => prop !== 'hasValue',
})<{
  hasValue: boolean;
}>(({ hasValue = false }) => ({
  color: hasValue ? tannerGray['600'] : 'transparent',
  cursor: 'pointer',
  fontSize: 16,
}));

const stepInput = (dir: 'up' | 'down') => (e: React.MouseEvent) => {
  // Update this if structure changes
  const input = e.currentTarget?.parentElement
    ?.previousSibling as HTMLInputElement | null;

  if (input) {
    if (dir === 'up') {
      input.stepUp();
    }
    if (dir === 'down') {
      input.stepDown();
    }

    const event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
  }
};

const clearInput = (e: React.MouseEvent) => {
  // Update this if structure changes
  const input = e.currentTarget?.previousSibling as HTMLInputElement | null;

  if (input) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;

    nativeInputValueSetter?.call(input, '');

    const event = new Event('input', { bubbles: true });
    input.dispatchEvent(event);
  }
};

type OmittedTextFiledProps = Omit<MuiTextFieldProps, 'select' | 'SelectProps'>;
export type TextFieldProps = OmittedTextFiledProps & {
  className?: string;
  displayCount?: boolean;
  maxLength?: number;
  optional?: boolean;
  showOptional?: boolean;
  helperTextProps?: Partial<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
  >;
  countProps?: Partial<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
  >;
};

const TextField: React.FC<TextFieldProps> = ({
  displayCount = false,
  error: originalError,
  helperText,
  maxLength = 500,
  onChange,
  required,
  helperTextProps,
  countProps,
  showOptional = false,
  ...props
}) => {
  const { t } = useTranslation();
  const optional = required === false && showOptional;
  const [currentValue, setCurrentValue] = React.useState<string>(
    String(props.value || props.defaultValue || ''),
  );
  React.useEffect(() => {
    if (typeof props.value === 'undefined') return;
    setCurrentValue(String(props.value || ''));
  }, [props.value]);

  const currentTextLength = currentValue?.length;
  const error =
    originalError || (displayCount && currentTextLength > maxLength);

  const classes = useMemo(() => {
    const listOfClasses = [];
    if (props.className) {
      listOfClasses.push(props.className);
    }
    if (props.multiline) {
      listOfClasses.push('MuiTextField-multiline');
    }
    if (props?.InputProps?.readOnly) {
      listOfClasses.push('MuiTextField-readonly');
    }
    if (props.label) {
      const label = props.label as String;
      listOfClasses.push(
        `MuiTextField-multiline-clamp-${
          label.length > 120 ? 3 : Math.ceil(label.length / 60)
        }`,
      );
    }
    return listOfClasses.join(' ');
  }, [
    props.className,
    props.multiline,
    props.label,
    props?.InputProps?.readOnly,
  ]);

  return (
    <MuiTextField
      {...props}
      className={classes}
      error={error}
      onChange={(e) => {
        setCurrentValue(e.target.value);
        if (onChange) onChange(e);
      }}
      InputLabelProps={{
        sx: {
          overflowWrap: 'break-word',
        },
      }}
      helperText={
        optional || helperText || displayCount ? (
          <Box
            component="span"
            sx={{
              display: 'block',
            }}
          >
            <HelperContainer>
              <HelperText
                data-testid="helper-text"
                {...helperTextProps}
                aria-atomic="true"
                aria-live="off"
                role={currentTextLength === 0 ? 'none' : undefined}
              >
                <>
                  {optional && (
                    <span
                      aria-live={currentTextLength === 0 ? undefined : 'off'}
                      aria-hidden={currentTextLength === 0 ? undefined : 'true'}
                    >
                      {t('prism-header:optional', 'Optional')}
                    </span>
                  )}
                  {optional && helperText ? ' | ' : ''}
                  <span
                    aria-live={error ? 'assertive' : 'polite'}
                    role={error ? 'alert' : ''}
                  >
                    {helperText}
                  </span>
                </>
              </HelperText>
              {displayCount && (
                <HelperTextCount
                  className="MuiCharacterCount"
                  data-testid="character-count"
                  aria-hidden={
                    !(
                      currentTextLength >= maxLength - 5 ||
                      currentTextLength === 0
                    )
                  }
                  {...countProps}
                >
                  {currentTextLength.toLocaleString()} /{' '}
                  {maxLength.toLocaleString()}
                </HelperTextCount>
              )}
            </HelperContainer>
          </Box>
        ) : undefined
      }
      select={false}
      SelectProps={undefined}
      InputProps={{
        ...props.InputProps,
        required,
        endAdornment: (() => {
          if (props.InputProps?.readOnly) {
            return (
              <Tooltip title="This field cannot be edited." placement="bottom">
                <IconButton>
                  <Lock />
                </IconButton>
              </Tooltip>
            );
          }
          if (props.type === 'number') {
            return (
              <Stepper disabled={props.disabled}>
                <StepperButton onClick={stepInput('up')}>
                  <ArrowUp />
                </StepperButton>
                <StepperButton onClick={stepInput('down')}>
                  <ArrowDown />
                </StepperButton>
              </Stepper>
            );
          }
          if (props.type === 'search') {
            return (
              <CloseButton onClick={clearInput} hasValue={!!currentValue} />
            );
          }
          return props.InputProps?.endAdornment;
        })(),
      }}
    />
  );
};

export default TextField;
