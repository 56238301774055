import React from 'react';
import { Box, Table, Thead, Tr, Th, Tbody, Td, Heading } from '@modulz/radix';

export function PropsTable({ data = {} }) {
  const hasProps = Object.keys(data).length > 0;

  return (
    <Box
      mt={8}
      mb={7}
      sx={{
        overflow: ['scroll', 'visible'],
        WebkitOverflowScrolling: 'touch',
      }}
    >
      <Heading as="h3" size={2} mt={8} mb={4}>
        Props
      </Heading>
      {hasProps && (
        <Box sx={{ minWidth: ['540px', '0'] }}>
          <Table>
            <Thead>
              <Tr>
                <Th>Prop</Th>
                <Th>Type</Th>
                <Th>Default</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(data).map(([key, value]) => {
                return (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>{value.type}</Td>
                    <Td>{value.default ? value.default : null}</Td>
                    <Td sx={{ lineHeight: 1 }}>{value.description}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
}
