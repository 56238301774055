import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { tannerGray } from '../ThemeProvider/colors';

export const EmptyStateWrapper = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: tannerGray[50],
}));

export const ContentWrapper = styled(Grid)(() => ({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  maxWidth: 500,
}));

export const Header = styled(Typography)(() => ({
  textAlign: 'center',
  marginBottom: 4,
}));

export const Caption = styled(Typography)(() => ({
  textAlign: 'center',
  color: tannerGray[700],
}));

export const ButtonWrapper = styled(Grid)(() => ({
  marginTop: 32,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SecondaryAction = styled(Grid)<{ hasSubmitAction: boolean }>(
  ({ hasSubmitAction }) => ({
    marginTop: hasSubmitAction ? 16 : 0,
  }),
);
