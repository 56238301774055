const searchData = [
  {
    id: 1,
    firstName: 'Joe',
    lastName: 'Bob',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
    disabled: true,
    disabledText: "I'm disabled",
  },
  {
    id: 2,
    firstName: 'Jill',
    lastName: 'Jackson',
    subTitle: 'Some Subtitle',
    type: 'People',
  },
  {
    id: 3,
    firstName: 'Frank',
    lastName: 'Sinatra',
    subTitle: 'Some Subtitle',
    type: 'People',
  },
  {
    id: 4,
    firstName: 'Billy',
    lastName: 'Jean',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 5,
    firstName: 'Meryl',
    lastName: 'Streep',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 6,
    firstName: 'Bob',
    lastName: 'Saget',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 7,
    firstName: 'Zoe',
    lastName: 'Deschanel',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 8,
    firstName: 'Will',
    lastName: 'Ferrell',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 9,
    firstName: 'John',
    lastName: 'Candy',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 10,
    firstName: 'Jane',
    lastName: 'Merry',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 11,
    firstName: 'Jill',
    lastName: 'Ferrell',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
  {
    id: 12,
    firstName: 'Jerry',
    lastName: 'Candy',
    subTitle: 'Some Subtitle',
    avatar: 'https://placehold.it/50',
    type: 'People',
  },
];

export default searchData;