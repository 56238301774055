import React, { PropsWithChildren } from 'react';
import { useTheme, styled, Typography } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { tannerGray, tannerSemantic } from '../ThemeProvider/colors';
import { lighten } from '../colorManipulator';
import Button from '../Button';
import { Variant } from '@mui/material/styles/createTypography';
import { ArrowDown } from '@octanner/prism-icons';

const Accordion = styled(MuiAccordion, { name: 'Accordion' })({
  flex: '1 1 auto',
  '&.MuiAccordion-root:before': {
    height: 0,
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
});

const SummaryContainer = styled('div', { name: 'SummaryContainer' })({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const AccordionSummary = styled(MuiAccordionSummary, {
  name: 'AccordionSummary',
})({
  '&.MuiButtonBase-root': {
    borderRadius: 4,
    fontSize: 14,
    marginTop: '3px',
    '&:hover': {
      backgroundColor: tannerGray['50'],
    },
  },
  '&.MuiAccordionSummary-root': {
    minHeight: 40,
    padding: '0px 0px 0px 16px',
    '& svg': {
      transition: 'transform 200ms',
      marginRight: 16,
    },
    '&.Mui-expanded': {
      minHeight: 40,
      '& svg': {
        transform: 'rotate3d(0, 0, 1, 180deg)',
      },
    },
  },
  '& > .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
});

const AccordionDetails = styled(MuiAccordionDetails, {
  name: 'AccordionDetails',
})({
  '&.MuiAccordionDetails-root': {
    flexDirection: 'column',
    padding: '2px 0px 2px 16px',
    marginTop: '3px',
  },
});

const VerticalTab: React.FC<
  PropsWithChildren<{
    label: string;
    onClick?(): void;
    selected?: boolean;
    expanded?: boolean | undefined;
    onChange?(): void;
    adminColor?: boolean;
    labelVariant?: Variant;
    'data-testid'?: string;
  }>
> = ({
  children,
  label,
  onClick,
  selected = false,
  expanded,
  onChange,
  adminColor = false,
  labelVariant = 'body1',
  'data-testid': testid,
}) => {
  const theme = useTheme();
  if (children)
    return (
      <Accordion
        data-testid={testid}
        elevation={0}
        expanded={
          children
            ? typeof expanded === 'boolean'
              ? expanded
              : undefined
            : false
        }
        onClick={onClick}
        onKeyDown={(e: React.KeyboardEvent) =>
          onClick && e.key === 'Enter' && onClick()
        }
        onChange={onChange}
      >
        <AccordionSummary
          focusRipple
          disableRipple={false}
          disableTouchRipple
          style={{
            color: selected
              ? lighten(
                  adminColor
                    ? tannerSemantic['info'].color
                    : theme.palette.primary.main,
                  0.85,
                )
              : undefined,
          }}
        >
          <SummaryContainer>
            <Typography variant={labelVariant}>{label}</Typography>{' '}
            {<ArrowDown />}
          </SummaryContainer>
        </AccordionSummary>
        <AccordionDetails data-testid="accordion-child-container">
          {children}
        </AccordionDetails>
      </Accordion>
    );
  return (
    <Button
      data-testid={`${testid}-button`}
      variant="text"
      color="inherit"
      sx={{
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: '3px',
        borderWidth: 0,
        backgroundColor: selected
          ? tannerSemantic['info'].background
          : 'inherit',
      }}
      onClick={onClick}
      onKeyDown={(e: React.KeyboardEvent) =>
        onClick && e.key === 'Enter' && onClick()
      }
    >
      <Typography
        sx={{
          color: selected ? tannerSemantic['info'].color : 'inherit',
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default VerticalTab;
