import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material';
import MuiTooltip from '@mui/material/Tooltip';
import { tannerGray } from '../ThemeProvider/colors';
export * from '@mui/material/Tooltip';

const Container = styled('div')({
  borderTop: `1px solid ${tannerGray['600']}`,
  margin: '8px -12px -8px -12px',
  padding: '4px 12px 5px 12px;',
});

const Callout: React.FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};

const Tooltip = Object.assign(MuiTooltip, { Callout });

export default Tooltip;
