import React, { useEffect } from 'react';
import CardMedia from '../CardMedia';
import Card from '../Card';
import { Play, Pause } from '@octanner/prism-icons';
import Typography from '../Typography';
import {
  StyledCard,
  StyledCardActionArea,
  MediaButton,
  MediaCardContent,
  MediaCardWrapper,
  StyledBrokenImage,
} from './styles';
import { useTranslation } from 'react-i18next';
import { getPreferredMediaUrl } from './MediaLibrary';
import { Media, MediaCardProps } from './types';

const MediaCard: React.FC<
  React.ComponentProps<typeof Card> & MediaCardProps
> = ({ setSelected, title, onMediaSelect, media, size }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [paused, setPaused] = React.useState(true);
  const [error, setError] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!paused) {
      if (videoRef?.current?.play) {
        videoRef?.current?.play();
      }
    } else {
      if (videoRef?.current?.pause) {
        videoRef?.current?.pause();
      }
    }
  }, [paused]);

  const onCardPlayClick = () => {
    setPaused(!paused);
  };

  const onCardSelect = (item: Media | null) => {
    if (onMediaSelect) {
      onMediaSelect(item);
    }
    setSelected(item);
  };

  const ImageMedia = (
    <CardMedia
      component={media.mediaType}
      playsInline
      controlsList="nodownload"
      disablePictureInPicture
      data-testid="video"
      src={
        getPreferredMediaUrl(media.thumbNailUrl, media.normalUrl) ||
        media.largeUrl
      }
      title={title}
      onError={() => {
        setError(true);
      }}
    />
  );

  return (
    <MediaCardWrapper>
      <StyledCard
        isError={error}
        tabIndex={0}
        onClick={() => onCardSelect(media)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onCardSelect(media);
          }
        }}
      >
        {error ? (
          <MediaCardContent>
            {size !== 'auto' && <StyledBrokenImage />}
            <Typography p={2} variant={size !== 'sm' ? 'body2' : 'body1'}>
              {t('prism-header:media-error', ' This media failed to load.')}
            </Typography>
          </MediaCardContent>
        ) : (
          <StyledCardActionArea
            tabIndex={-1}
            data-testid="media-library-card"
            aria-label={
              media.mediaType === 'img'
                ? `select ${media.title}`
                : `select ${media.title} video`
            }
          >
            {media.mediaType === 'img' ? (
              ImageMedia
            ) : (
              <CardMedia
                ref={videoRef}
                component={media.mediaType}
                playsInline
                controlsList="nodownload"
                disablePictureInPicture
                data-testid="video"
                src={media.mediaUrl}
                title={title}
                poster={media.largeUrl || media.thumbNailUrl || media.normalUrl}
              />
            )}
          </StyledCardActionArea>
        )}
        {media.mediaType === 'video' && (
          <MediaButton
            tabIndex={0}
            disableRipple
            disableFocusRipple
            onClick={(e) => {
              e.stopPropagation();
              onCardPlayClick();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            role="button"
            aria-label={
              paused
                ? `play ${media.title} video`
                : `pause ${media.title} video`
            }
            aria-live="polite"
          >
            {paused ? <Play /> : <Pause />}
          </MediaButton>
        )}
      </StyledCard>
    </MediaCardWrapper>
  );
};

export default MediaCard;
