import React, { ComponentProps, FC } from 'react';
import MuiListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { tannerGray } from '../ThemeProvider/colors';

const StyledListSubheader = styled(MuiListSubheader, {
  name: 'PrismListSubheader',
})({
  color: tannerGray['600'],
  fontSize: 12,
  lineHeight: 1.3,
  paddingTop: 8,
  paddingBottom: 6,
  pointerEvents: 'none', // workaround for https://github.com/mui-org/material-ui/issues/18200
});

const ListSubheader: FC<ComponentProps<typeof StyledListSubheader>> = ({
  children,
  ...props
}) => <StyledListSubheader {...props}>{children}</StyledListSubheader>;

export default ListSubheader;
