import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

const MIN_LENGTH = 8;
const UPPER_LOWER_RULE = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
const NUMBER_RULE = /^(?=.*\d).+$/;
const SPECIAL_CHAR_RULE = /^(?=.*[-+_!@#$%^&*., ?]).+$/;

interface PasswordState {
  isMatch: boolean;
  isMinLength: boolean;
  hasUpperAndLower: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  strength: number;
  passwordError: string;
  confirmPasswordError: string;
}

const validatePassword = (
  currentPassword: string,
  password: string,
  confirmPassword: string,
  t: TFunction,
  onBlur: boolean = false,
): PasswordState => {
  let passwordError = '';
  let confirmPasswordError = '';
  let strength = 0;
  const isMinLength = password.length >= MIN_LENGTH;
  const hasUpperAndLower = UPPER_LOWER_RULE.test(password);
  const hasNumber = NUMBER_RULE.test(password);
  const hasSpecialChar = SPECIAL_CHAR_RULE.test(password);

  if (password) strength += 10;
  if (isMinLength) strength += 10;
  if (hasUpperAndLower) strength += 10;
  if (hasNumber) strength += 10;
  if (hasSpecialChar) strength += 10;
  if (password.length > 12 && strength === 50) strength += 50;

  const meetsReqs =
    isMinLength && hasUpperAndLower && hasNumber && hasSpecialChar;
  const isMatch = password === confirmPassword;
  const isCurrent = currentPassword === password;

  if (password && !meetsReqs) {
    passwordError = t(
      'prism-header:password-bad-characters',
      'Password does not contain all required characters',
    );
  } else if (password && isCurrent) {
    passwordError = t(
      'prism-header:password-same-as-current',
      'New password cannot be the same as your current password',
    );
  } else if (onBlur && !isMatch) {
    confirmPasswordError = t(
      'prism-header:passwords-do-not-match',
      'Passwords do not match',
    );
  }

  return {
    hasNumber,
    hasSpecialChar,
    hasUpperAndLower,
    isMatch,
    isMinLength,
    passwordError,
    confirmPasswordError,
    strength,
  };
};

const usePasswordValidator = (
  currentPassword: string,
  password: string,
  confirmPassword: string,
) => {
  const { t } = useTranslation();
  const [state, setState] = useState<PasswordState>({
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperAndLower: false,
    isMatch: false,
    isMinLength: false,
    strength: 0,
    passwordError: '',
    confirmPasswordError: '',
  });

  const validate = () =>
    setState(
      validatePassword(currentPassword, password, confirmPassword, t, true),
    );

  useEffect(() => {
    setState(validatePassword(currentPassword, password, confirmPassword, t));
  }, [currentPassword, password, confirmPassword, setState, t]);

  return {
    ...state,
    isValid:
      state.isMatch &&
      state.isMinLength &&
      state.hasNumber &&
      state.hasSpecialChar &&
      state.hasUpperAndLower,
    actions: { validate },
  };
};

export default usePasswordValidator;
