import {
  createPlateUI,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_LIC,
  ELEMENT_UL,
  ELEMENT_LI,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TH,
  ELEMENT_PARAGRAPH,
  StyledElement,
  withProps,
} from '@udecode/plate';
import { Colors } from '@octanner/prism-core';

export const plateUI = createPlateUI({
  [ELEMENT_H1]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '28px',
        fontWeight: '800',
        fontFamily: '"Noto Sans", sans-serif',
        lineHeight: '150%',
      },
    },
  }),

  [ELEMENT_H2]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '20px',
        fontWeight: '800',
        fontFamily: '"Noto Sans", sans-serif',
        lineHeight: '150%',
      },
    },
  }),

  [ELEMENT_H3]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '16px',
        fontWeight: '800',
        fontFamily: '"Noto Sans", sans-serif',
        lineHeight: '150%',
      },
    },
  }),

  [ELEMENT_H4]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '14px',
        fontWeight: '800',
        fontFamily: '"Noto Sans", sans-serif',
        lineHeight: '150%',
      },
    },
  }),

  [ELEMENT_LIC]: withProps(StyledElement, {
    styles: {
      root: {
        fontFamily: '"Noto Sans", sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '150%',
        color: Colors.tannerGray['900'],
      },
    },
  }),

  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        fontFamily: '"Noto Sans", sans-serif',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '150%',
        color: Colors.tannerGray['900'],
      },
    },
  }),
  [ELEMENT_UL]: withProps(StyledElement, {
    as: 'ul',
  }),
  [ELEMENT_LI]: withProps(StyledElement, {
    as: 'li',
  }),
  [ELEMENT_TABLE]: withProps(StyledElement, {
    as: 'table',
  }),
  [ELEMENT_TH]: withProps(StyledElement, {
    as: 'th',
  }),
  [ELEMENT_TD]: withProps(StyledElement, {
    as: 'td',
    styles: {
      root: {
        width: '25%',
        verticalAlign: 'baseline',
        paddingRight: '16px',
      },
    },
  }),
});
