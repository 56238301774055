import React from 'react';
import { Button } from '@octanner/prism-core/dist';
import type { ButtonProps } from 'prism-core/dist/Button/types';

// ? Extending the props from prism-cores button, please adjust the props here, not in button.
interface AdminButtonProps extends ButtonProps {
  'data-testid'?: string;
  size?: 'small' | 'medium' | 'large';
}

const AdminButton = ({
  children,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
  loading = false,
  disabled = false,
  size = 'medium',
  sx,
  className,
  component = 'button',
  disableElevation = false,
  disableFocusRipple = false,
  disableRipple = false,
  endIcon,
  startIcon,
  fullWidth = false,
  href,
  variant = 'contained',
  onClick,
}: AdminButtonProps) => {
  return (
    <Button
      adminColor
      aria-label={ariaLabel}
      data-testid={dataTestId}
      loading={loading}
      disabled={disabled}
      sx={sx}
      className={className}
      size={size}
      component={component}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      endIcon={endIcon}
      startIcon={startIcon}
      fullWidth={fullWidth}
      href={href}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
export default AdminButton;
