import React from 'react';
import LayoutContext from '../context/Layout';
import { gql, useQuery } from '@apollo/client';
import { Identity } from './interfaces';
import { useDebouncedValue } from '../hooks';

const PrismIdentityFragment = gql`
  fragment PrismIdentityFragment on Identity {
    id
    identityUuid
    firstName
    lastName
    preferredName
    email
    profile {
      avatar
      pronoun
    }
    uniqueId
    jobTitle
    workLocation
    workLocationName
    segmentOfficelocation
  }
`;

export const IDENTITY_SEARCH = gql`
  query identitySearch($searchText: String!, $customerId: String!) {
    identitySearch(
      condition: {
        active: true
        customerId: $customerId
        name: $searchText
        email: $searchText
        uniqueId: $searchText
        searchCondition: "or"
        orderBy: "firstName, lastName, id"
        size: 100
      }
    ) {
      identities {
        businessUnit {
          buName
          parentBusinessUnit {
            buName
          }
        }
        customer {
          customerUuid
          name
          stpNumber
          features {
            effectiveDate
            expirationDate
            featureCode
            featureName
          }
        }
        ...PrismIdentityFragment
        manager {
          ...PrismIdentityFragment
        }
      }
    }
  }
  ${PrismIdentityFragment}
`;

export const useIdentitySearch = (
  searchText: string,
  excludeSelf: boolean = false,
) => {
  const debouncedSearchText = useDebouncedValue(searchText);
  const [currentIdentityQuery] = React.useContext(LayoutContext);
  const customerId =
    currentIdentityQuery?.data?.currentIdentity?.customer?.customerUuid;

  const { loading, error, data } = useQuery<{
    identitySearch: { identities: Identity[] };
  }>(IDENTITY_SEARCH, {
    variables: {
      searchText: debouncedSearchText,
      customerId,
    },
    skip: debouncedSearchText?.length < 2 || !customerId,
  });

  const filteredData = excludeSelf
    ? (data?.identitySearch.identities || []).filter(
        // @ts-ignore the type number from prism is incorrect
        (identity) => identity.id !== layout.me.data?.id,
      )
    : data?.identitySearch.identities;

  return {
    loading,
    error,
    data: filteredData,
  };
};
