import React from 'react';
import Avatar from '../Avatar';
import { Check } from '@octanner/prism-icons';
import {
  StyledBox,
  AvatarContainer,
  AvatarTitleWrap,
  Title,
  TitleContainer,
  CheckWrap,
} from '../Search/styles';

const getAriaLabel = (
  disabled: boolean | undefined,
  isSelected: boolean,
  title: string,
) => {
  switch (true) {
    case disabled:
      return `${title} read only`;
    case isSelected:
      return `${title} selected`;
    default:
      return title;
  }
};

const SearchOptionCard = React.forwardRef<
  HTMLLIElement,
  React.HTMLProps<HTMLLIElement> & {
    avatarUrl?: string;
    disabled?: boolean;
    disabledText?: string;
    selected?: boolean;
    showAvatar?: boolean;
    subTitle?: string;
    title: string;
    type?: string;
  }
>(
  (
    {
      avatarUrl,
      disabled,
      disabledText,
      selected,
      showAvatar,
      subTitle,
      title,
      type,
      ...props
    },
    ref,
  ) => (
    <StyledBox
      component="li"
      ref={ref}
      disabled={disabled}
      aria-label={getAriaLabel(
        disabled,
        Boolean(props['aria-selected']),
        title,
      )}
      {...(props as React.HTMLAttributes<HTMLDivElement>)}
    >
      <AvatarTitleWrap selected={selected}>
        {showAvatar && (
          <AvatarContainer disabled={disabled}>
            <Avatar src={avatarUrl} alt={title} size="sm" />
          </AvatarContainer>
        )}
        <TitleContainer>
          <Title disabled={disabled}> {title}</Title>
          {subTitle && (
            <Title sub disabled={disabled}>
              {subTitle}
            </Title>
          )}
        </TitleContainer>
        <Title light> {(disabled && disabledText) || type}</Title>
      </AvatarTitleWrap>
      <CheckWrap>{selected && <Check />}</CheckWrap>
    </StyledBox>
  ),
);

export default SearchOptionCard;
