export interface Customer {
  customerUuid: string;
  name: string;
  stpNumber: string;
  features: {
    effectiveDate: string;
    expirationDate: string;
    featureCode: string;
    featureName: string;
  }[];
}

export interface Identity {
  profile?: {
    avatar: string;
    catalogCoreCountry?: { iso2Code: string };
    homeCoreCountry?: { iso2Code: string };
    pronoun?: string;
  };
  dateOfBirth: string;
  nonFedFlag?: boolean;
  nonCredFlag?: boolean;
  uniqueId: string;
  email: string;
  id: string;
  identityUuid: string;
  preferredName: string;
  firstName: string;
  lastName: string;
  language?: string;
  jobTitle?: string;
  workLocation?: string;
  workLocationName?: string;
  isManager?: boolean;
  manager?: Identity;
  businessUnit?: BusinessUnit;
  customer?: Customer;
  segmentOfficelocation?: string;
}

export interface Bank {
  bankId: string;
  pointsBalance: number;
  catalogLink: string;
}

export interface UserBank {
  accessCodeDetails?: {
    accessCode: string;
    expiresAt: string;
    status: string;
  }[];
  activeAccessCodeCount?: number;
  bankName: string;
  defaultBank: boolean;
  id: number;
  points: number;
  programId?: number;
}
export interface BusinessUnit {
  buName: string;
  parentBusinessUnit: BusinessUnit;
}

export interface CatalogLinkProps {
  referrerUrl: string;
}

export interface Link {
  id: string;
  translationString: string;
  url: string;
}

export interface ThemeAttribute {
  value: string;
}

export interface Theme {
  themeId?: string;
  logo?: ThemeAttribute;
  canvasImage?: ThemeAttribute;
  primaryColor?: ThemeAttribute;
  secondaryColor?: ThemeAttribute;
  primaryFontColor?: ThemeAttribute;
  secondaryFontColor?: ThemeAttribute;
}

export interface UserMenu {
  linkName: string;
  url: string;
}

export interface Layout {
  bankBalance?: UserBank[];
  cultureCloud?: {
    ccHeader?: {
      links?: Link[];
    };
  };
  ccIsBellIndicatorActive?: {
    isBellIndicatorActive: boolean;
  };
  theme?: Theme;
  hasRedeemAwardsPermission?: {
    hasRedeemAwardsPermission: boolean;
  };
}

export interface CustomerCoreLocaleMapsByCustomerPayLoad {
  customerCoreLocaleMapsByCustomer: {
    nodes: Locale[];
  };
}

export interface Locale {
  locale: {
    code: string;
    languageDescription: string;
  };
}

export interface ccWebUserSearchConfig {
  ccWebUserSearchConfigEnabledFields: {
    fields: CustomSearchFields[];
  };
}

export interface CustomSearchFields {
  enabled: boolean;
  fieldName: string;
  fieldType: FieldTypes;
  order: number;
  translationStringId: string;
}

export enum FieldTypes {
  EmailAddress = 'EMAIL_ADDRESS',
  BusinessUnit = 'BUSINESS_UNIT',
  LeaderName = 'LEADER_NAME',
  EmployeeId = 'EMPLOYEE_ID',
  ParentBusinessUnit = 'PARENT_BUSINESS_UNIT',
  JobTitle = 'JOB_TITLE',
  WorkLocation = 'WORK_LOCATION',
  WorkLocationCondensed = 'WORK_LOCATION_CONDENSED',
}

export enum FieldNames {
  EmailAddress = 'Email address',
  BusinessUnit = 'Business unit',
  LeaderName = 'Leader name',
  EmployeeId = 'Employee ID',
  ParentBusinessUnit = 'Parent Business Unit',
  JobTitle = 'Job Title',
  WorkLocation = 'Work Location',
  WorkLocationCondensed = 'Work Location (Condensed)',
}

export interface IdentityInfoProps {
  identity: Identity;
  color?: 'primary' | 'secondary';
  isMobile?: boolean;
  customSearchFields?: CustomSearchFields[];
  pronounsEnabled?: boolean;
}
export interface IdentityToolTipProps extends IdentityInfoProps {
  name: string;
  primaryColor: string;
  secondaryColor: string;
  pronounsEnabled?: boolean;
}

export interface PreferredPronouns {
  ccWebPreferredPronouns: {
    enabled: boolean;
  };
}

export interface VibeDerivedDetails {
  vibeGetDerivedVibeDetails?: {
    attributes: {
      text: {
        welcomeMessage: string;
        pointsNamePlural: string;
        pointsNameSingular: string;
      };
    };
    vibeName: string;
    vibeUuid: string;
  };
}
