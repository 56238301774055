import React from 'react';
import { StyledToggleButtonGroup, IconWrapper, LabelWrapper } from './styles';
import { ColorToggleButtonProps } from './types';
import ToggleButton from '@mui/material/ToggleButton';

export default function ColorToggleButton({
  options = [],
  value,
  onChange,
  variant = 'primary',
  disabled = false,
}: ColorToggleButtonProps) {
  const [alignment, setAlignment] = React.useState(value || '');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
    onChange?.(event, newAlignment);
  };
  const iconOnly = options.every((option) => !option.label);

  return (
    <StyledToggleButtonGroup
      color="secondary"
      value={alignment}
      exclusive
      onChange={handleChange}
      variant={variant}
      disabled={disabled}
      iconOnly={iconOnly}
    >
      {options.map((option) => (
        <ToggleButton
          key={option.value}
          value={option.value}
          disabled={alignment === option.value}
        >
          {option.icon && (
            <IconWrapper
              isSelected={alignment === option.value}
              variant={variant}
            >
              {option.icon}
            </IconWrapper>
          )}
          {option.label && (
            <LabelWrapper
              isSelected={alignment === option.value}
              variant={variant}
            >
              {option.label}
            </LabelWrapper>
          )}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
