import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

const observerOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

type Props = {
  load: () => void;
  component?: React.ElementType;
};

const ScrollLoader: React.FC<Props> = ({
  load,
  component: Component = 'div',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null),
    [triggerLoad, setTriggerLoad] = useState(false),
    handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
      if (entries[0].intersectionRatio <= 0) {
        return;
      }
      setTriggerLoad(true);
    }, []),
    observer = useMemo(() => {
      return new IntersectionObserver(handleObserver, observerOptions);
    }, [handleObserver]);

  useEffect(() => {
    if (!triggerLoad) {
      return;
    }
    load();
    setTriggerLoad(false);
  }, [triggerLoad, load]);

  useEffect(() => {
    const observableRef = ref.current;
    if (!observer || !observableRef || observer.root) {
      return;
    }
    observer.observe(observableRef);

    return () => {
      if (!observableRef) return;
      observer.unobserve(observableRef);
    };
  }, [observer]);

  return <Component ref={ref} data-testid="scroll-loader"></Component>;
};

export default ScrollLoader;
