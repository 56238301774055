import React, { useEffect, useMemo, useState } from 'react';
import Chip from '../Chip';
import { Identity } from '../Header/interfaces';
import SearchOptionCard from '../Search/SearchOptionCard';
import { CustomPaper, getSearchInputStyles } from '../Search/styles';
import { getIdentityProperty } from '../Search/Search';
import { SearchProps } from './types';
import { FullWidthTextField } from './styles';
import { Popper } from '@mui/material';
import Autocomplete from '../Autocomplete';

const SingleSearch: React.FC<SearchProps> = ({
  classes,
  'data-testid': inputDataTestId,
  disabled = false,
  error,
  filterOptions,
  helperText,
  loading,
  onChange,
  onInputChange,
  options,
  placeholder,
  renderOption,
  style,
  sx,
  value,
}) => {
  const [usableOptions, setUsableOptions] = useState<Identity[]>(options || []);
  const [hasValue, setHasValue] = useState<boolean>(
    Array.isArray(value) ? Boolean(value?.length) : Boolean(value),
  );
  const [freeSolo, setFreeSolo] = useState<boolean>(
    options && options?.length > 0 ? false : true,
  );

  const isInputDisabled = useMemo(() => {
    if (value) {
      return Array.isArray(value) ? Boolean(value?.length) : Boolean(value);
    }
    return hasValue;
  }, [hasValue, value]);

  useEffect(() => {
    setUsableOptions(options || []);
  }, [options]);

  const actualValue = () => {
    if (value && !Array.isArray(value)) {
      return [value];
    } else {
      return value;
    }
  };
  return (
    <Autocomplete
      filterOptions={(options) => {
        if (filterOptions) {
          return filterOptions(options);
        } else {
          return options;
        }
      }}
      onChange={(event, value, reason) => {
        const hasArrayValue = Boolean(value?.length);
        setHasValue(hasArrayValue);
        onChange?.(event, value, reason);
        if (reason === 'selectOption') {
          setUsableOptions([]);
        }
      }}
      onInputChange={onInputChange}
      value={actualValue() || []}
      getOptionLabel={getIdentityProperty('getOptionLabel')}
      multiple={true}
      classes={classes}
      freeSolo={freeSolo}
      sx={
        // Unfortunate workaround for jest to not fail Autcomplete test
        {
          '& .MuiFilledInput-root': {
            paddingTop: '0px',
          },
          ...sx,
        }
      }
      style={style}
      options={usableOptions}
      disabled={disabled}
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement="bottom"
          modifiers={[{ name: 'flip', enabled: false }]}
        />
      )}
      renderTags={(options: Identity[], getTagProps) =>
        options.map((option, index) => (
          <Chip
            label={getIdentityProperty('renderTags')(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(props, option: Identity) => {
        if (renderOption) {
          return renderOption(props, option);
        } else
          return (
            <SearchOptionCard
              {...props}
              avatarUrl={getIdentityProperty('renderOption')(
                option,
                'avatarUrl',
              )}
              showAvatar
              subTitle={getIdentityProperty('renderOption')(option, 'buName')}
              title={getIdentityProperty('renderOption')(option)}
            />
          );
      }}
      PaperComponent={CustomPaper}
      renderInput={(props) => (
        <FullWidthTextField
          error={error}
          helperText={helperText}
          placeholder={isInputDisabled ? '' : placeholder}
          onBlur={() => {
            setFreeSolo(true);
          }}
          InputProps={getSearchInputStyles(disabled, props, loading)}
          inputProps={{
            ...props.inputProps,
            'data-testid': inputDataTestId,
            tabIndex: isInputDisabled ? -1 : 0,
            readOnly: disabled ? disabled : isInputDisabled,
            'aria-hidden': disabled ? disabled : isInputDisabled,
            onKeyDown: (e) => {
              if (e.key === 'Enter' && (options?.length === 0 || loading)) {
                e.stopPropagation();
              }
            },
          }}
        />
      )}
    />
  );
};

export default SingleSearch;
