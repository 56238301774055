import React from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as RC from '@modulz/radix';
import * as RI from '@modulz/radix-icons';
import * as PSM from '@octanner/prism-core/dist';
import * as PA from '@octanner/prism-admin/dist';
import * as PSMTextEditor from '@octanner/prism-texteditor/dist';
import * as PSMDataGrid from '@octanner/prism-datagrid/dist';
import * as PSMUtilties from '@octanner/prism-utilities/dist';
import * as PSMHooks from '@octanner/prism-core/dist/hooks';
import { DebugContextProvider } from '@modulz/primitives';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import Layout from '../components/Layout';
import CodeBlock from '../components/CodeBlock';
import { PropsTable } from '../components/PropsTable';
import { TypesTable } from '../components/TypesTable';
import FunctionDocumentation from '../components/FunctionDocumentation';
import { ThemeSection } from '../components/ThemeSection';
import { APISection } from '../components/APISection';
import OskoEnergyLogo from '../components/OskoEnergyLogo.svg';
import * as PSMIcons from '@octanner/prism-icons';
import searchData from '../example-data/search';
import {events, simpleEvents, simpleOutlinedEvents} from '../example-data/Timeline';
import { useTranslation } from 'react-i18next';
import Icons from '../components/Icons';

const components = {
  data: {
    searchData,
    events,
    simpleEvents,
    simpleOutlinedEvents
  },
  useTranslation,
  ...RC,
  ...RI,
  ...PSMIcons,
  ...PSM,
  ...PSMHooks,
  ...PSMTextEditor,
  ...PSMDataGrid,
  ...PSMUtilties,
  ...PA,
  LAUNCHDARKLY_CLIENT_ID: process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID,
  LuxonAdapter: AdapterLuxon,
  DateTime,
  pre: (props) => <div {...props} />,
  code: CodeBlock,
  table: RC.Table,
  thead: RC.Thead,
  tbody: RC.Tbody,
  tfoot: RC.Tfoot,
  tr: RC.Tr,
  th: RC.Th,
  td: RC.Td,
  inlineCode: RC.Code,
  a: RC.Link,
  PropsTable: PropsTable,
  TypesTable: TypesTable,
  FunctionDocumentation: FunctionDocumentation,
  ol: (props) => <ol style={{ fontSize: '14px' }} {...props} />,
  ul: (props) => <ol style={{ fontSize: '14px' }} {...props} />,
  h1: (props) => <RC.Heading {...props} size={3} mt={8} mb={4} />,
  h2: (props) => <RC.Heading {...props} as="h2" size={2} mt={8} mb={4} />,
  h3: (props) => (
    <RC.Heading
      {...props}
      as="h3"
      size={1}
      mt={7}
      mb={1}
      sx={{ marginBottom: '1rem', lineHeight: 1 }}
    />
  ),
  h4: (props) => (
    <RC.Heading
      {...props}
      as="h4"
      size={0}
      mt={6}
      mb={1}
      sx={{ lineHeight: 2 }}
    />
  ),
  p: (props) => <PSM.Typography gutterBottom {...props} />,
  Prop: (props) => (
    <RC.Box mb={3}>
      <RC.Text as="p" size={2} sx={{ lineHeight: 2, color: 'gray700' }}>
        {props.isOptional && 'optional'}{' '}
        <RC.Code ml={!props.isOptional && -1}>{props.children}</RC.Code>
      </RC.Text>
      {props.default && (
        <RC.Text as="p" size={2} sx={{ lineHeight: 2, color: 'gray700' }}>
          default <RC.Code>{props.default}</RC.Code>
        </RC.Text>
      )}
    </RC.Box>
  ),
  ThemeSection,
  APISection,
  OskoEnergyLogo,
  ReactDOM,
  Icons,
};

function DocPageTemplate({ data, location, ...props }) {
  const children = <MDXRenderer children={data.mdx.body} />;

  return (
    <Layout pathname={location.pathname}>
      <Helmet>
        <title>Prism — {data.mdx.frontmatter.title}</title>
        <meta
          property="og:title"
          content={`Prism — ${data.mdx.frontmatter.title}`}
        />
        <meta
          property="og:description"
          content={data.mdx.frontmatter.description}
        />
      </Helmet>

      <MDXProvider components={components}>
        <RC.Box>
          <RC.Heading size={4} mb={2} sx={{ lineHeight: 4 }}>
            {data.mdx.frontmatter.title}
          </RC.Heading>
          <RC.Heading
            size={1}
            weight="normal"
            mb={7}
            as="h2"
            sx={{ lineHeight: 3, color: 'gray700' }}
          >
            {data.mdx.frontmatter.description}
          </RC.Heading>
          {data.mdx.frontmatter.disableLock === true ? (
            <DebugContextProvider disableLock>{children}</DebugContextProvider>
          ) : (
            children
          )}
        </RC.Box>
      </MDXProvider>
    </Layout>
  );
}

export default DocPageTemplate;

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        component
        description
      }
      body
    }
  }
`;
