import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { Props } from './Types';
import { Typography, CircularProgress, Box } from '@octanner/prism-core';

// TODO: find a better way to inject the license
LicenseInfo.setLicenseKey(
  // @ts-ignore
  typeof __MUI_PRO_LICENSE_KEY__ !== 'undefined' ? __MUI_PRO_LICENSE_KEY__ : '',
);

const InProgress = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mt: 4,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const NoRows = () => {
  // TODO: add translations
  return (
    <Typography
      variant="h5"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        mt: 4,
      }}
    >
      No rows
    </Typography>
  );
};

export default function DataGrid<TRow extends object>(props: Props<TRow>) {
  const {
    getRowId,
    columns,
    rows,
    headerHeight = 50,
    loading = false,
    autoHeight = false,
    height,
    paginationMode = 'client',
    pagination = false,
    rowCount,
    rowsPerPageOptions = [25, 50, 100],
    pageSize = 100,
    onPageSizeChange,
    page = 0,
    onPageChange,
    onSortModelChange,
    sortingMode = 'client',
    checkboxSelection = false,
    onSelectionModelChange,
    keepNonExistentRowsSelected = false,
    selectionModel = [],
    disableSelectionOnClick = false,
    disableColumnMenu = false,
    density = 'standard',
  } = props;

  /* if rerenders are an issue we could wrap 'columns' is a useMemo */
  return (
    <DataGridPro<TRow>
      sx={{ height }}
      autoHeight={autoHeight}
      columns={columns.map((column) => ({
        field: column.field,
        flex: column.flex,
        renderHeader: () => column.renderHeader?.(column),
        renderCell: (params) =>
          column.renderCell?.({
            field: column.field,
            id: getRowId(params.row),
            row: params.row,
          }),
      }))}
      headerHeight={headerHeight}
      rows={rows}
      getRowId={(row) => getRowId(row)}
      disableSelectionOnClick={disableSelectionOnClick}
      loading={loading}
      pagination={pagination}
      rowsPerPageOptions={rowsPerPageOptions}
      pageSize={pageSize}
      onPageSizeChange={(pageSize) => onPageSizeChange?.(pageSize)}
      paginationMode={paginationMode}
      rowCount={rowCount}
      page={page}
      onPageChange={(page) => onPageChange?.(page)}
      sortingMode={sortingMode}
      onSortModelChange={(sort) => onSortModelChange?.(sort)}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionModelChange}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      selectionModel={selectionModel}
      components={{
        Toolbar: () => <>{props.toolbar}</>,
        LoadingOverlay: InProgress,
        NoRowsOverlay: NoRows,
      }}
      density={density}
      disableColumnMenu={disableColumnMenu}
      localeText={{
        toolbarExport: 'Download',
      }}
      defaultGroupingExpansionDepth={
        props.treeProps?.defaultGroupingExpansionDepth
      }
      getTreeDataPath={props.treeProps?.getTreeDataPath}
      groupingColDef={
        props.treeProps
          ? {
              headerName: props.treeProps.groupingColDef.headerName,
              renderCell: (params) =>
                props.treeProps?.groupingColDef.renderCell({
                  field: params.field,
                  id: getRowId(params.row),
                  row: params.row,
                }),
              hideDescendantCount:
                props.treeProps.groupingColDef.hideDescendantCount,
            }
          : undefined
      }
    />
  );
}
