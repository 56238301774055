import React from 'react';
import MenuItemBase from './MenuItemBase';
import { SxProps } from '@mui/material';
export * from '@mui/material/MenuItem';

type Props = {
  children: React.ReactNode;
  className?: string;
  href: string;
  'data-testid'?: string;
  onClick?: (event: React.MouseEvent) => void;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  sx?: SxProps;
  css?: React.CSSProperties;
  target?: string;
};

export default function MenuItemLink(props: Props) {
  return (
    <MenuItemBase
      component="a"
      className={props.className}
      href={props.href}
      onClick={props.onClick}
      target={props.target}
      data-testid={props['data-testid']}
      autoFocus={props.autoFocus}
      style={props.style}
      sx={props.sx}
      css={props.css}
    >
      {props.children}
    </MenuItemBase>
  );
}
