import React from 'react';

const CultureCloudWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="119"
      height="36"
      viewBox="0 0 403 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M81.1244 31.8235C81.1244 21.8253 88.2464 14.8044 96.6273 14.8044C100.092 14.5931 103.455 15.9991 105.743 18.6089L106.773 14.9055H111.156V27.679H106.331C104.383 21.7793 101.654 18.8019 97.6013 18.8019C93.0157 18.8019 89.7994 22.1653 89.7994 31.3365C89.7994 40.5077 93.1628 43.8711 98.6765 43.8711C103.501 43.8711 106.276 40.2136 107.113 34.8469H111.79C110.963 43.1359 106.23 48.4475 97.5462 48.4475C87.5479 48.4475 81.1152 41.7207 81.1152 31.8143L81.1244 31.8235Z"
          fill="white"
        />
        <path
          d="M117.351 40.1125V28.892H114.033V24.8486H124.463V39.1384C124.353 40.379 124.721 41.6196 125.483 42.5937C126.081 43.1359 126.871 43.4208 127.68 43.3748C129.876 43.3748 131.971 41.0958 131.971 37.7692V28.892H128.617V24.8486H139.102V43.623H142.42V47.6664H132.412V41.6656C130.804 45.4701 128.314 48.0983 124.169 48.0983C119.345 48.0983 117.341 44.79 117.341 40.1033L117.351 40.1125Z"
          fill="white"
        />
        <path
          d="M144.404 43.6229H148.108V18.1678H144.404V14.0141H155.276V43.6229H158.979V47.6664H144.404V43.6229Z"
          fill="white"
        />
        <path
          d="M171.33 18.8938V25.0876H178.645V28.892H171.33V40.3974C171.33 42.5018 172.056 43.6689 173.719 43.6689C175.181 43.6689 175.861 42.5478 176.743 39.3774L179.665 40.2044C178.59 45.7641 176.201 48.1534 171.615 48.1534C166.634 48.1534 164.208 45.5711 164.208 40.3515V28.892H160.146V25.0876H164.346V19.7208L171.33 18.8938Z"
          fill="white"
        />
        <path
          d="M183.946 40.1125V28.892H180.629V24.8486H191.068V39.1384C190.958 40.379 191.316 41.6196 192.088 42.5937C192.686 43.1359 193.476 43.4208 194.285 43.3748C196.481 43.3748 198.576 41.0958 198.576 37.7692V28.892H195.213V24.8486H205.698V43.623H209.016V47.6664H199.017V41.6656C197.409 45.4701 194.919 48.0983 190.774 48.0983C185.94 48.0983 183.946 44.79 183.946 40.1033V40.1125Z"
          fill="white"
        />
        <path
          d="M211.441 43.623H214.805V28.8921H211.441V24.8486H221.485V30.7024C222.846 26.4568 225.483 24.3616 228.598 24.3616C231.061 24.2605 233.129 26.1719 233.23 28.6347C233.23 28.7083 233.23 28.7818 233.23 28.8553C233.368 31.024 231.723 32.9079 229.545 33.0457C229.499 33.0457 229.462 33.0457 229.416 33.0457C227.532 33.2571 225.841 31.897 225.639 30.0224C225.621 29.8753 225.612 29.7283 225.621 29.5813C225.621 29.4802 225.667 29.3332 225.667 29.1953C224.343 29.2964 222.744 30.7575 221.908 33.5879V43.6321H226.154V47.6756H211.423V43.6321L211.441 43.623Z"
          fill="white"
        />
        <path
          d="M241.575 37.1902C241.869 42.511 244.01 44.257 247.621 44.257C250.176 44.45 252.501 42.7499 253.089 40.2596H256.894C256.012 45.029 252.795 48.1626 246.408 48.1626C238.809 48.1626 234.168 43.4851 234.168 36.4551C233.966 29.9488 239.075 24.5178 245.581 24.3156C245.774 24.3156 245.967 24.3156 246.16 24.3156C253.861 24.3156 257.473 29.7742 257.133 37.1811H241.584L241.575 37.1902ZM241.621 34.1209H250.149C249.9 29.4894 248.641 27.6331 245.995 27.6331C243.468 27.6331 241.906 29.2413 241.611 34.1117L241.621 34.1209Z"
          fill="white"
        />
        <path
          d="M268.822 31.8235C268.822 21.8253 275.944 14.8044 284.325 14.8044C287.789 14.5931 291.152 15.9991 293.441 18.6089L294.461 14.9055H298.853V27.679H294.029C292.081 21.7793 289.351 18.8019 285.299 18.8019C280.713 18.8019 277.497 22.1653 277.497 31.3365C277.497 40.5077 280.86 43.8711 286.374 43.8711C291.198 43.8711 293.974 40.2136 294.81 34.8469H299.487C298.66 43.1359 293.928 48.4475 285.243 48.4475C275.254 48.4475 268.812 41.7207 268.812 31.8143L268.822 31.8235Z"
          fill="white"
        />
        <path
          d="M301.923 43.6229H305.626V18.1678H301.923V14.0141H312.794V43.6229H316.507V47.6664H301.932V43.6229H301.923Z"
          fill="white"
        />
        <path
          d="M318.85 36.354C318.74 29.811 323.96 24.4167 330.503 24.3064C330.751 24.3064 330.99 24.3064 331.238 24.3156C338.452 24.3156 343.524 29.085 343.524 36.1151C343.653 42.6397 338.47 48.0339 331.946 48.1626C331.688 48.1626 331.44 48.1626 331.183 48.1534C323.905 48.1534 318.85 43.4208 318.85 36.354ZM335.722 36.207C335.722 30.0683 334.215 27.6239 331.045 27.6239C327.875 27.6239 326.652 30.1143 326.652 36.2529C326.652 42.3915 328.215 44.836 331.33 44.836C334.445 44.836 335.704 42.3456 335.704 36.1978H335.713L335.722 36.207Z"
          fill="white"
        />
        <path
          d="M349.019 40.1125V28.892H345.701V24.8486H356.131V39.1384C356.021 40.379 356.389 41.6196 357.161 42.5937C357.758 43.1359 358.548 43.4208 359.357 43.3748C361.553 43.3748 363.648 41.0958 363.648 37.7692V28.892H360.285V24.8486H370.77V43.623H374.088V47.6664H364.09V41.6656C362.481 45.4701 359.991 48.0983 355.846 48.0983C351.022 48.0983 349.019 44.79 349.019 40.1033V40.1125Z"
          fill="white"
        />
        <path
          d="M379.538 45.7641C377.323 43.1451 376.202 39.7725 376.423 36.354C376.221 32.8436 377.415 29.3883 379.74 26.7417C381.312 25.1335 383.49 24.2513 385.741 24.3064C388.581 24.2421 391.218 25.74 392.615 28.2028V18.1586H389.104V14.0049H399.737V43.6138H403.008V47.6572H393.047V42.8878C391.889 46.0858 388.838 48.1994 385.447 48.1534C383.232 48.1994 381.1 47.3355 379.547 45.7549L379.538 45.7641ZM393.047 36.2529C393.047 31.9614 391.687 28.598 388.415 28.598C385.447 28.598 384.078 31.3273 384.078 36.3999C384.078 41.4726 385.539 43.963 388.415 43.963C391.246 43.963 393.047 40.7374 393.047 36.2529Z"
          fill="white"
        />
        <path
          d="M63.4629 26.6587H63.4537C60.026 24.251 56.4604 22.0455 52.7754 20.0606C52.8948 15.8977 52.7662 11.7348 52.3894 7.59032C48.2541 7.93953 44.1372 8.53685 40.0662 9.3731C37.4747 6.09242 34.6903 2.95877 31.722 0.00891876C28.7538 2.95877 25.9694 6.09242 23.3779 9.3731C19.3161 8.53685 15.2084 7.94872 11.073 7.59951C10.6963 11.744 10.5676 15.9069 10.6871 20.0698C7.00206 22.0547 3.4365 24.2602 0.00878906 26.6679C2.39808 30.0864 4.98035 33.3671 7.75561 36.4732C6.22095 40.3328 4.91603 44.2843 3.85003 48.3002C7.88426 49.3846 11.9828 50.2392 16.1181 50.8457C17.4322 54.8248 18.9853 58.7212 20.7589 62.5165C24.5358 60.7612 28.2024 58.7947 31.7404 56.6076C35.2784 58.7947 38.9451 60.7612 42.722 62.5165C44.5048 58.7212 46.0578 54.8248 47.3719 50.8457C51.498 50.2484 55.5874 49.3937 59.6124 48.3094C58.5464 44.3027 57.2507 40.3604 55.716 36.5099C58.5005 33.3947 61.0919 30.1048 63.4812 26.6863L63.4629 26.6587ZM43.5766 41.0588H43.4204H20.5475C17.0003 41.0588 14.124 38.1824 14.124 34.6353C14.124 31.0881 17.0003 28.2117 20.5475 28.2117C21.0805 28.2117 21.6135 28.2761 22.1373 28.4139C22.6427 23.3688 26.8791 19.5276 31.9518 19.5368C37.392 19.5368 41.803 23.957 41.803 29.3972C41.803 29.6178 41.7938 29.8475 41.7754 30.068C42.3544 29.8751 42.9609 29.774 43.5766 29.774C46.6919 29.774 49.219 32.3011 49.219 35.4164C49.219 38.5317 46.6919 41.068 43.5766 41.068V41.0588Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default CultureCloudWhiteLogo;
