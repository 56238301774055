import React from 'react';
import ColorBox from './ColorBox';
import { RootBox, TitleTypography, GridBox } from './styles';
import { ColorBoxLabelProps } from './types';
import INFORMATION_COLORS_GRID from './constants';

const InformationColorsComponent: React.FC<ColorBoxLabelProps> = () => (
  <RootBox>
    <TitleTypography variant="h3">Information Colors</TitleTypography>
    <GridBox>
      {INFORMATION_COLORS_GRID?.flat()?.map(({ color, label }) => (
        <ColorBox
          key={color}
          color={color}
          label={label}
          isSemantic={label.includes('Semantic')}
        />
      ))}
    </GridBox>
  </RootBox>
);

export default InformationColorsComponent;
