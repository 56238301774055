import React from 'react';
import Select from '../Select';
import { Pagination as Page } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuItem from '../MenuItem';
import { tannerGray, tannerBlack } from '../ThemeProvider/colors';

interface PaginationProps {
  rowsPerPage?: boolean;
  totalRecord?: number;
  itemsPerPageOptions?: number[];
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  onItemsPerPageChange?: (newItemsPerPage: number) => void;
  itemsPerPage?: number;
  menuProps?: {
    value: string;
  };
}
const Container = styled('div')({
  maxWidth: '572px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  gap: '16px',
  '@media screen and (max-width: 646px)': {
    display: 'initial',
  },
});

const MenuItemSm = styled(MenuItem)({
  '&.MuiButtonBase-root.Mui-selected': {
    color: '#0060E0',
    backgroundColor: '#DEEBFC',
  },
  '& .MuiListItemIcon-root': {
    color: '#0060E0',
  },
});

const StyledPagination = styled(Page)({
  '& .MuiPaginationItem-page': {
    color: tannerGray['600'],
  },
  '& .MuiPaginationItem-ellipsis': {
    color: tannerGray['600'],
  },
  '& .Mui-selected': {
    color: tannerBlack,
  },
  '& .MuiButtonBase-root': {
    padding: '0px',
    margin: '0px',
    width: '40px',
    height: '40px',
    fontSize: '14px',
    borderRadius: '20px',
  },
  '@media screen and (max-width: 646px)': {
    size: 'small',
    paddingTop: '6px',
  },
});

const Pagination: React.FC<
  React.ComponentProps<typeof Page> & PaginationProps
> = ({
  rowsPerPage,
  totalRecord,
  itemsPerPageOptions,
  menuProps,
  onChange,
  itemsPerPage,
  onItemsPerPageChange,
  ...props
}) => {
  const handleItemsPerPageChange = (e: any) => {
    const newValue = e.target.value as number;
    if (onItemsPerPageChange) {
      onItemsPerPageChange(newValue);
    }
  };
  return (
    <Container>
      {rowsPerPage && (
        <Select
          sx={{
            minWidth: 200,
            radius: '3px',
            gap: '8px',
            '& .MuiSelect-select': { color: tannerGray['600'] },
          }}
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
        >
          {itemsPerPageOptions?.map((option: any) => (
            <MenuItemSm key={option} value={option}>
              {`${option} ${menuProps?.value}`}
            </MenuItemSm>
          ))}
        </Select>
      )}
      <StyledPagination
        onChange={onChange}
        count={
          totalRecord && itemsPerPage
            ? Math.ceil(totalRecord / itemsPerPage)
            : 0
        }
        {...props}
      />
    </Container>
  );
};

export default Pagination;
